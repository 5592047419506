import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FindLoad, SendPageSave, useGlobalStyles, SavePage, FindSuccessItem } from 'utils';
import { SearchAndFilter, PaginationItem, Loader, TableNoInfo } from 'components';
import { tableStyle } from './styles';
import { filterFromTable, handleReturnColor } from './constants';
import { FilterForTable } from './filtereForTable';
import { CANCELLED } from '../../pages/accounting/constants';
import { useDebouncedCallback } from 'use-debounce';
import { SaveParams } from '../../utils/hooks/paginate';

export const FullTable = ({
                            head,
                            body,
                            list,
                            loadingType,
                            handleClick,
                            noText,
                            dateFilter,
                            handleOpenModal,
                            addButton,
                            margin,
                            listCount,
                            handleClickButton,
                            typeForFilter,
                            startEnd,
                            createPermisson,
                            type,
                            height,
                            defaultType,
                            customBodyItem,
                            clickType,
                            dateFrom,
                            padding,
                            paddingHead,
                          }) => {
  const classes = tableStyle();
  const globalStyle = useGlobalStyles();
  const history = useHistory();
  const info = history?.location?.state;
  const loader = FindLoad(loadingType ? loadingType : '');
  const miniLoadSuccess = FindSuccessItem('MINI_LOAD');
  const [listInfo, setListInfo] = useState(list);
  const [backSearched, setBackSearched] = useState({ searchKey: '', searchValue: '' });
  const [searched, setSearched] = useState({ name: '', text: '' });

  useEffect(() => {
    if (list && !searched?.value) {
      setListInfo(list);
    }
  }, [list]);

  useEffect(() => {
    if (info?.searchKey) {
      setBackSearched({
        searchKey: info?.searchKey,
        searchValue: info?.searchValue,
      });
    }
  }, [info]);


  useEffect(() => {
    if (defaultType) {
      const anotherInfo = { ...info };
      defaultType ? anotherInfo.type = defaultType : '';
      SavePage(history, info, { ...anotherInfo });
    }
  }, []);

  const changePage = (number) => {
    setSearched({ name: '', text: '' });
    SendPageSave(number, info, history);
  };

  const debounced = useDebouncedCallback((name, value, searchKey) => {
      const params = {
        ...info,
      };
      params.limit = 100;
      params.skip = 0;
      params.page = 1;
      if (value) {
        params.searchKey = searchKey;
        params.searchValue = value;
      } else {
        delete params.searchKey;
        delete params.searchValue;
      }
      SaveParams(history, params);
    },
    1000,
    { maxWait: 2000 },
  );

  const handleChange = (e, nameRow, searchKey, type) => {
    if (searchKey) {
      setBackSearched({
        searchKey: searchKey,
        searchValue: e.target.value,
      });
      // setSearched({ name: '', text: '' });
      debounced(e.target.name, e.target.value, searchKey);
    } else {
      setSearched({
        name: e.target.name,
        value: e.target.value,
      });

      if (e.target.name) {
        if (!e.target.value) {
          setListInfo(list);
        } else {
          const info = filterFromTable(e, nameRow, list, type);
          setListInfo(info);
        }
      }
    }
  };

  const handleName = (item, name) => {
    handleClickButton(item, name);
  };

  const renderItems = (i, item) => {
    if (item) {
      if (item?.button) {
        return item?.button((name) => handleName(i, name), i, miniLoadSuccess?.data === i.id);
      } else {
        return item?.rowText(i);
      }
    }
  };

  // if (loader.length) return <Loader/>

  return (
    <div style={{ width: '100%' }}>

      {dateFilter &&
        <FilterForTable
          startEnd={startEnd}
          typeForFilter={typeForFilter}
          addButton={addButton}
          createPermisson={createPermisson}
          handleOpenModal={handleOpenModal}
          type={type}
          loader={loader}
          dateFrom={dateFrom}
          // handleSelectStatus={() => setSearch('')}
        />
      }

      <div style={margin ? { margin: 0 } : {}}
           className={
             height === 'small' ? globalStyle.tableWrapperSmall :
               height === 'medium' ? globalStyle.tableWrapperMedium :
                 globalStyle.tableWrapper}>
        <TableContainer
          component={Paper}
          className={
            height === 'small' ? globalStyle.tableContainerSmall :
              height === 'medium' ? globalStyle.tableContainerMedium :
                globalStyle.tableContainer}
        >
          <Table stickyHeader aria-label="sticky table" style={{position:'relative'}}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {head ? head.map((i, j) => (i &&
                  <TableCell key={j} style={paddingHead ? { padding: paddingHead } : {}}>
                    <SearchAndFilter
                      handleSearch={(e) => handleChange(e, i?.nameRow, i?.searchKey, i?.icon)}
                      item={i}
                      searched={searched}
                      backSearched={backSearched}
                    />
                  </TableCell>
                )) : ''}
              </TableRow>
            </TableHead>

            {loader?.length ?
              <div style={{ position: 'absolute', width: '100%' }}>
                <Loader />
              </div>
              :
              <TableBody>
                {listInfo?.length ? listInfo?.map((i, j) => (
                    <React.Fragment key={j}>
                      <TableRow className={classes.tableRow}>
                        {customBodyItem && customBodyItem(i)}

                        {body?.map((item, ke) => {
                          if (item) {
                            return (
                              <TableCell
                                onClick={() => item?.notClickable !== true &&
                                  handleClick && handleClick(
                                    clickType === 'item' ? i : i.id)
                                }
                                style={{
                                  opacity: i?.status === CANCELLED ? '0.5' : '',
                                  background: item.type === 'returnColor' ? handleReturnColor(i?.bill?.processedDate, i?.customer?.pmtTerm) : 'transparent',
                                  textAlign: item.type === 'returnColor' ? 'center' : '',
                                  color: '#51566D',
                                  borderLeft: ke === 0 ?
                                    i?.isVerification ? '5px solid #D72525' :
                                      i?.isRevision === true ? '5px solid #D72525' : ''
                                    : '',
                                  padding: padding ? padding : '',

                                }}
                                key={ke}
                              >
                                {renderItems(i, item) || 'Not Set'}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    </React.Fragment>
                  ))
                  :
                  <div style={{ height: '100px' }}>
                    <TableNoInfo text={`No ${noText} Yet`} />
                  </div>
                }
              </TableBody>
            }
          </Table>
        </TableContainer>
        {listCount > 0 ?
          <div style={{ background: 'white', border: '0 0 8px 8px' }}>
            <PaginationItem
              listInfo={listInfo}
              handleReturn={(number) => changePage(number)}
              count={listCount}
              styles={{ margin: '0 0 21px 0' }}
              loader={loader}
            />
          </div>
          : ''
        }
      </div>
    </div>
  );
};