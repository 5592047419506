import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGlobalStyles} from "utils";
import {FullTable} from "components";
import {LAST_LOADS, lastLoadsHead, lastLoadsBody} from "../constants";
import {loadActions} from "store";

export const TruckLastLoads = ({ id }) => {
    const {carrierLastLoads} = useSelector((state) => ({
            carrierLastLoads: state.loads.carrierLastLoads,
        })
    )
    const globalStyle = useGlobalStyles();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadActions.getCarrierLastLoads(id))
    }, [])

    return (
        <div style={{marginBottom: '16px'}} className={globalStyle.tableWrapperNoShadow}>
                <div style={{margin: '16px 0'}}>
                    <FullTable
                        height={'medium'}
                        head={lastLoadsHead}
                        body={lastLoadsBody}
                        loadingType={LAST_LOADS}
                        list={carrierLastLoads?.length ? carrierLastLoads : []}
                        noText={'Loads'}
                    />
                </div>
        </div>
    );
};
