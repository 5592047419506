import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {Button, TextField} from "@material-ui/core";
import {MinLoader} from "../loader";
import {filtersFragment} from "./styles";
import {loadActions} from "store";
import {format, SavePage} from "utils";

export const DateFilters = ({status}) => {
    const classes = filtersFragment()
    const dispatch = useDispatch()
    const [inputs, setInputs] = useState(null)
    const loader = false
    const history = useHistory()
    const historyInfo = history?.location?.state

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),);
    }

    const handleSearch = () => {
        const params = {department: status}
        inputs.startDate ? params.from = moment(inputs.startDate).format("YYYY-MM-DDT00:00:01") : ''
        inputs.endDate ? params.to = moment(inputs.endDate).format("YYYY-MM-DDT23:59:59") : ''
        dispatch(loadActions.getLoadsByStatus(params))
        SavePage(history, historyInfo, {...params})
    }

    useEffect(() => {
        if (historyInfo) {
            const startEndDate = {}
            historyInfo?.from ? startEndDate.startDate = moment(historyInfo?.from).format('MM-DD-YYYY') : ''
            historyInfo?.to ? startEndDate.endDate = moment(historyInfo?.to).format('MM-DD-YYYY') : ''
            setInputs(startEndDate)
        }
    }, [])

    return (
        <div className={classes.filterWrapper}>
            <TextField
                className={classes.dateFiled}
                type={'date'}
                name={'startDate'}
                variant="outlined"
                value={inputs?.startDate}
                onChange={handleChange}
                InputProps={{
                    inputProps: {
                        max:
                            inputs?.endDate ?
                                moment(inputs?.endDate).format('YYYY-MM-DD')
                                :
                              ''
                    }
                }}
            />
            <TextField
                className={classes.dateFiled}
                type={'date'}
                name={'endDate'}
                variant="outlined"
                value={inputs?.endDate}
                onChange={handleChange}
                InputProps={{
                    inputProps: {
                        min: inputs?.startDate ?
                            format(new Date(inputs?.startDate))
                            : ''
                    }
                }}
            />

            <Button
                onClick={handleSearch}
                className={classes.search}>
                {loader.length ?
                    <MinLoader color={'white'} margin={'0px'}/>
                    :
                    'Search'
                }
            </Button>
        </div>
    )
}