import React, {useEffect, useState} from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {inputsStyle} from "./styles";
import axios from "axios";
import {Colors, CountryList, useGlobalStyles} from "utils";
import {SelectInput, ValidationInput} from "components";
import PlaceIcon from '@material-ui/icons/Place';

export const AddressInput = ({
                                 handleSelectValue,
                                 info,
                                 disabled,
                                 errorBoolean,
                                 oneInput,
                                 handleGetAll,
                                 all,
                                 noBlue,
                                 type,
                                 direction,
                                 loadInput
                             }) => {
    const classes = inputsStyle();
    const globalInputs = useGlobalStyles();
    const globalStyles = useGlobalStyles();
    const [address, setAddress] = useState(info?.address?.formattedAddress ||  "");
    const [error, setError] = useState("");
    const [fullAddress, setFullAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState('')
    const [suite, setSuite] = useState(info?.address?.unitNumber ? info?.address?.unitNumber : '')
    const [inputs, setInputs] = useState(info ? type === 'another' ? {...info} : {...info.address} : {});
    const Country = inputs.country ? inputs.country : fullAddress.country ? fullAddress.country : all ? all.country : ''
    const City = inputs.city ? inputs.city : fullAddress.city ? fullAddress.city : all ? all.city : ''
    const States = inputs.state ? inputs.state : fullAddress.state ? fullAddress.state : all ? all.state : ''
    const Zip = inputs?.zip ? inputs?.zip : fullAddress?.zip ? fullAddress.zip : all ? all.zip : ''
    const Street = fullAddress ? fullAddress.street : address ? address : all ? all.street : ''
    const fullAddressCompleted = (Street && Street + ' ') + (City && City + ' ')  + (States && States + ' ') + (Zip && Zip + ' ') + Country

    const handleChangeAddress = (value, name) => {
        if (name !== 'other') {
            setAddress(value);
        }
    };

    const handleSelect = async (value, ev) => {
        setAddress(value);
        setLoading(true)
        await axios.post(`/address`, {address: value}, {auth: true})
            .then(function (response) {
                handleSelectValue(
                    {
                        address: response.data.formattedAddress,
                        unitNumber: suite
                    }
                    );
                setInputs({...response.data})
                setFullAddress(response.data);
                setLoading(false)
                handleGetAll && handleGetAll(response.data)
            })
            .catch(function (error) {
            });
    };

    useEffect(() => {
        handleSelectValue({
            address: fullAddressCompleted,
            unitNumber: suite
        })
    }, [fullAddressCompleted])


    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value})),
        error === e.target.name && setError('')

    }

    const handleChangeSuite = (e) => {
        setSuite(e.target.value)
        handleSelectValue({
            address: fullAddressCompleted,
            unitNumber: e.target.value
        })
    }
    const placeholder = info ?
        type === 'another' ? info.formattedAddress :
            info.address ? info.address.formattedAddress : info :
        all ? all.formattedAddress : "Physical Address*";

    return (
        <div style={direction === 'column' ? {
            display: 'flex',
            width: '100%',
            flexDirection: 'column'
        } : {display: 'flex', width: '100%'}}>
            <PlacesAutocomplete value={address} onChange={handleChangeAddress} onSelect={(ev) => handleSelect(ev)}>
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div style={loadInput === 'pick' ? {marginBottom: '18px'} : {}}
                         className={!oneInput ? globalInputs.addressSimpleInput : globalInputs.simpleInputFull}>
                        <input
                            // className={info && globalStyles.inputTextFieldBlue }
                            className={
                                errorBoolean ? classes.searchAddressError :
                                    info ? noBlue ? classes.searchAddress : classes.searchAddressFill :
                                    classes.searchAddress
                            }
                            {...getInputProps({
                                placeholder: placeholder,
                                disabled: disabled,
                                onBlur: (e) => {
                                    handleChangeAddress(e.target.value)
                                },
                            })}
                        />
                        <div style={{position: 'relative', marginBottom: '6px'}}>
                            <p className={classes.errorText}>{errorBoolean ? errorBoolean : ''}</p>
                        </div>

                        <div style={oneInput ? {position:'relative'} : {}}>
                            {loading && <div>Loading...</div>}

                            <div className={classes.valuesContainer}>
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                    const style = suggestion.active ? {
                                        margin: '15px',
                                        cursor: "pointer"
                                    } : {margin: '15px', cursor: "pointer"};
                                    return (
                                        <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <PlaceIcon style={{color: Colors.ThemeGreen}}/>
                                                <span
                                                    className={classes.searchfAddressDescriptionText}>{suggestion.description}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>

            {!oneInput &&
                <div className={loadInput === 'pick' ? classes.pickInputs : classes.inputs}>
                    <ValidationInput
                        className={info && globalStyles.inputTextFieldBlue}
                        style={globalInputs.addressSimpleInput}
                        variant={"outlined"}
                        name={"unitNumber"}
                        type={"text"}
                        label={"Suite"}
                        handleSendAddress={(e) => {
                            handleChangeAddress(e, 'other')
                        }}
                        onChange={handleChangeSuite}
                        value={suite}
                        disabled={disabled}
                    />
                    <ValidationInput
                        className={info && globalStyles.inputTextFieldBlue}
                        style={globalInputs.addressSimpleInput}
                        variant={"outlined"}
                        name={"city"}
                        type={"name"}
                        label={"City*"}
                        handleSendAddress={(e) => {
                            handleChangeAddress(e, 'other')
                        }}
                        onChange={handleChange}
                        value={City}
                        loader={loading}
                        disabled={disabled}
                    />
                    <ValidationInput
                        className={info && globalStyles.inputTextFieldBlue}
                        style={globalInputs.addressSimpleInput}
                        variant={"outlined"}
                        name={"state"}
                        type={"name"}
                        label={"State"}
                        handleSendAddress={(e) => {
                            handleChangeAddress(e, 'other')
                        }}
                        onChange={handleChange}
                        value={States}
                        loader={loading}
                        disabled={disabled}
                    />
                    <SelectInput
                        className={info && globalStyles.inputTextFieldBlue}
                        style={globalInputs.addressSimpleInput}
                        name={"country"}
                        label={"Country*"}
                        handleBlur={(e) => {
                            handleChangeAddress(e, 'other')
                        }}
                        handleSelect={handleChange}
                        handleChangeCountryCode={setCode}
                        loader={loading}
                        value={Country}
                        list={CountryList}
                        disabled={disabled}
                    />
                    <ValidationInput
                        className={info && globalStyles.inputTextFieldBlue}
                        variant={"outlined"}
                        name={"zip"}
                        type={"text"}
                        label={"Zip Code"}
                        handleSendAddress={(e) => {
                            handleChangeAddress(e, 'other')
                        }}
                        onChange={handleChange}
                        value={Zip}
                        loader={loading}
                        disabled={disabled}
                    />
                </div>
            }
        </div>
    );
}
