import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { agentActions, httpRequestsOnSuccessActions, mcActions, officeActions } from 'store';
import { ActiveInactive, History, Loader, Notes, SimpleModal, SimpleTabs, SlicedText } from 'components';
import { AgentInfoTable } from 'fragments';
import {
  AntSwitch,
  FindLoad,
  FindSuccessItem,
  Images,
  PermissionsList,
  RenderStatus,
  RolePermission,
  useGlobalStyles,
} from 'utils';

export const AgentInfoPage = () => {
  const { agentById } = useSelector((state) => ({
      agentById: state.agents.agentById,
    }),
  );
  const dispatch = useDispatch();
  const params = useParams();
  const loader = FindLoad('GET_AGENT_BY_ID');
  const globalStyle = useGlobalStyles();
  const [switchBoolean, setSwitchBoolean] = useState(null);
  const [agentName, setAgentName] = useState('');
  const [agentLastName, setAgentLastName] = useState('');
  const [open, setOpen] = useState(false);
  const name = agentName ? `${agentName} ${agentLastName}` : agentById && `${agentById.firstName} ${agentById.lastName}`;
  const activeInactive = switchBoolean === 'ACTIVE' ? 'Inactivate' : 'Activate';
  const activeSuccess = FindSuccessItem('ACTIVATE_AGENT');
  const inactiveSuccess = FindSuccessItem('INACTIVATE_AGENT');
  const activeLoading = FindLoad('ACTIVATE_AGENT');
  const inactiveLoading = FindLoad('INACTIVATE_AGENT');

  useEffect(() => {
    if (agentById) {
      setSwitchBoolean(agentById.status);
    }
  }, [agentById]);

  useEffect(() => {
    dispatch(agentActions.getAgentById(params.id));
    dispatch(mcActions.getAgentAssignedMcs(params.id, 'AGENT'));
    dispatch(officeActions.getOfficesForAgents());
  }, []);

  useEffect(() => {
    if (agentById) {
      dispatch(mcActions.getAssignedMcs(agentById?.branch?.id, 'BRANCH'));
    }
  }, [agentById]);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (activeSuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_AGENT'));
      setSwitchBoolean('ACTIVE');
      setOpen(false);
    }
    if (inactiveSuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_AGENT'));
      setSwitchBoolean('INACTIVE');
      setOpen(false);
    }
  }, [activeSuccess, inactiveSuccess]);

  const handleSwitch = () => {
    if (switchBoolean === 'ACTIVE') {
      dispatch(agentActions.inactivateAgent(agentById.id));
    } else {
      dispatch(agentActions.activateAgent(agentById.id));
    }
  };

  const tabsLabels = [
    {
      label: 'Details',
    },
    {
      label: 'History',
    },
    {
      label: 'Notes',
    },
  ];

  const tabsContent = [
    {
      tabComponent:
        <AgentInfoTable
          handleChangeLastName={setAgentName}
          handleChangeFirstName={setAgentLastName}
        />,
      tab: 'Details',
    },
    {
      tabComponent: <History onModel={'agent'} resourceId={params.id} />,
      tab: 'History',
    },
    {
      tabComponent: <Notes />,
      tab: 'Notes',
    },
  ];

  if (loader.length) return <Loader />;

  return (
    <>
      <SimpleTabs
        head={
          <div className={globalStyle.spaceBetween}>
            <div style={{ marginRight: '24px' }} className={globalStyle.centerItem}>
              {RenderStatus(activeInactive)}
              {RolePermission([PermissionsList.AGENT_STATUS?.code], 'officeManager') &&
                <AntSwitch
                  onClick={handleOpenClose}
                  checked={switchBoolean === 'ACTIVE'}
                />
              }
            </div>
            <div className={globalStyle.centerItem}>
              <img style={{ marginRight: '4px' }} src={Images.agentsBlue} alt={'authorityBlueFill'} />
              <SlicedText type={'tableName'} size={20} data={name && name} />
            </div>
          </div>
        }
        border={'blue'}
        height={'full'}
        tabsLabels={tabsLabels}
        tabsContent={tabsContent}
        parentLink={'/agents'}
        parent={'Agents'}
        child={'Agent Info'}
        defaultTab={'Details'}
        paramsName={'agentParams'}
      />
      <SimpleModal
        handleOpenClose={handleOpenClose}
        openDefault={open}
        content={
          <ActiveInactive
            loading={!!activeLoading?.length || !!inactiveLoading?.length}
            text={switchBoolean === true ? `Inactivate ${name}` : `Activate ${name}`}
            textInfo={activeInactive}
            name={name}
            handleClose={handleOpenClose}
            handleDel={handleSwitch}
          />
        }
      />
    </>
  );
};