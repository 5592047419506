import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {customerPaymentInfo} from "./styles";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {FindLoad, FindSuccess, Images, PermissionsList, RolePermission} from "utils";
import {DeleteElement, NoYet, RemoveButton, SimpleModal, TextRow, VoidedButton} from "components";
import {customerPaymentActions, httpRequestsOnSuccessActions} from "store";
import {checkStatus} from "../../core";

const ACTION_TYPE = 'VOID_CUSTOMER_PAYMENT_LOAD'

export const LoadTable = ({loadInfo}) => {
    const classes = customerPaymentInfo()
    const params = useParams()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)
    const [loadId, setLoadId] = useState('')

    useEffect(() => {
        if (success?.length) {
            handleOpenCloseDelModal()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }
    }, [success])

    const handleOpenDelModal = (row) => {
        setLoadId(row.load)
        setOpen(!open)
    }

    const handleOpenCloseDelModal = () => {
        setOpen(!open)
    }

    const handleVoidCustomer = () => {
        dispatch(customerPaymentActions.voidCustomerPaymentLoad(params.id, loadId))
    }

    return (
        <div>
            {loadInfo?.length ?
                <div className={classes.tableWrapper}>
                    <TableContainer component={Paper}>
                        <Table sx={{width: '100%'}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.loadTableHead}>
                                    <TableCell style={{width: '30%'}}>Load Id</TableCell>
                                    <TableCell style={{width: '30%'}} align="left">Amount</TableCell>
                                    <TableCell style={{width: '30%'}} align="left">MC Authority</TableCell>
                                    {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CUSTOMER_PAYMENT_ADD_CUSTOMER_AND_LOAD?.code]) &&
                                        <TableCell style={{width: '150px'}} align="left">Actions</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            {loadInfo?.map((row, key) => (
                                <TableBody key={key} className={classes.tableBody}>
                                    <TableRow
                                        key={'name'}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            <div className={classes.loadItem}>
                                                <img src={Images.loadOutline} alt='icon'/>
                                                <p style={{fontWeight: 'bold'}}>{row?.displayId}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">$ {row?.amount}</TableCell>
                                        <TableCell align="left">
                                            <TextRow name={row?.mcName ? row.mcName : 'Not Set'}/>
                                        </TableCell>
                                        {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CUSTOMER_PAYMENT_ADD_CUSTOMER_AND_LOAD?.code]) &&
                                            <TableCell style={{width: '150px'}} align="left">
                                                <div className={classes.tableLastRow}>
                                                    {!checkStatus(row) ?
                                                        <VoidedButton/>
                                                        :
                                                        <RemoveButton
                                                            text={'Void'}
                                                            handleClick={() => handleOpenDelModal(row)}
                                                        />
                                                    }
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                    </TableContainer>
                </div>
                :
                <NoYet position={'center'} text={'No Loads Yet'}/>
            }

            <SimpleModal
                handleOpenClose={handleOpenCloseDelModal}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!loader?.length}
                        type={'void'}
                        text={'Void Load'}
                        handleClose={handleOpenCloseDelModal}
                        handleDel={handleVoidCustomer}
                        info={'Bill'}
                        modalText={'Are you sure? You wont Void this load ?'}
                        // modalText={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text.'}
                    />
                }
            />
        </div>
    )
}