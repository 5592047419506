import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {FactoringInfo} from "fragments";
import {factoringActions, httpRequestsOnSuccessActions} from "store";
import {ActiveInactive, History, Loader, Notes, SimpleModal, SimpleTabs, SlicedText} from "components";
import {
    AntSwitch,
    FindLoad, FindSuccessItem,
    Images,
    PermissionsList,
    RenderStatus,
    RolePermission,
    useGlobalStyles
} from "utils";

export const FactoringsInfoPage = () => {
    const {factoringById} = useSelector((state) => ({
            factoringById: state.factorings.factoringById,
        })
    )
    const dispatch = useDispatch()
    const params = useParams()
    const globalStyle = useGlobalStyles()
    const loader = FindLoad('GET_FACTORING_BY_ID')
    const [customerName, setCustomerName] = useState('')
    const history = useHistory()
    const info = history?.location?.state
    const [switchBoolean, setSwitchBoolean] = useState(factoringById ? factoringById?.status : false)
    const [open, setOpen] = useState(false)
    const activeInactive = switchBoolean === true ? 'Inactivate' : 'Activate'
    const inactiveSuccess = FindSuccessItem('INACTIVATE_FACTORING')
    const activeSuccess = FindSuccessItem('ACTIVATE_FACTORING')
    const activeLoader = FindLoad('ACTIVATE_FACTORING')
    const inactiveLoader = FindLoad('INACTIVATE_FACTORING')
    const name = customerName ? customerName : factoringById && factoringById.name

    useEffect(() => {
        if (factoringById) {
            setSwitchBoolean(factoringById.status === 'ACTIVE')
        }
    }, [factoringById])

    useEffect(() => {
        dispatch(factoringActions.getFactoringById(params.id))
    }, [])

    useEffect(() => {
        const filteredInfo = {
            ...info
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab
        dispatch(factoringActions.getCarrierFactoring(params.id, {...filteredInfo}))
    }, [info])

    useEffect(() => {
        if (inactiveSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_FACTORING'))
            setSwitchBoolean(false)
            setOpen(false)
        }
        if (activeSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_FACTORING'))
            setSwitchBoolean(true)
            setOpen(false)
        }
    }, [inactiveSuccess, activeSuccess])

    const handleOpenClose = () => {
        setOpen(!open)
    }

    const handleSwitch = () => {
        if (switchBoolean) {
            dispatch(factoringActions.inactivateFactoring(factoringById?.id))
        } else {
            dispatch(factoringActions.activateFactoring(factoringById?.id))
        }
    }

    const tabsLabels = [
        {
            label: 'Details'
        },
        {
            label: 'History'
        },
        {
            label: 'Notes'
        },
    ]

    const tabsContent = [
        {
            tabComponent: <FactoringInfo info={factoringById} setCustomerName={setCustomerName}/>,
            tab: 'Details'
        },
        {
            tabComponent: <History onModel={'factoring'} resourceId={params.id}/>,
            tab: 'History'
        },
        {
            tabComponent:  <Notes/>,
            tab: 'Notes'
        }
    ];

    if (loader.length) return <Loader/>

    return (
        <>
            <SimpleTabs
                head={
                    <div className={globalStyle.spaceBetween}>
                        <div style={{marginRight: '24px'}} className={globalStyle.centerItem}>
                            {RenderStatus(activeInactive)}
                            {/*{RolePermission([PermissionsList.OFFICE_STATUS?.code]) &&*/}
                                <AntSwitch
                                    onClick={handleOpenClose}
                                    checked={switchBoolean}
                                />
                            {/*}*/}
                        </div>
                        <div className={globalStyle.centerItem}>
                            <img style={{marginRight: '4px'}} src={Images.factoringBold}
                                 alt={"authorityBlueFill"}/>
                            <SlicedText
                                type={'tableName'}
                                size={20}
                                data={customerName ? customerName : factoringById && factoringById.name}
                            />
                        </div>
                    </div>
                }
                border={'blue'}
                height={'full'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                parentLink={'/factorings'}
                parent={'Factorings'}
                child={'Factoring Info'}
                defaultTab={'Details'}
                paramsName={'factoringParams'}
            />

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <ActiveInactive
                        loading={!!activeLoader?.length || !!inactiveLoader?.length}
                        text={switchBoolean === true ? `Inactivate ${name}` : `Activate ${name}`}
                        textInfo={activeInactive}
                        name={name}
                        handleClose={handleOpenClose}
                        handleDel={handleSwitch}
                    />
                }
            />
        </>
    )
}