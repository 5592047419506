import { isFactored, userType } from '../constants';
import { Images } from '../images';
import { PermissionsList } from '../hooks';
import { RolePermission } from '../hooks';


export const ProfileRouters =
  [
    userType === 'ADMIN' &&
    RolePermission([PermissionsList.READ_ADMIN?.code, PermissionsList.CREATE_UPDATE_ADMIN?.code]) &&
    {
      'name': 'HR',
      'icon': Images.humanResourcesGray,
      'activeIcon': Images.humanResources,
      'path': '/humanResources',
      'create': '/createHumanResources',
      'type': 'Profile',
    },
    RolePermission([PermissionsList.READ_OFFICE?.code, PermissionsList.CREATE_UPDATE_OFFICE?.code], 'officeManager') &&
    {
      'name': 'Offices',
      'icon': Images.officesGray,
      'activeIcon': Images.offices,
      'path': '/offices',
      'create': '/createOffice',
      'info': '/offices',
      'type': 'Profile',
    },
    RolePermission([PermissionsList.READ_BRANCH?.code, PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeOrBranchManager') &&
    {
      'name': 'Branches',
      'icon': Images.branchesGray,
      'activeIcon': Images.branches,
      'path': '/branches',
      'create': '/createBranch',
      'type': 'Profile',
    },
    RolePermission([PermissionsList?.READ_AGENT?.code, PermissionsList.CREATE_UPDATE_AGENT?.code], 'officeOrBranchManager') &&
    {
      'name': 'Agents',
      'icon': Images.agentsGray,
      'activeIcon': Images.agentsFill,
      'path': '/agents',
      'create': '/createAgent',
      'type': 'Profile',
    },
    RolePermission([PermissionsList.READ_MC?.code, PermissionsList.CREATE_UPDATE_MC?.code]) &&
    {
      'name': 'Authorities',
      'icon': Images.authorityGray,
      'activeIcon': Images.authorityFill,
      'path': '/authorities',
      'create': '/createAuthorities',
      'type': 'Profile',
    },
    userType === 'ADMIN' &&
    RolePermission([PermissionsList.READ_CUSTOMER?.code, PermissionsList.CREATE_UPDATE_CUSTOMER?.code]) &&
    {
      'name': 'Customers',
      'icon': Images.customerGray,
      'activeIcon': Images.customerFill,
      'path': '/customers',
      'create': '/createCustomer',
      'type': 'Profile',
    },
    RolePermission([PermissionsList?.READ_CARRIER?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code], 'agentView') &&
    {
      'name': 'Carriers',
      'icon': Images.carrierGray,
      'activeIcon': Images.carrierFill,
      'path': '/Carriers',
      'create': '/createCarriers',
      'type': 'Profile',
    },
    userType === 'ADMIN' &&
    RolePermission([PermissionsList.READ_FACTORING?.code, PermissionsList.CREATE_UPDATE_FACTORING?.code]) &&
    {
      'name': 'Factorings',
      'icon': Images.factoringGray,
      'activeIcon': Images.factoring,
      'path': '/factorings',
      'create': '/createFactoring',
      'type': 'Profile',
    },
  ];


export const SettingsAndLoads = [
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_LOADS?.code], 'agentView') &&
  {
    'name': 'Loads',
    'icon': Images.loads,
    'activeIcon': Images.activeLoad,
    'create': '/createLoad',
    'path': '/loads',
  },

  {
    'name': 'Truck Board',
    'icon': Images.truckBoard,
    'activeIcon': Images.truckBoardFill,
    // "create": "/trucks",
    'path': '/trucks',
  },


];


export const FactoredCreditLoads = (total) => {

  return [
    {
      'name': 'Credit Check',
      'icon': Images.creditCheck,
      'activeIcon': Images.creditCheckActive,
      'create': '/credit',
      'path': '/credit',
      'activeCount': total?.numCreditRequests,
    },
    {
      'name': 'Rejected',
      'icon': Images.rejectedCredit,
      'activeIcon': Images.rejectedCreditActive,
      'create': '/rejected',
      'path': '/rejected',
    },
    {
      'name': 'Consignment Request',
      'icon': Images.consign,
      'activeIcon': Images.consignActive,
      'create': '/consignmentRequest',
      'path': '/consignmentRequest',
      'activeCount': total?.numConsignmentRequest,
    },
    {
      'name': 'Consignment',
      'icon': Images.consignementPassive,
      'activeIcon': Images.consignementActive,
      'create': '/consign',
      'path': '/consign',
    },
  ];
};

export const CreditLoads = (total) => {

  return [
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.ACCEPT_DENY_LOAD?.code, PermissionsList.CREDIT_TAB?.code], 'agentView') &&
    {
      'name': 'Credit Check',
      'icon': Images.creditCheck,
      'activeIcon': Images.creditCheckActive,
      'create': '/credit',
      'path': '/credit',
      'activeCount': total?.numCreditRequests,
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREDIT_TAB?.code], 'agentView') &&
    {
      'name': 'Rejected',
      'icon': Images.rejectedCredit,
      'activeIcon': Images.rejectedCreditActive,
      'create': '/rejected',
      'path': '/rejected',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREDIT_CONSIGN_TAB?.code, PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code], 'officeOrBranchManager') &&
    {
      'name': 'Consignment Request',
      'icon': Images.consign,
      'activeIcon': Images.consignActive,
      'create': '/consignmentRequest',
      'path': '/consignmentRequest',
      'activeCount': total?.numConsignmentRequest,
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREDIT_CONSIGN_TAB?.code], 'officeOrBranchManager') &&
    {
      'name': 'Consignment',
      'icon': Images.consignementPassive,
      'activeIcon': Images.consignementActive,
      'create': '/consign',
      'path': '/consign',
    },
  ];
};


export const Claims = (total) => {
  return [
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_BOND_LIST.code], 'officeOrBranchManager') &&
    {
      'name': 'Bonds',
      'icon': Images.onBondPassive,
      'activeIcon': Images.onBondActive,
      'path': '/bonds',
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CLAIM_LIST.code], 'officeOrBranchManager') &&
    {
      'name': 'Claims',
      'icon': Images.onClaimPassive,
      'activeIcon': Images.onClaimActive,
      'path': '/claims',
    },
  ];
};

export const LoadAccounting = () => {
  if (userType === 'AGENT') {
    return [
      // {
      //     "name": "Receivables",
      //     "icon": "Receivables",
      //     "path": "/receivables",
      //     "create": "/receivables",
      // },

      // {
      //     "name": "Carrier Payment",
      //     "icon": "CarrierPayment",
      //     "path": "/carrierPayments",
      //     "create": "/carrierPayments",
      // },
    ];
  } else if (userType === 'ADMIN') {
    return [

      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PROCESSING?.code]) &&
      {
        'name': 'Processing',
        'icon': Images.processingGray,
        'activeIcon': Images.processingFill,
        'path': '/processing',
        'create': '/processing',
      },
      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_LOAD_VERIFICATION?.code]) &&
      {
        'name': 'Load Verification',
        'icon': Images.loadVerification,
        'activeIcon': Images.loadVerificationFill,
        'path': '/loadVerification',
        'create': '/loadVerification',
      },

      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PAYMENT_VERIFICATION?.code]) &&
      {
        'name': 'Pmt Verification',
        'icon': Images.paymentVerification,
        'activeIcon': Images.paymentVerificationFill,
        'path': '/paymentVerification',
        'create': '/paymentVerification',
      },
      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PAYABLE?.code]) &&
      {
        'name': 'Payables',
        'icon': Images.payable,
        'activeIcon': Images.payableFill,
        'path': '/payables',
        'create': '/payables',
      },

      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SUBMIT_BILL?.code, PermissionsList.READ_BILLING?.code]) &&
      {
        'name': 'Billing',
        'icon': Images.billingGray,
        'activeIcon': Images.billingFill,
        'path': '/billing',
        'create': '/billing',
      },
      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_RECEIVABLES?.code]) &&
      {
        'name': 'Receivables',
        'icon': Images.receivables,
        'activeIcon': Images.receivablesFill,
        'path': '/receivables',
        'create': '/receivables',
      },
      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_PAYMENT?.code]) &&
      {
        'name': 'Carrier Payments',
        'icon': Images.carrierPayment,
        'activeIcon': Images.carrierPaymentFill,
        'path': '/carrierPayments',
        'create': '/carrierPayments',
      },
      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CUSTOMER_PAYMENT?.code, PermissionsList.CREATE_CUSTOMER_PAYMENT?.code]) &&
      {
        'name': 'Customer Payments',
        'icon': Images.customerPayment,
        'activeIcon': Images.customerPaymentFill,
        'path': '/customerPayments',
        'create': '/customerPayments',
      },


    ];
  }
};

export const Payroll = [
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PAYROLL?.code], 'agentView') &&
  {
    'name': 'Payroll',
    'icon': Images.payroll,
    'activeIcon': Images.payrollActive,
    'path': '/payroll',
  },
  RolePermission([PermissionsList.READ_MANAGEMENT?.code]) &&
  {
    'name': 'Access',
    'icon': Images.accessManagementGray,
    'activeIcon': Images.accessManagement,
    'path': '/management',
  },

  userType === 'ADMIN' && RolePermission([
    PermissionsList.CREATE_UPDATE_MY_CONF?.code,
    PermissionsList.CREATE_UPDATE_PAYROLL_SETUP?.code,
    PermissionsList.READ_PAYROLL_SETUP?.code,
    PermissionsList.READ_MY_CONF?.code,
  ]) &&
  {
    'name': 'System',
    'icon': Images.systemGray,
    'activeIcon': Images.systemFill,
    'create': '/system',
    'path': '/system',
  },
  userType !== 'ADMIN' &&
  {
    'name': 'Load Templates',
    'icon': Images.templatePassive,
    'activeIcon': Images.templateActive,
    'create': '/templates',
    'path': '/templates',
  },
];


export const AllRouting = [
  {
    'name': 'Offices',
    'path': '/offices',
  },
  {
    'name': 'Add Office',
    'path': '/createOffice',
  },
  {
    'name': 'Branches',
    'path': '/branches',
  },
  {
    'name': 'Add Branch',
    'path': '/createBranch',
  },
  {
    'name': 'Human Resources',
    'path': '/humanResources',
  },
  {
    'name': 'Add Human Resource',
    'path': '/createHumanResources',
  },
  {
    'name': 'Customers',
    'path': '/customers',
  },
  {
    'name': 'Add Customers',
    'path': '/createCustomer',
  },
  {
    'name': 'Factoring Companies',
    'path': '/factorings',
  },
  {
    'name': 'Add Factoring Companie',
    'path': '/createFactoring',
  },
  {
    'name': 'Authorities',
    'path': '/authorities',
  },
  {
    'name': 'Add MC Authoritie',
    'path': '/createAuthorities',
  },
  {
    'name': 'Carriers',
    'path': '/Carriers',
  },
  {
    'name': 'Add Carrier',
    'path': '/createCarrier',
  },

  {
    'name': 'Agents',
    'path': '/agents',
  },
  {
    'name': 'Add Agent',
    'path': '/createAgent',
  },
  {
    'name': 'Processing',
    'path': '/processing',
  },
  {
    'name': 'Billing',
    'path': '/billing',
  },
  {
    'name': 'Receivables',
    'path': '/receivables',
  },
  {
    'name': 'Customer Payment',
    'path': '/customerPayments',
  },
  {
    'name': 'Load Verification',
    'path': '/loadVerification',
  },
  {
    'name': 'Payment Verification',
    'path': '/paymentVerification',
  },
  {
    'name': 'Payables',
    'path': '/payables',
  },
  {
    'name': 'Carrier Payment',
    'path': '/carrierPayments',
  },
  {
    'name': 'Receivables',
    'path': '/receivables',
  },
  {
    'name': 'Payroll',
    'path': '/payroll',
  },
  {
    'name': 'Access Management',
    'path': '/management',
  },
  {
    'name': 'Loads',
    'path': '/loads',
  },
  {
    'name': 'Add Load',
    'path': '/createLoad',
  },
  {
    'name': 'System',
    'path': '/system',
  },
  {
    'name': 'Credit Check',
    'path': '/credit',
  },
  {
    'name': 'Rejected',
    'path': '/rejected',
  },
  {
    'name': 'Consignment Request',
    'path': '/consignmentRequest',
  },
  {
    'name': 'Consignment',
    'path': '/consign',
  },
  {
    'name': 'Claims',
    'path': '/claims',
  },
  {
    'name': 'Bonds',
    'path': '/bonds',
  },
  {
    'name': 'Ar Report',
    'path': '/ar',
  },
  {
    'name': 'Ap Report',
    'path': '/ap',
  },
  {
    'name': 'Load Templates',
    'path': '/templates',
  },
  {
    'name': 'Truck Board',
    'path': '/trucks',
  },
  {
    'name': 'My Profile',
    'path': '/my-profile',
  },
  {
    'name': 'Factored Load',
    'path': '/factored-loads',
  },
  {
    'name': 'Add Factored Load',
    'path': '/create-factored-load',
  },

];

export const reportActions = [
  {
    'name': 'AR',
    'icon': Images.arIcon,
    'activeIcon': Images.arActive,
    'path': '/ar',
  },
  {
    'name': 'AP',
    'icon': Images.apPassive,
    'activeIcon': Images.apActive,
    'path': '/ap',
  },
];