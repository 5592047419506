export const paymentMethods = [
    {name:'Check', id:'CHECK'},
    {name:'Ach', id:'ACH'},
    {name:'Cod', id:'COD'},
    {name: 'Lockbox', id: 'LOCKBOX'},
    {name:'Wire', id:'WIRE'},
]

export const checkStatus = ( info ) =>{
    if(info) {
        return info.status !== 'VOIDED';
    }
}

export const aboutFactoring = (info) =>{
    if (info){
        if(info.factoringName){
            return true
        }
        else{
            return !!(info?.customers && info.customers.length < 1);
        }
    }
}