import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {agentActions, branchActions, httpRequestsOnSuccessActions, mcActions,} from "store";
import {ActiveInactive, History, Loader, Notes, SimpleModal, SimpleTabs, SlicedText} from "components";
import { BranchInfoTable } from '../../fragments';
import {
    AntSwitch,
    FindLoad,
    FindSuccessItem,
    Images,
    PermissionsList,
    RenderStatus,
    RolePermission,
    useGlobalStyles,
} from "utils";

export const BranchesInfoPage = () => {
    const {branchById} = useSelector((state) => ({
            branchById: state.branches.branchById,
        })
    )
    const dispatch = useDispatch()
    const params = useParams()
    const globalStyle = useGlobalStyles()
    const [switchBoolean, setSwitchBoolean] = useState(false)
    const [branchName, setBranchName] = useState('')
    const [open, setOpen] = useState(false)
    const name = branchName ? branchName : branchById && branchById.name
    const activeInactive = switchBoolean === true ? 'Inactivate' : 'Activate'
    const loader = FindLoad('GET_BRANCH_BY_ID')
    const activeLoader = FindLoad('ACTIVATE_BRANCH')
    const inactiveLoader = FindLoad('INACTIVATE_BRANCH')
    const activeSuccess = FindSuccessItem('ACTIVATE_BRANCH')
    const inactiveSuccess = FindSuccessItem('INACTIVATE_BRANCH')

    useEffect(() => {
        if (branchById) {
            setSwitchBoolean(branchById.isActive)
        }
    }, [branchById])

    useEffect(() => {
        dispatch(branchActions.getBranchById(params.id))
        dispatch(agentActions.getAgentByBranch(params.id))
        dispatch(agentActions.getAgents({status: 'ACTIVE'}))
        // dispatch(mcActions.getMcs())
        dispatch(mcActions.getAssignedMcs(params.id, 'BRANCH'))
    }, [])

    useEffect(() => {
        const data = {
            'officeId': branchById && branchById.office.id,
            'branchId': params.id,
        }
        if (branchById && branchById.office && RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code, PermissionsList?.READ_BRANCH?.code], 'officeManager')) {
            dispatch(agentActions.getManagersByBranch(data))
        }
    }, [branchById !== null])

    useEffect(() => {
        if (branchById) {
            const data = branchById && branchById.office.id
            dispatch(agentActions.getBy(data))
        }
    }, [branchById])

    useEffect(() => {
        if (branchById && RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code, PermissionsList?.READ_BRANCH?.code], 'officeManager')) {
            dispatch(mcActions.getOfficeAssignedMcs(branchById && branchById.office.id, 'OFFICE'))
        }
    }, [branchById])

    useEffect(() => {
        if (activeSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_BRANCH'))
            setSwitchBoolean(true)
            setOpen(false)
        }
        if (inactiveSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_BRANCH'))
            setSwitchBoolean(false)
            setOpen(false)
        }
    }, [activeSuccess, inactiveSuccess])

    const handleOpenClose = () => {
        setOpen(!open)
    }

    const handleSwitch = () => {
        if (switchBoolean) {
            dispatch(branchActions.inactivateBranch(branchById.id))
        } else {
            dispatch(branchActions.activateBranch(branchById.id))
        }
    }

    const tabsLabels = [
        {
            label: 'Details'
        },
        {
            label: 'History'
        },
        {
            label: 'Notes'
        },
    ]

    const tabsContent = [
        {
            tabComponent:   <BranchInfoTable handleChangeName={setBranchName}  />,
            tab: 'Details'
        },
        {
            tabComponent: <History onModel={'branch'} resourceId={params.id}/>,
            tab: 'History'
        },
        {
            tabComponent:  <Notes/>,
            tab: 'Notes'
        }
    ];

    if (loader.length) return <Loader/>

    return (
        <div>
            <SimpleTabs
                head={
                    <div className={globalStyle.spaceBetween}>
                        <div style={{marginRight: '24px'}} className={globalStyle.centerItem}>
                            {RenderStatus(activeInactive)}
                            {RolePermission([PermissionsList.BRANCH_STATUS?.code]) &&
                                <AntSwitch
                                    onClick={handleOpenClose}
                                    checked={switchBoolean}
                                />
                            }
                        </div>
                        <div className={globalStyle.centerItem}>
                            <img style={{marginRight: '4px'}} src={Images.branchesBold} alt={"branch"}/>
                            <SlicedText type={'tableName'} size={20} data={name && name}/>
                        </div>
                    </div>
                }
                border={'blue'}
                height={'full'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                parentLink={'/branches'}
                parent={'Branches'}
                child={'Branch Info'}
                defaultTab={'Details'}
                paramsName={'branchParams'}
            />

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <ActiveInactive
                        loading={!!activeLoader?.length || !!inactiveLoader?.length}
                        text={switchBoolean === true ? `Inactivate ${name}` : `Activate ${name}`}
                        textInfo={activeInactive}
                        name={name}
                        handleClose={handleOpenClose}
                        handleDel={handleSwitch}
                    />
                }
            />
        </div>
    )
}