import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, officeActions} from "store";
import {
    createInputsWrapper,
    EmailValidator,
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccessItem,
    PermissionsList,
    RolePermission,
    useGlobalStyles,
    useGlobalText
} from "utils";
import {AddressInput, CreateChancel, Line, ValidationInput, EditSaveButtons, MaskInput} from "components";

export const CreateOfficeInputs = ({handleChangeName, info}) => {
    const globalTextStyle = useGlobalText();
    const globalStyles = useGlobalStyles();
    const globalScreens = createInputsWrapper();
    const dispatch = useDispatch();
    const history = useHistory();
    const [disables, setDisabled] = useState(!!info)
    const [fullAddress, setFullAddress] = useState({})
    const [error, setError] = useState('')
    const [inputs, setInputs] = useState(info ? {...info} : {});
    const [emailError, setEmailError] = useState('')
    const actionType = info ? 'EDIT_OFFICE' : 'CREATE_OFFICE'
    const loader = FindLoad('CREATE_OFFICE')
    const success = FindSuccessItem('EDIT_OFFICE')
    const backError = FindErrorItem(actionType)
    const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error

    useEffect(() => {
        if (info?.address) {
            const sendAddress = {
                address: info?.address?.formattedAddress,
            }
            if (info?.address?.unitNumber) {
                sendAddress.unitNumber = info?.address?.unitNumber
            }
            setFullAddress(sendAddress)
        }
    }, [info])

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType))
            setDisabled(true)
        }
    }, [success]);

    useEffect(() => {
        return () => {
            removeError()
        }
    }, [])

    const removeError = () => {
        errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType))
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}));
        error === e.target.name && setError(''),
        e.target.name === 'name' && handleChangeName(e.target.value), removeError()
    }

    const handleFullAddress = (ev) => {
        setFullAddress(ev)
        removeError()
        if (error === 'address') setError('')
    }

    const handleCreate = () => {
        const phoneNumber = inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + ''

        const data = {
            "name": inputs?.name,
            "email": inputs?.email,
            "phoneNumber": phoneNumber,
            "establishedDate": new Date(inputs?.establishedDate).getTime(),
            "address": fullAddress,
        };

        if (!emailError.length) {
            if (inputs.name && inputs.email && inputs.phoneNumber && inputs.establishedDate && fullAddress?.address) {
                if (info) {
                    dispatch(officeActions.editOffice(data, info.id))
                } else {
                    dispatch(officeActions.createOffice(data))
                }
            } else {
                setError(
                    !inputs.name ? 'name' :
                        !inputs.email ? 'email' :
                            !inputs.phoneNumber ? 'phoneNumber' :
                                !inputs.establishedDate ? 'date' :
                                    !fullAddress?.address ? 'address' : ''
                )
            }
        }
    }

    const handleChancel = () => {
        setDisabled(true)
        setInputs({...info})


        const sendAddress = {
            address: info?.address?.formattedAddress,
        }
        if (info?.address?.unitNumber) {
            sendAddress.unitNumber = info?.address?.unitNumber
        }
        setFullAddress(sendAddress)
        // setFullAddress(info?.address?.formattedAddress)
        setError('')
        setEmailError('')
        removeError()
    }

    return (
        <div className={globalScreens.createInputsWrapper}>
            <div className={globalStyles.spaceBetween}>
                <div className={globalStyles.centerItem}>
                    {!info && <p className={globalTextStyle.title}>Office Information</p>}
                </div>
                {info && RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) &&
                    <EditSaveButtons
                        margin={true}
                        type={'EDIT_OFFICE'}
                        handleChancel={handleChancel}
                        handleSetEdit={() => setDisabled(false)}
                        handleSaveInfo={handleCreate}
                    />
                }
            </div>

            <div className={globalStyles.flexAble}>
                {info && <Line height={'290px'}/>}
                <div className={globalStyles.fullWidth}>
                    <div className={globalScreens.basicInfo}>
                        <p className={globalTextStyle.smallText}>Basic Information</p>
                        <div className={globalScreens.basicInfoInputs}>

                            <ValidationInput
                                className={error !== 'name' &&
                                    errorText !== 'Office with this name exits' &&
                                    info && globalStyles.inputTextFieldBlue
                                }
                                style={globalStyles.simpleInput}
                                variant={"outlined"}
                                name={"name"}
                                type={"text"}
                                label={"Office Name*"}
                                typeError={error === 'name' ? ErrorText.field :
                                    errorText === 'Office with this name exits' ? 'Office with this name exits' :
                                        ''
                                }
                                onChange={handleChange}
                                value={inputs?.name}
                                disabled={disables}
                            />

                            <ValidationInput
                                className={info && globalStyles.inputTextFieldBlue}
                                style={globalStyles.simpleInput}
                                validator={EmailValidator}
                                variant={"outlined"}
                                name={"email"}
                                type={"email"}
                                label={"Email Address*"}
                                typeError={error === 'email' ? ErrorText.field : emailError === 'Not valid email' ? 'Not valid email' : ''}
                                sendBoolean={(bool) => bool === true ? setEmailError("Not valid email") : setEmailError('')}
                                onChange={handleChange}
                                value={inputs?.email}
                                disabled={disables}
                            />
                            <MaskInput
                                style={globalStyles.simpleInput}
                                name='phoneNumber'
                                label="Phone Number*"
                                value={inputs?.phoneNumber}
                                disables={disables}
                                handleChange={handleChange}
                                info={info}
                                error={
                                    error === 'phoneNumber' ? ErrorText.field :
                                        errorText === 'phoneNumber must be a valid phone number' && ErrorText.phone}
                            />
                            <ValidationInput
                                className={info && globalStyles.inputTextFieldBlue}
                                variant={"outlined"}
                                name={"establishedDate"}
                                type={"date"}
                                typeError={error === 'date' ? ErrorText.field : ''}
                                label={"Establishment Date*"}
                                onChange={handleChange}
                                value={info ? moment(inputs?.establishedDate).format('YYYY-MM-DD') : inputs.establishedDate}
                                disabled={disables}
                            />
                        </div>
                    </div>

                    <div className={globalScreens.basicInfo}>
                        <p className={globalTextStyle.smallText}>Address</p>
                        <div className={globalScreens.basicInfoInputs}>
                            <AddressInput
                                errorBoolean={
                                    error === 'address' ? 'Input is not field' :
                                        errorText === 'Unable to verify the address' ? 'Unable to verify the address' : ''}
                                info={info}
                                handleSelectValue={handleFullAddress}
                                disabled={disables}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {!info &&
                <CreateChancel
                    loader={!!loader.length}
                    classes={globalStyles.buttonsStyle}
                    create={"Create"}
                    chancel={"Cancel"}
                    onCreate={handleCreate}
                    onClose={() => history.push('/offices')}
                />
            }
        </div>
    );
}
