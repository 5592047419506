import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import {navBarStyles} from "./style";
import {Title} from "components";
import {UserInfo} from "./userInfo";
import {AppBar, Toolbar} from "@material-ui/core";
import Notification from "../notifications/notification";
import {notificationsActions} from "store";
import {AllRouting} from "utils";


export const TopBar = ({open}) => {
    const {notifications} = useSelector((state) => ({
        notifications: state.notifications.notifications,
    }))
    const classes = navBarStyles();
    const dispatch = useDispatch()
    const location = window.location.pathname
    let title = AllRouting.find((i) => i?.path === location)
    const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));
    const slicedLink = location.slice(1)
    const moreInfo = slicedLink.search('/') !== -1 &&  slicedLink.slice(0,  slicedLink.search('/'))

    useEffect(() => {
        const params = {pageSize: 6, page: 1,};
        dispatch(notificationsActions.getNotifications(params));
    }, [])

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: !!open,
            })}
        >
            <Toolbar className={classes.headerContent}>
                <div className={classes.Toolbar}>
                    <div
                        className={
                            !!open === true ? classes.openToolbar : classes.closeToolbar
                        }
                    >
                        <Title text={
                            title?.name ? title?.name :
                                `${moreInfo ? moreInfo.charAt(0).toUpperCase() + moreInfo.slice(1) : ''} Info`}
                        />
                    </div>

                    <div className={classes.notAndUser}>
                        <Notification notifications={notifications}/>
                        <UserInfo userInfo={userInfo}/>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};
