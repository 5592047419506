import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Loader, SimpleTabs} from "components";
import {PayrollBracket, Quickbooks, McConfigurations, RmisUpdates} from "./core";
import {FindLoad, PermissionsList, RolePermission} from "utils";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {SaveParams} from "../../utils/hooks/paginate";

export const SystemTable = () => {
    const loader = FindLoad('GET_SYSTEM_DEFAULT_BRACKET')
    const history = useHistory()
    const [quickStatus, setQuickStatus] = useState(null)
    const location = useLocation()
    const info = history?.location?.state
    const {systemBrackets} = useSelector((state) => ({
        systemBrackets: state.brackets.systemBrackets,
    }));

    useEffect(() => {
        if(location?.pathname === "/system/qb"){
            if(location?.search) {
                setQuickStatus(location?.search)
            }
            const anotherInfo = {
                ...info,
                activeTab: 2
            }
            SaveParams(history, {...anotherInfo})
        }
    }, [])

    const tabsLabels = [
        RolePermission([PermissionsList.READ_PAYROLL_SETUP?.code, PermissionsList.CREATE_UPDATE_PAYROLL_SETUP?.code,]) &&
        {
            label: 'Payroll Setup'
        },
        RolePermission([PermissionsList.READ_MY_CONF?.code, PermissionsList.CREATE_UPDATE_MY_CONF?.code,]) &&
        {
            label: 'Mc Configurations'
        },
        RolePermission([PermissionsList.READ_QUICKBOOKS?.code, PermissionsList.ADD_QUICKBOOKS?.code]) &&
        {
            label: 'Quickbooks'
        },
        RolePermission([PermissionsList.CHANGE_RMIS_SETTINGS?.code,]) &&
        {
            label: 'RMIS'
        },
    ]

    const tabsContent = [
        RolePermission([PermissionsList.READ_PAYROLL_SETUP?.code, PermissionsList.CREATE_UPDATE_PAYROLL_SETUP?.code,]) &&
        {
            tabComponent: loader.length ? <Loader/> : <PayrollBracket data={systemBrackets}/>,
            tab: 'Payroll Setup'
        },
        RolePermission([PermissionsList.READ_MY_CONF?.code, PermissionsList.CREATE_UPDATE_MY_CONF?.code,]) &&
        {
            tabComponent: <McConfigurations/>,
            tab: 'Mc Configurations'
        },
        RolePermission([PermissionsList.READ_QUICKBOOKS?.code, PermissionsList.ADD_QUICKBOOKS?.code]) &&
        {
            tabComponent: <Quickbooks quickStatus={quickStatus}/>,
            tab: 'Quickbooks'
        },
        RolePermission([PermissionsList.CHANGE_RMIS_SETTINGS?.code]) &&
        {
            tabComponent: <RmisUpdates/>,
            tab: 'RMIS'
        },
    ]

    return (
        <SimpleTabs
            defaultTab={'Payroll Setup'}
            noBread={true}
            tabsLabels={tabsLabels}
            tabsContent={tabsContent}
        />
    )
}