import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { agentActions, httpRequestsOnSuccessActions, mcActions, officeActions } from 'store';
import { ActiveInactive, History, Loader, Notes, SimpleModal, SimpleTabs, SlicedText } from 'components';
import {
  AntSwitch,
  FindLoad,
  FindSuccessItem,
  Images,
  PermissionsList,
  RenderStatus,
  RolePermission,
  useGlobalStyles,
} from 'utils';
import { ACTIVATE_OFFICE, INACTIVATE_OFFICE } from '../../store/offices/offices.types';
import { Branches } from '../../fragments/offices/officeInfo/core/branches';
import { OfficeInfoTable } from 'fragments';

export const OfficesInfoPage = () => {
  const { officeById } = useSelector((state) => ({
      officeById: state.offices.officeById,
    }),
  );
  const globalStyle = useGlobalStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const info = history?.location?.state;
  const [switchBoolean, setSwitchBoolean] = useState(false);
  const [officeName, setOfficeName] = useState('');
  const [open, setOpen] = useState(false);
  const name = officeName ? officeName : officeById && officeById.name;
  const activeInactive = switchBoolean === true ? 'Inactivate' : 'Activate';
  const loader = FindLoad('GET_OFFICE_BY_ID');
  const inactiveSuccess = FindSuccessItem('INACTIVATE_OFFICE');
  const activeSuccess = FindSuccessItem('ACTIVATE_OFFICE');
  const activeLoader = FindLoad('ACTIVATE_OFFICE');
  const inactiveLoader = FindLoad('INACTIVATE_OFFICE');

  useEffect(() => {
    dispatch(officeActions.getOfficeById(params.id));
    dispatch(agentActions.getAgentByOffice(params.id));
    dispatch(agentActions.getAgents({ status: 'ACTIVE' }));
    dispatch(mcActions.getAssignedMcs(params.id, 'OFFICE'));
    dispatch(mcActions.getOfficeAssignedMcs(params.id, 'OFFICE'));
    dispatch(officeActions.getOfficeBranches(params.id, { status: 'ACTIVE' }));
  }, []);

  useEffect(() => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    dispatch(officeActions.getOfficeBranches(params.id, { ...filteredInfo }));
  }, [info]);


  useEffect(() => {
    if (inactiveSuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_OFFICE'));
      setSwitchBoolean(false);
      setOpen(false);
    }
    if (activeSuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_OFFICE'));
      setSwitchBoolean(true);
      setOpen(false);
    }
  }, [inactiveSuccess, activeSuccess]);

  useEffect(() => {
    if (officeById) {
      setSwitchBoolean(officeById.status === 'ACTIVE');
    }
  }, [officeById]);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const handleSwitch = () => {
    if (switchBoolean) {
      dispatch(officeActions.inactivateOffice(officeById?.id));
    } else {
      dispatch(officeActions.activateOffice(officeById?.id));
    }
  };

  const tabsLabels = [
    {
      label: 'Details',
    },
    {
      label: 'Branches',
    },
    {
      label: 'History',
    },
    {
      label: 'Notes',
    },
  ];

  const tabsContent = [
    {
      tabComponent: <OfficeInfoTable handleChangeName={setOfficeName} />,
      tab: 'Details',
    },
    {
      tabComponent: <Branches />,
      tab: 'Branches',
    },
    {
      tabComponent: <History onModel={'office'} resourceId={params.id} />,
      tab: 'History',
    },
    {
      tabComponent: <Notes />,
      tab: 'Notes',
    },
  ];

  if (loader.length) return <Loader />;

  return (
    <>
      <SimpleTabs
        head={
          <div className={globalStyle.spaceBetween}>
            <div style={{ marginRight: '24px' }} className={globalStyle.centerItem}>
              {RenderStatus(activeInactive)}
              {RolePermission([PermissionsList.OFFICE_STATUS?.code]) &&
                <AntSwitch
                  onClick={handleOpenClose}
                  checked={switchBoolean}
                />
              }
            </div>
            <div className={globalStyle.centerItem}>
              <img style={{ marginRight: '4px' }} src={Images.officeFillBold}
                   alt={'authorityBlueFill'} />
              <SlicedText type={'tableName'} size={20} data={name && name} />
            </div>
          </div>
        }
        border={'blue'}
        height={'full'}
        tabsLabels={tabsLabels}
        tabsContent={tabsContent}
        parentLink={'/offices'}
        parent={'Office'}
        child={'Office Info'}
        defaultTab={'Details'}
        paramsName={'officeParams'}
      />
      <SimpleModal
        handleOpenClose={handleOpenClose}
        openDefault={open}
        content={
          <ActiveInactive
            loading={!!activeLoader?.length || !!inactiveLoader?.length}
            text={switchBoolean === true ? `Inactivate ${name}` : `Activate ${name}`}
            textInfo={activeInactive}
            name={name}
            handleClose={handleOpenClose}
            handleDel={handleSwitch}
          />
        }
      />
    </>
  );
};