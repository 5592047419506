import React, {useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Circle, NoYet, PaginationItem, PriceConvertor, TypeRow,} from "components";
import {Colors, useGlobalText, useModal} from "utils";
import {customerPaymentInfo} from "../../../customerPayment/customerPaymentInfo/core/styles";
import {PaymentTableModal} from "fragments";

export const Payments = ({}) => {
    const {paymentsByCarrier,} = useSelector((state) => ({
        paymentsByCarrier: state.carriers.paymentsByCarrier,
    }));
    const classes = customerPaymentInfo();
    const list = paymentsByCarrier?.pmts?.length && paymentsByCarrier?.pmts
    const {open} = useModal()


    return (
        <div>
            <div className='payments-accordion-wrapper' style={{padding: 0}}>
                {list?.length ? list.map((i, k) => (
                        <div key={k}>
                            <Accordion>
                                <AccordionSummary
                                    className={classes.accordion}
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className='space-between'>
                                        <div>
                                            <p className='head-item'>Method: <b>
                                                <TypeRow text={i.method}/>
                                               </b></p>
                                        </div>
                                        <div>
                                            <p className='head-item'>Reference: <b>{i.referenceNumber}</b></p>
                                        </div>
                                        <div>
                                            <p className='head-item'>Paid
                                                Date: <b>{moment(i.createdAt).format('MM-DD-YYYY')}</b></p>
                                        </div>
                                        <div style={{marginRight: '50px'}}>
                                            <p className='head-item'>Paid Amount:<b>&nbsp;
                                                <PriceConvertor price={i?.amount ? i.amount : 0}/>
                                            </b></p>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{boxShadow: '0px 0px 6px #8A8A8A29',}}>
                                    <div className={classes.bodyWrapper}>
                                        <div className='space-between'>
                                            <p className='payment-load-title'>Loads</p>
                                        </div>
                                        <div className='payments-table'>
                                            <div className='flex'>
                                                <p className='payments-table-head'>
                                                    <b>Load ID</b>
                                                </p>
                                                <p className='payments-table-head'>
                                                    <b> Total Amount</b>
                                                </p>
                                                <p/>
                                            </div>
                                            {i?.loadPmts?.length ? i.loadPmts.map((item, k) => (
                                                    <div key={k} className='payments-table-body'>
                                                        <p className='payments-table-body-item'>{item?.displayId}</p>
                                                        <p className='payments-table-body-item'>
                                                            <PriceConvertor price={item?.amountPaid ? item?.amountPaid : 0}/>
                                                        </p>
                                                        <button className='details-button'
                                                                onClick={() => open( <PaymentTableModal
                                                                    loadId={item.loadId}/>)}
                                                        >
                                                            Details
                                                        </button>
                                                    </div>
                                                )) :
                                                <NoYet position={'screenCenter'} text={'No Load Yet'}/>
                                            }
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))
                    :
                    <NoYet position={'center'} text={'No Payment Yet'}/>
                }
            </div>
        </div>
    )
}