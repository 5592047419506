import {
   CREATE_GLOBAL_NOTE,
   DELETE_GLOBAL_NOTE,
   EDIT_GLOBAL_NOTE,
   GET_GLOBAL_NOTES,
} from "./note.type";

export const getGlobalNotes = (id, data) => {
   return {
      type: GET_GLOBAL_NOTES,
      payload: { id, data },
   };
};

export const createGlobalNote = (body) => {
   return {
      type: CREATE_GLOBAL_NOTE,
      payload: {body},
   };
};

export const editGlobalNote = (fId, id, body) => {
   return {
      type: EDIT_GLOBAL_NOTE,
      payload: { fId, body, id },
   };
};

export const deleteGlobalNote = (id, SId) => {
   return {
      type: DELETE_GLOBAL_NOTE,
      payload: { id, SId },
   };
};
