import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  Credit,
  Consign,
  Rejected,
  ConsignmentRequest,
  FactoredLoads, FactoredLoadDetails, MyProfilePage,
} from 'pages';
import { CreateFactoredLoadTable } from 'fragments';

export const FactoredRouter = ({}) => {
  return (
    <Switch>
      <Route path="/my-profile" component={MyProfilePage} />
      <Route path="/factored-loads" component={FactoredLoads} />
      <Route path="/create-factored-load" component={CreateFactoredLoadTable} />
      <Route path="/factored-load/:id" component={FactoredLoadDetails} />
      {/*Credit */}
      <Route path="/credit" component={Credit} />
      <Route path="/rejected" component={Rejected} />
      <Route path="/consignmentRequest" component={ConsignmentRequest} />
      <Route path="/consign" component={Consign} />
      <Redirect to={'/'} />
    </Switch>
  );
};
