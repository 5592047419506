import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {managementFragments} from "./style";
import {
    DeleteButton,
    SearchAndFilter,
    SimpleModal,
    DeleteElement,
    Loader,
    NoYet,
    SlicedText,
} from "components";
import {FindLoad, FindSuccessItem, Images} from "utils";
import {httpRequestsOnSuccessActions, roleActions} from "store";

export const Role = ({key, roleInfo, handleEdit}) => {
    const classes = managementFragments();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [role, setRole] = useState('')
    const [activeRole, setActiveRole] = useState('')
    const loader = FindLoad('GET_ROLE')
    const loadDel = FindLoad('DELETE_ROLE')
    const success = FindSuccessItem('DELETE_ROLE')

    useEffect(() => {
        if (success) {
            setOpen(false)
            setRole('id')
            dispatch(roleActions.removeRole())
            dispatch(httpRequestsOnSuccessActions.removeSuccess('DELETE_ROLE'))
        }
    }, [success])

    const handleOpenClose = (e, id) => {
        e.stopPropagation()
        setOpen(!open)
        setRole(id)
    }

    const searchRole = (ev) => {
        dispatch(roleActions.searchRoles(ev.target.value));
    };

    const openRolePermission = (role) => {
        dispatch(roleActions.getRoleById(role?.id));
        setActiveRole(role.title)
    };

    const deleteRole = () => {
        dispatch(roleActions.deleteRole(role.id))
    }

    const handleEditOpen = (e, item) => {
        e.stopPropagation()
        handleEdit(item)
    }

    return (
        <div key={key} className={classes.tableStyle}>
            <div className={classes.tableHeadStyle}>
                <SearchAndFilter
                    handleSearch={(ev) => searchRole(ev)}
                    item={{name: 'type', title: 'Type', custom: false}}
                />
            </div>
            <div className={classes.scroll}>
                {loader?.length ?
                    <Loader style={'relative'}/> : roleInfo && roleInfo.length ?
                        roleInfo.map((item, j) => (
                            <div
                                onClick={() => openRolePermission(item)}
                                key={j}
                                className={activeRole === item.title ? classes.tableBodyBottomActive : classes.tableBodyBottom}
                            >
                                <div className={classes.tableBodyStyle}>
                                    <div>
                                        <img
                                            src={Images.accessManagementUser}
                                            alt={"accessManagementUser"}
                                        />
                                        <SlicedText type={'name'} size={17} data={item && item.title}/>
                                        <SlicedText type={'desc'} size={40} data={item && item.description}/>
                                    </div>
                                    <div className={classes.wrapper}>
                                        <button onClick={(e) => handleEditOpen(e, item)} className={classes.editButton}>
                                            <img src={Images.edit} alt='edit'/>
                                        </button>
                                        <DeleteButton
                                            top={1}
                                            toolTipTitle={"Remove Role"}
                                            handleClick={(e) => handleOpenClose(e, item)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                        : <div className={classes.noItemPaddings}><NoYet text={'No Role Yet'}/></div>}
            </div>
            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={!!loadDel?.length}
                        text={'Delete Role?'}
                        className={classes}
                        handleClose={handleOpenClose}
                        handleDel={deleteRole}
                        info={role && role.title}
                    />
                }
            />
        </div>
    );
};