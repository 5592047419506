import React, { useState } from 'react';
import clsx from 'clsx';
import { navBarStyles } from './style';
import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { GlobalLogo } from 'components';
import { Images, PermissionsList, ProfileRouters, RolePermission, SettingsAndLoads, userType, isAgent } from 'utils';
import {
  Claims,
  CreditLoads,
  FactoredCreditLoads,
  LoadAccounting,
  Payroll,
  reportActions,
} from '../../../utils/routers/routers';
import { AccordionRow } from './accordionRow';
import { ListItemRow } from './listItemRow';
import { useSelector } from 'react-redux';
import { isFactored } from 'utils';

export const LeftBar = ({ handleDrawerClose, open }) => {
  const { loadTotals } = useSelector((state) => ({
    loadTotals: state.loads.loadTotals,
  }));
  const classes = navBarStyles();
  const LoadActionsList = LoadAccounting();
  const ClaimsList = Claims(loadTotals);
  const CreditList = CreditLoads(loadTotals);
  const FactoredCreditList = FactoredCreditLoads(loadTotals);
  const [accounting, setAccounting] = useState('');
  const creditCount = (loadTotals?.numCreditRequests ? loadTotals?.numCreditRequests : 0) + (loadTotals?.numConsignmentRequest ? loadTotals?.numConsignmentRequest : 0);
  const claimCount = (loadTotals?.numBonds ? loadTotals?.numBonds : 0) + (loadTotals?.numOnClaim ? loadTotals?.numOnClaim : 0);

  return (
    <div id={'muiAccordion'} className={classes.wrapp}>
      <div
        className={open === false ? classes.transition : classes.transitionOpen}
      >
        <IconButton
          style={{ background: '#1C2331 0% 0% no-repeat padding-box' }}
          className={classes.IconButtonStyle}
          onClick={handleDrawerClose}
        >
          {open === false ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <GlobalLogo />
        <Divider />
        <div className={classes.leftSection} />

        {isFactored ?
          <List className={classes.menuItems}>
            <ListItemRow
              list={[{
                'name': 'My Profile',
                'icon': Images.profilesGray,
                'activeIcon': Images.profilesFill,
                'path': '/my-profile',
              },
              ]}
              open={open}
              setAccounting={() => setAccounting('')}
            />
            <ListItemRow
              list={[{
                'name': 'Loads',
                'icon': Images.loads,
                'activeIcon': Images.activeLoad,
                'create': '/create-factored-load',
                'path': '/factored-loads',
              }]}
              open={open}
              setAccounting={() => setAccounting('')}
            />
            <AccordionRow
              list={FactoredCreditList}
              name={'Credit'}
              activeIcon={Images.creditFillBold}
              passiveIcon={Images.creditFill}
              open={open}
              accounting={accounting}
              setAccounting={setAccounting}
              activeCount={creditCount}
            />
          </List>

          :

          <List className={classes.menuItems}>
            {isAgent &&
              <ListItemRow
                list={[{
                  'name': 'My Profile',
                  'icon': Images.profilesGray,
                  'activeIcon': Images.profilesFill,
                  'path': '/my-profile',
                },
                ]}
                open={open}
                setAccounting={() => setAccounting('')}
              />
            }

            {RolePermission([
                PermissionsList.ALL_ACCESS?.code,
                PermissionsList.READ_OFFICE?.code,
                PermissionsList.CREATE_UPDATE_OFFICE?.code,

                PermissionsList.READ_BRANCH?.code,
                PermissionsList.CREATE_UPDATE_BRANCH?.code,

                PermissionsList.READ_ADMIN?.code,
                PermissionsList.CREATE_UPDATE_ADMIN?.code,

                PermissionsList.READ_CUSTOMER?.code,
                PermissionsList.CREATE_UPDATE_CUSTOMER?.code,

                PermissionsList.READ_FACTORING?.code,
                PermissionsList.CREATE_UPDATE_FACTORING?.code,

                PermissionsList.READ_MC?.code,
                PermissionsList.CREATE_UPDATE_MC?.code,

                PermissionsList.READ_CARRIER?.code,
                PermissionsList.CREATE_UPDATE_CARRIER?.code,

                PermissionsList.READ_AGENT?.code,
                PermissionsList.CREATE_UPDATE_AGENT?.code,

              ], 'agentView') &&

              <AccordionRow
                list={ProfileRouters}
                name={'Profiles'}
                activeIcon={Images.profilesFill}
                passiveIcon={Images.profilesGray}
                open={open}
                accounting={accounting}
                setAccounting={setAccounting}
              />
            }

            <ListItemRow
              list={SettingsAndLoads}
              open={open}
              setAccounting={() => setAccounting('')}
            />


            <AccordionRow
              list={CreditList}
              name={'Credit'}
              activeIcon={Images.creditFillBold}
              passiveIcon={Images.creditFill}
              open={open}
              accounting={accounting}
              setAccounting={setAccounting}
              activeCount={creditCount}
            />

            {userType === 'ADMIN' &&
              RolePermission([
                PermissionsList.ALL_ACCESS?.code,
                PermissionsList.READ_PROCESSING?.code,
                PermissionsList.READ_BILLING?.code,
                PermissionsList.READ_RECEIVABLES?.code,
                PermissionsList.READ_CUSTOMER_PAYMENT?.code,
                PermissionsList.READ_PAYMENT_VERIFICATION?.code,
                PermissionsList.READ_PAYABLE?.code,
                PermissionsList.READ_CARRIER_PAYMENT?.code,
              ], 'agentView') &&

              <AccordionRow
                list={LoadActionsList}
                name={'Accounting'}
                activeIcon={Images.accountingFill}
                passiveIcon={Images.accountingGray}
                open={open}
                accounting={accounting}
                setAccounting={setAccounting}
              />
            }

            {RolePermission([
                PermissionsList.ALL_ACCESS?.code,
                PermissionsList.READ_CLAIM_LIST?.code,
                PermissionsList.READ_BOND_LIST?.code,
              ]) &&
              <AccordionRow
                list={ClaimsList}
                name={'Claims'}
                activeIcon={Images.claimFill}
                passiveIcon={Images.claimGray}
                open={open}
                accounting={accounting}
                setAccounting={setAccounting}
              />
            }

            <ListItemRow
              list={Payroll}
              open={open}
              setAccounting={() => setAccounting('')}
            />


            {userType === 'ADMIN' && RolePermission([
                PermissionsList.READ_AR_DETAILED_REPORT?.code,
                PermissionsList.READ_AR_SUMMARY_REPORT?.code,
                PermissionsList.READ_AR_ACTIVITY_REPORT?.code,
                PermissionsList.READ_AR_PAYMENT_APPLICATION_REPORT?.code,
                PermissionsList.READ_AP_DETAILED_REPORT?.code,
                PermissionsList.READ_AP_SUMMARY_REPORT?.code,
                PermissionsList.READ_AP_ACTIVITY_REPORT?.code,
                PermissionsList.READ_AP_PAYMENT_APPLICATION_REPORT?.code,
              ]) &&

              <AccordionRow
                list={reportActions}
                name={'Report'}
                activeIcon={Images.activeReports}
                passiveIcon={Images.reports}
                open={open}
                accounting={accounting}
                setAccounting={setAccounting}
              />
            }
          </List>
        }
        <Divider />
      </Drawer>
    </div>
  );
};
