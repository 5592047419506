import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { carrierActions } from 'store';
import { CsvFile, FullTable, SearchInput, TableWrapper } from 'components';
import { ACTION_TYPE, carrierPaymentCsv, carrierPmtBody, carrierPmtHead } from './constants';
import { PermissionsList, RolePermission } from '../../../utils';

export const CarrierPayment = ({}) => {
  const { carrierPayments } = useSelector((state) => ({
    carrierPayments: state.carriers.carrierPayments,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;

  useEffect(() => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    if (!info?.loadDisplayId) {
      delete filteredInfo.loadDisplayId;
    }
    dispatch(carrierActions.getCarrierPayments({ ...filteredInfo }));
  }, [info]);

  const pushPageDetails = (id) => {
    history.push({
      pathname: `/carrierPayment/${id}`,
      state: { carrierPaymentParams: { ...info } },
    });
  };

  return (
    <div>
      {/*{RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EXPORT_CARRIER_PMT_CSV?.code]) &&*/}
      {/*<div className="flex-end" style={{ marginBottom: '16px' }}>*/}
      {/*  <CsvFile params={carrierPaymentCsv} />*/}
      {/*</div>*/}
      {/*}*/}
      <div style={{ position: 'absolute', right: '32px' }}>
        <SearchInput />
      </div>
      <TableWrapper
        buttonsTab={true}
        link={true}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        <FullTable
          head={carrierPmtHead}
          body={carrierPmtBody}
          loadingType={ACTION_TYPE}
          list={carrierPayments?.pmts}
          listCount={carrierPayments?.count}
          handleClick={pushPageDetails}
          // (id) => history.push(`/carrierPayment/${id}`)
          noText={'Carrier Payments'}
          dateFilter={true}
          startEnd={true}
        />
      </TableWrapper>
    </div>
  );
};