import { Colors, Images, PermissionsList, RolePermission, userType } from 'utils';
import { IconAndTitle, TextRow, PhoneRow, MinLoader } from 'components';
import React from 'react';

export const carrierStatuses = [
  { id: 'All', name: 'All' },
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'PENDING', name: 'Pending' },
];

export const carrierStatusesEnum = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'PENDING', name: 'Pending' },
];

export const statusesEnum = {
  'ACTIVE': 'ACTIVE',
  'INACTIVE': 'INACTIVE',
  'PENDING': 'PENDING',
};


export const carrierCsv = {
  type: 'carrier',
  statusList: carrierStatuses,
  requiredStatus: true,
  statusEnum: 'statuses',
  statusesEnum: statusesEnum
};

export const CARRIER_DOCKS = {
  PROOF_OF_INSURANCE: 'PROOF_OF_INSURANCE',
  CARRIERPACKET: 'CARRIERPACKET',
  SETUPPACKET: 'SETUPPACKET',
  W9: 'W9',
  MC_CERTIFICATE: 'MC_CERTIFICATE',
  INTERSTATE_PERMIT: 'INTERSTATE_PERMIT',
  CDL: 'CDL',
  EFS_FORM: 'EFS_FORM',
  PAYMENT_FORM: 'PAYMENT_FORM',
  NOA: 'NOA',
  LOR: 'LOR',
  WRITTEN_CONSENT: 'WRITTEN_CONSENT',
  VOIDED_CHECK: 'VOIDED_CHECK',
  OTHER: 'OTHER',
};

export const CARRIER_DOCK_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
};
export const appStatus = {
  PENDING: 1,
  ACTIVE: 2,
  INACTIVE: 3,
};
export const carriersHead = [
  { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
  { name: 'docketNumber', title: 'Authority Number', custom: false },
  { name: 'name', title: 'Carrier Name' },
  { name: 'phone', title: 'Phone Number', custom: false },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
  { name: '', title: 'App Invitation', custom: false },
  { name: '', title: 'RMIS', custom: false },
  { name: '', title: 'Status', custom: false },
];

export const carriersBody = (loaders) => {
  return [
    { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
    { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
    { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
    { rowText: (item) => <PhoneRow phone={item?.phone} /> },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
    { button: (item, info) => renderAppInvitation(item, info, loaders), notClickable: true },
    { button: (item, info) => renderRmis(item, info, loaders), notClickable: true },
    { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
  ];
};

export const ACTION_TYPE = 'GET_CARRIERS';

const renderRmis = (data, info, loaders) => {
  if (info?.rmisStatus === 'INACTIVE') {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Inactive</p>
        {userType === 'ADMIN' &&
          <button
            onClick={() => data('rmisInvite')}
          >
            {loaders?.loadRmis?.length && loaders?.id === info?.id ?
              <MinLoader style={{ margin: 0, position: 'relative' }} color={Colors.ThemeBlue} />
              :
              'Reinvite'
            }
          </button>
        }
      </div>
    );
  } else if (info?.rmisStatus === 'ACTIVE') {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Active</p>
      </div>
    );
  }
};

const renderAppInvitation = (data, info, loaders) => {
  if (appStatus?.INACTIVE === info?.appStatus) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Inactive</p>
        <button
          onClick={() => data('appInvitation')}
        >
          {loaders?.inviteLoader?.length && info?.id === loaders?.id ?
            <MinLoader style={{ margin: 0, position: 'relative' }} color={Colors.ThemeBlue} />
            :
            'Invite'
          }
        </button>
      </div>
    );
  } else if (appStatus?.PENDING === info?.appStatus) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Pending</p>
        <button
          onClick={() => data('appReInvitation')}
        >
          {loaders?.reInviteLoader?.length && info?.id === loaders?.id ?
            <MinLoader style={{ margin: 0, position: 'relative' }} color={Colors.ThemeBlue} />
            :
            'ReInvite'
          }
        </button>
      </div>
    );
  } else if (appStatus?.ACTIVE === info?.appStatus) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Active</p>
      </div>
    );
  } else {
    return '';
  }
};


const renderStatus = (data, info, loaders) => {
  if (info?.status === statusesEnum?.ACTIVE) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Active</p>
      </div>
    );
  } else if (info?.status === statusesEnum?.INACTIVE) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Inactive</p>
      </div>
    );
  } else if (info?.status === statusesEnum?.PENDING) {
    return (
      <div className='acceptDenyWrapper'>
        {loaders?.acceptDenyLoader?.length && info?.id === loaders?.id ?
          <MinLoader style={{ margin: 0, position: 'relative' }} color={Colors.ThemeBlue} />
          :
          <>
            <button onClick={() => data('accept')}>
              <img src={Images.approveIcon} alt="icons" />
            </button>
            <button onClick={() => data('deny')}>
              <img src={Images.clear} alt="icons" />
            </button>
          </>
        }
      </div>
    );
  } else {
    return '';
  }
};


export const homeBaseById = [
  { name: 'AL', id: 'AL' },
  { name: 'AK', id: 'AK' },
  { name: 'AZ', id: 'AZ' },
  { name: 'AR', id: 'AR' },
  { name: 'CA', id: 'CA' },
  { name: 'CA(N)', id: 'CA(N)' },
  { name: 'CA(S)', id: 'CA(S)' },
  { name: 'CO', id: 'CO' },
  { name: 'CT', id: 'CT' },
  { name: 'DE', id: 'DE' },
  { name: 'FL', id: 'FL' },
  { name: 'GA', id: 'GA' },
  { name: 'HI', id: 'HI' },
  { name: 'ID', id: 'ID' },
  { name: 'IL', id: 'IL' },
  { name: 'IN', id: 'IN' },
  { name: 'IA', id: 'IA' },
  { name: 'KS', id: 'KS' },
  { name: 'KY', id: 'KY' },
  { name: 'LA', id: 'LA' },
  { name: 'ME', id: 'ME' },
  { name: 'MD', id: 'MD' },
  { name: 'MA', id: 'MA' },
  { name: 'MI', id: 'MI' },
  { name: 'MN', id: 'MN' },
  { name: 'MS', id: 'MS' },
  { name: 'MO', id: 'MO' },
  { name: 'MT', id: 'MT' },
  { name: 'NE', id: 'NE' },
  { name: 'NV', id: 'NV' },
  { name: 'NH', id: 'NH' },
  { name: 'NJ', id: 'NJ' },
  { name: 'NM', id: 'NM' },
  { name: 'NY', id: 'NY' },
  { name: 'CA(N)', id: 'CA(N)' },
  { name: 'CA(S)', id: 'CA(S)' },
  { name: 'NC', id: 'NC' },
  { name: 'ND', id: 'ND' },
  { name: 'OH', id: 'OH' },
  { name: 'OK', id: 'OK' },
  { name: 'OR', id: 'OR' },
  { name: 'PA', id: 'PA' },
  { name: 'RI', id: 'RI' },
  { name: 'SC', id: 'SC' },
  { name: 'SD', id: 'SD' },
  { name: 'TN', id: 'TN' },
  { name: 'TX', id: 'TX' },
  { name: 'UT', id: 'UT' },
  { name: 'VT', id: 'VT' },
  { name: 'VA', id: 'VA' },
  { name: 'WA', id: 'WA' },
  { name: 'WV', id: 'WV' },
  { name: 'WI', id: 'WI' },
  { name: 'WY', id: 'WY' },
  { name: 'MEXICO', id: 'MEXICO' },
  { name: 'BC', id: 'BC' },
  { name: 'AB', id: 'AB' },
  { name: 'SK', id: 'SK' },
  { name: 'MB', id: 'MB' },
  { name: 'ON', id: 'ON' },
  { name: 'PQ', id: 'PQ' },
];

