import moment from "moment";
import { SavePage } from '../../utils';

export const handleGetDate = (info) => {
    const date1 = new Date(info);
    const date2 = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    return Math.round(diffInTime / oneDay);
}

export const handleReturnColor = (date, termDay) => {
    const days = handleGetDate(date)

    const finalDate = Math.ceil(days / termDay)
    if (finalDate <= 1) {
        return '#00C8511A'
    } else if (finalDate > 1 && finalDate <= 2) {
        return '#438AFE1A'
    } else if (finalDate > 2 && finalDate <= 3) {
        return '#FFA3301A'
    } else {
        return '#D725251A'
    }
}

export const renderDates = ( date ) => {
    if(date){
        return moment(date).format('MM/DD/YYYY')
    }else{
        return 'N/A'
    }
}




export const filterFromTable = (e, nameRow, list, type) => {
    const profit = (el) => {
        return el?.customerRate - el?.carrierRate - el?.agentCharges ||  0
    }
    const totalRec = (el) => {
        return el?.totalBilled - el?.customerPaid
    }
    const remainingAmount = (el) => {
        return el?.amount - el?.used
    }

           return  list?.filter((el) =>
              nameRow ?
                type === 'date' ?
                  el[e.target.name] && el[e.target.name][nameRow] && moment(el[e.target.name][nameRow]).format('MM/DD/YYYY').toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
                  :
                  el[e.target.name] && el[e.target.name][nameRow] && el[e.target.name][nameRow].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
                :
                e.target.name === 'address' ?
                  el?.address?.formattedAddress.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                  :
                  type === 'date' ?
                    el[e.target.name] && moment(el[e.target.name]).format('MM/DD/YYYY').toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                    :
                    e.target.name === 'profit' ?
                      profit(el).toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
                      :
                      e.target.name === 'remainingAmount' ?
                        remainingAmount(el).toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
                        :
                        e.target.name === 'totalReceivable' ?
                          totalRec(el).toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
                          :
                          e.target.name === 'pmtMethod' ?
                            el?.method?.toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
                            :
                            el[e.target.name] && el[e.target.name].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
            )
        }



export const  handleSelectSort = (e, history, info) => {
    if (e.target.value === 'All') {
        const cleanList = {
            ...info
        }
        cleanList.skip = 0
        cleanList.page = 1
        delete cleanList[e.target.name]
        SavePage(history, info, {...cleanList})
    } else {
        if (!e.target.value) {
            const filteredList = {
                ...info
            }
            filteredList.skip = 0
            filteredList.page = 1
            delete filteredList[e.target.name]
            SavePage(history, info, {...filteredList})
        } else {
            const newObj = {
                ...info
            }
            newObj.skip = 0
            newObj.page = 1
            newObj[e.target.name] = e.target.value
            SavePage(history, info, {...newObj})
        }
    }
}

export const handleSetName = (name, history, info) => {
    const anotherInfo = {...info}
    if (name) {
        anotherInfo.sortType = name
    } else {
        delete anotherInfo.sortType
    }
    SavePage(history, info, {...anotherInfo})
}
