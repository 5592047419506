import {InputLabel, Select, FormControl, InputAdornment, FormHelperText} from "@material-ui/core";
import {inputsStyle} from "./styles";
import {InputMinLoader} from "./inputMiniLoader";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

export const SelectInput = ({
                                className,
                                loader,
                                name,
                                label,
                                handleSelect,
                                style,
                                value,
                                list,
                                handleChangeCountryCode,
                                typeError,
                                type,
                                disabled,
                                handleBlur,
    noHelper,
                            }) => {
    const classes = inputsStyle();
    const history = useHistory()
    const info = history?.location?.state
    const [current, setCurrent] = React.useState(  'ALL');

    useEffect(() => {
        if(value){
            setCurrent(value)
        }else{
            setCurrent('')
        }
    }, [value])

    useEffect(() => {
        if(info?.status){
            setCurrent(info?.status)
        }
    }, [info])

    const handleChange = (event) => {
        handleSelect && handleSelect(event)
        // setCurrent(event.target.value);
        const selectedIndex = event.target.options.selectedIndex;
        if (handleChangeCountryCode) {
            handleChangeCountryCode(event.target.options[selectedIndex].getAttribute('data-key'))
        }
    };

    return (
        <>
            <div className={style ? style : classes.SignInInput}>
                <FormControl variant="outlined" className={className ? className : classes.inputTextField}
                             error={!!typeError}
                >
                    <InputLabel disabled={disabled === true} htmlFor="outlined-age-native-simple">{label}</InputLabel>
                    <Select
                        disabled={disabled === true}
                        native
                        value={loader ? '' : current}
                        defaultValue={current}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={label}
                        name={name}
                        error={!!typeError}
                        inputProps={{
                            name: name,
                            id: 'outlined-age-native-simple',
                        }}
                        endAdornment={
                            loader ? <InputMinLoader/> : ''
                        }
                    >
                        {type === 'mc' ?
                            <>
                                <option aria-label="None" value=""/>
                                {list?.length ? list.map((option, r) => (
                                    <option key={r} value={value === option.name ? option.name : option.id}>{option.name}</option>
                                )) : ''}
                            </>
                            :
                            type === 'carrier' ?
                                <>
                                    <option aria-label="None" value=""/>
                                    {list?.length ? list.map((option, r) => (
                                        <option key={r}
                                                value={value === option.firstName ? option.firstName : option.id}>{option.firstName}</option>
                                    )) : ''}
                                </>
                                :
                                <>
                                    <option aria-label="None" value=""/>
                                    {list?.length ? list.map((option, r) => (
                                        <option data-key={option.code ? option.code : r} key={r}
                                                value={
                                                    type === 'id' ? option.id :
                                                        type === 'agent' ? option.id :
                                                            type === 'name' ? option.id :
                                                                type === 'mc' ? option.id :
                                                                   type === 'loadId' ? option.id :
                                                                     option.name
                                                }
                                        >
                                            {type === 'agent' ? `${option.firstName} ${option.lastName}` :
                                                type === 'name' ? option.name :
                                                  type === 'loadId' ? `${option.displayId }  ($${option?.customerRate - option?.customerPaid})` :
                                                    option.name}
                                        </option>
                                    )) : ''}
                                </>
                        }
                    </Select>
                    {!noHelper && <FormHelperText className={classes.selectErrorText}>{typeError && typeError}</FormHelperText>}
                </FormControl>
            </div>
        </>
    )
}
