import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {AdminInfoTable} from "fragments";
import {adminActions, httpRequestsOnSuccessActions, roleActions} from "store";
import {ActiveInactive, History, Loader, Notes, SimpleModal, SimpleTabs, SlicedText} from "components";
import {AntSwitch, FindLoad, FindSuccessItem, Images, PermissionsList, RenderStatus, RolePermission, useGlobalStyles} from "utils";

export const HumanResourcesInfoPage = () => {
    const {adminInfoById, assignedRoleList, rolesList} = useSelector((state) => ({
            adminInfoById: state.admins.adminInfoById,
            assignedRoleList: state.admins.assignedRoleList,
            rolesList: state.roles.rolesList,
        })
    )
    const dispatch = useDispatch()
    const params = useParams()
    const globalStyle = useGlobalStyles()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [switchBoolean, setSwitchBoolean] = useState(null)
    const [open, setOpen] = useState(false)
    const name = firstName && lastName ? `${firstName} ${lastName}` :
        firstName ? firstName : lastName ? lastName :
            `${adminInfoById?.firstName} ${adminInfoById?.lastName}`
    const activeInactive = switchBoolean === true ? 'Inactivate' : 'Activate'
    const loader = FindLoad('GET_ADMIN_BY_ID')
    const activeSuccess = FindSuccessItem('ACTIVATE_ADMIN')
    const inactiveSuccess = FindSuccessItem('INACTIVATE_ADMIN')
    const activeLoader = FindLoad('ACTIVATE_ADMIN')
    const inactiveLoader = FindLoad('INACTIVATE_ADMIN')

    useEffect(() => {
        if (adminInfoById) {
            setSwitchBoolean(adminInfoById.status === 'ACTIVE')
        }
    }, [adminInfoById])

    useEffect(() => {
        dispatch(roleActions.getRole());
        dispatch(adminActions.getAdminById(params.id))
        dispatch(adminActions.getAdminInfo(params.id));
    }, [])

    useEffect(() => {
        if (activeSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_ADMIN'))
            setSwitchBoolean(true)
            setOpen(false)
        }
        if (inactiveSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_ADMIN'))
            setSwitchBoolean(false)
            setOpen(false)
        }
    }, [activeSuccess, inactiveSuccess])

    const handleOpenClose = () => {
        setOpen(!open)
    }

    const handleSwitch = () => {
        if (switchBoolean) {
            dispatch(adminActions.inactivateAdmin(params.id))
        } else {
            dispatch(dispatch(adminActions.activateAdmin(params.id)))
        }
    }

    const tabsLabels = [
        {
            label: 'Details'
        },
        {
            label: 'History'
        },
        {
            label: 'Notes'
        },
    ]

    const tabsContent = [
        {
            tabComponent:  <AdminInfoTable handleChangeName={setFirstName} handleChangeLast={setLastName} />,
            tab:'Details',
        },
        {
            tabComponent: <History onModel={'admin'} resourceId={params.id}/>,
            tab:'History',
        },
        {
            tabComponent: <Notes/>,
            tab:'Notes',
        }
    ];

    if (loader.length) return <Loader/>

    return (
        <>
            <SimpleTabs
                head={
                    <div className={globalStyle.spaceBetween}>
                        <div style={{marginRight: '24px'}} className={globalStyle.centerItem}>
                            {RenderStatus(activeInactive)}
                            {RolePermission([PermissionsList.ADMIN_STATUS?.code]) &&
                                <AntSwitch
                                    onClick={handleOpenClose}
                                    checked={switchBoolean}
                                />
                            }
                        </div>
                        <div className={globalStyle.centerItem}>
                            <img style={{marginRight: '4px'}} src={Images.humanResourcesYellow}
                                 alt={"authorityBlueFill"}/>
                            <SlicedText type={'tableName'} size={20} data={name && name}/>
                        </div>

                    </div>
                }
                border={'blue'}
                height={'full'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                parentLink={'/humanResources'}
                parent={'Human Recources'}
                child={'Admin Info' }
                defaultTab={'Details'}
                paramsName={'humanResourceParams'}
            />
            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={open}
                content={
                    <ActiveInactive
                        loading={!!activeLoader?.length || !!inactiveLoader?.length}
                        text={switchBoolean === true ? `Inactivate ${name}` : `Activate ${name}`}
                        textInfo={activeInactive}
                        name={name}
                        handleClose={handleOpenClose}
                        handleDel={handleSwitch}
                    />
                }
            />
        </>

    )
}

