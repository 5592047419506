import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors, Shadow } from 'utils';

export const buttonsStyle = makeStyles((theme) => ({
  buttonsTab: {
    '& .MuiPaper-root': {
      background: 'none',
      boxShadow: 'none',
    },
    '& span': {
      background: 'none',
    },

    '& .MuiTabs-flexContainer': {
      borderRadius: '18px',
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiTabs-root': {
      minHeight: '36px !important',
      height: '36px  !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '& .MuiTabs-fixed': {
      minHeight: '36px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .PrivateTabIndicator-colorPrimary-30': {
      display: 'none',
    },
    '& .MuiTab-root': {
      // minWidth: '103px',
      // maxWidth: '103px',
      minHeight: '36px',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      // minWidth: "103px",
      minHeight: '32px',
      maxHeight: '32px',
      background: '#438AFE 0% 0% no-repeat padding-box',
      borderRadius: '18px',
      fontSize: '14px',
      lineHeight: '19px',
      color: Colors.TextWhite,
      textTransform: 'capitalize',
      marginLeft: '2px',

      '& :hover': {
        background: '#0000001A 0% 0% no-repeat padding-box',
        // minWidth: "103px",
        minHeight: '32px',
        maxHeight: '32px',
        borderRadius: '18px',
        fontSize: '14px',
        lineHeight: '19px',
        color: Colors.TextWhite,
        textTransform: 'capitalize',
      },
    },

    '& .MuiTab-textColorPrimary': {
      minWidth: '103px',
      minHeight: '32px',
      maxHeight: '32px',
      borderRadius: '18px',
      fontSize: '14px',
      lineHeight: '19px',
      color: Colors.TextSecondary,
      textTransform: 'capitalize',
      marginLeft: '2px',

      '& :hover': {
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        minWidth: '103px',
        minHeight: '32px',
        maxHeight: '32px',
        borderRadius: '18px',
        fontSize: '14px',
        lineHeight: '19px',
        color: Colors.TextSecondary,
        textTransform: 'capitalize',
      },
    },
  },

  addButtonStyle: {
    background: Backgrounds.green,
    boxShadow: '0px 0px 6px #00C8514D',
    borderRadius: '18px',
    width: 'auto',
    padding: '0 24px',
    height: '36px',
    fontSize: '14px',
    lineHeight: '19px',
    color: Colors.TextWhite,
    textTransform: 'capitalize',
    textDecoration: 'none!important',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: Backgrounds.hover,
    },
    '& p': {
      fontSize: '27px',
      marginRight: '8px',
      // marginBottom:'5px',
    },

  },
  addModalButtonStyle: {
    width: '100%',
    height: '48px',
    background: Backgrounds.green,
    boxShadow: '0px 0px 6px #00C8514D',
    borderRadius: '8px',
    padding: '0 15px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '600',
    color: Colors.TextWhite,
    textTransform: 'capitalize',
    '&:hover': {
      background: Backgrounds.hover,
    },
  },
  addModalBlueButtonStyle: {
    width: '100%',
    height: '48px',
    background: Colors.ThemeBlue,
    boxShadow: '0px 0px 6px #00C8514D',
    borderRadius: '8px',
    padding: '0 15px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '600',
    color: Colors.TextWhite,
    textTransform: 'capitalize',
    '&:hover': {
      background: Colors.ThemeDarkBlue,
    },
  },
  chancelModalButtonStyle: {
    width: '100%',
    height: '48px',
    background: '#E6ECF3 0% 0% no-repeat padding-box',
    borderRadius: '8px',
    padding: '0 15px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '600',
    color: Colors.TextPrimary,
    textTransform: 'capitalize',
    '&:hover': {
      background: '#E6ECF3 0% 0% no-repeat padding-box',
    },
  },

  deleteButtonStyle: {
    background: 'none',
    border: 'none',
    outline: 'none',
    '& :hover': {
      borderRadius: '40px',
    },
  },

  addCircleStyle: {
    width: '24px',
    height: '24px',
    background: Backgrounds.green,
    borderRadius: '40px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px',
  },

  closeCircleStyle: {
    width: '24px',
    height: '24px',
    background: Backgrounds.gray,
    borderRadius: '40px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px',
  },

  addCircleTextStyle: {
    fontSize: '14px',
    lineHeight: '19px',
    color: Colors.TextPrimary,
  },

  editButtonStyle: {
    width: '73px',
    height: '36px',
    fontSize: '14px',
    color: Colors.TextWhite,
    boxShadow: Shadow.blueButton,
    borderRadius: '18px',
    background: Backgrounds.theme,
    textTransform: 'capitalize',
  },

  buttonsWrapper: {
    display: 'flex',
  },

  cancelButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    color: Colors.TextPrimary,
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '8px',
    height: '36px',
    textTransform: 'capitalize',
  },

  saveButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    color: Colors.ThemeBlue,
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '8px',
    height: '36px',
    textTransform: 'capitalize',
  },

  loaderWrapper: {
    width: '64px',
    // marginRight: '24px'
  },

  editButton: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: 'none',
    outline: 'none',
    '& span': {
      color: '#438AFE80',
      fontSize: '14px',
      marginLeft: '8px',
    },
  },

  voidButton: {
    background: Colors.ThemeBlue,
    color: Colors.TextWhite,
    boxShadow: '0px 0px 6px #438AFE4D',
    borderRadius: '18px',
    width: '77px',
    height: '32px',
    fontSize: '14px',
    textTransform: 'capitalize',
    padding: '0',
    // cursor: 'no-drop',
    marginLeft: '8px',
    border: 'none',
    '& :hover': {
      background: Colors.ThemeBorderBlue,
      width: '77px',
      height: '32px',
      fontSize: '14px',
      borderRadius: '18px',
    },
  },

  voided: {
    background: Colors.ThemeGray,
    color: Colors.TextPrimary,
    borderRadius: '18px',
    width: '93px',
    height: '32px',
    fontSize: '14px',
    textTransform: 'capitalize',
    padding: '0',
    border: 'none',
    '& :hover': {
      background: Colors.ThemeBorder,
      width: '93px',
      height: '32px',
      fontSize: '14px',
      borderRadius: '18px',
    },
  },

  closeBill: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& button': {
      textTransform: 'capitalize',
      width: '109px',
      height: '36px',
      background: '#D72525 0% 0% no-repeat padding-box',
      borderRadius: '18px',
      color: Colors.TextWhite,
      fontSize: '14px',
      fontWeight: '600',
      border: 'none',
    },
    '& button:hover': {
      background: '#D72525 0% 0% no-repeat padding-box',
    },
  },

  removeButton: {
    border: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: '14px',
      fontWeight: 600,
      color: Colors.ThemeRed,
      marginLeft: '8px',
      marginBottom: '2px',
    },
  },

  downloadLinkStyle: {
    color: '#347AF0',
    display: 'inline-flex',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    '& img': {
      width: '100%',
      objectFit: 'contain',
      marginLeft: '8px',
    },
  },
  editButtonIcon: {
    background: 'transparent',
    border: 'none',
  },
  exportButton: {
    padding: '0 12px',
    width: '107px',
    height: '36px',
    background: '#438AFE',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginLeft: '16px',
    '& img': {
      marginRight: '8px',
    },
  },
  generateButton: {
    padding: '0 12px',
    width: '120px',
    height: '36px',
    background: '#00C851',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginLeft: '16px',
    '& img': {
      marginRight: '8px',
    },
  },
  csvExportBtn: {
    background: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#2A374E',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textDecorationLine: 'underline',
      '& img':{
        marginTop: '2px',
          minWidth:'24px',
      }
  },
}));
