import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {
    CloseButton,
    CreateChancel, ErrMessage,
    modalsStyle,
    SelectInput,
    SelectTypeAutocomplete,
    ValidationInput
} from "components";
import {ErrorText, FindErrorItem, FindLoad, FindSuccess, useGlobalStyles, useGlobalTextStyles} from "utils";
import {
    customerPaymentActions,
    httpRequestsOnErrorsActions,
    httpRequestsOnLoadActions,
    httpRequestsOnSuccessActions
} from "store";
import {receivablesFragments} from "./styles";
import {PENDING} from "../../../pages/accounting/constants";

const ACTION_TYPE = 'CREATE_CUSTOMER_PAYMENT_LOAD'
const GET_LOAD_ACTION_TYPE = 'GET_LOADS_BY_STATUS'
export const AddLoad = ({handleOpenClose, loadDate}) => {
    const {mcsList, customersList} = useSelector((state) => ({
        mcsList: state.mcs.mcsList,
        customersList: state.customers.customersList,
    }));
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)
    const backError = FindErrorItem(ACTION_TYPE)
    const classes = modalsStyle()
    const globalWrappers = useGlobalStyles();
    const globalText = useGlobalTextStyles();
    const dispatch = useDispatch()
    const params = useParams()
    const [inputs, setInputs] = useState({})
    const [error, setError] = useState({})
    const [mcLoads, setMcLoads] = useState([])
    const loadAmount = loadDate && loadDate?.amount - loadDate?.used

    useEffect(() => {
        if (success.length) {
            handleOpenClose()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }
    }, [success])

    useEffect(() => {
        return () => dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
    }, [])

    const handleCreate = () => {
        if (inputs?.customer && inputs.mc && inputs.loadId && inputs.amount) {
            const itemInfo = {
                "customer": inputs?.customer,
                "loadId": inputs.loadId,
                "amount": +inputs.amount,
                "mc": inputs.mc,
            }
            dispatch(customerPaymentActions.createCustomerPaymentLoad(itemInfo, params.id))
        } else {
            setError(
                !inputs.customer ? 'customer' :
                    !inputs.mc ? 'mc' :
                        !inputs.loadId ? 'loadId' :
                            !inputs.amount ? 'amount' : ''
            )
        }
    }

    const handleChange = e => {
        if (e.target.name === 'customer') {
            setInputs({customer: e.target.value})
            setMcLoads([])
        } else {
            if (e.target.name === 'mc') {
                const inputParams = {
                    ...inputs
                }
                delete inputParams.loadId
                setInputs(inputParams)
                setMcLoads([])
                dispatch(httpRequestsOnLoadActions.appendLoading(GET_LOAD_ACTION_TYPE))
                axios.get(`/loads`, {
                    auth: true,
                    params: {mc: e.target.value, customer: inputs?.customer}
                }).then((res) => {
                    setMcLoads(res.data)
                }).finally(() => {
                    dispatch(httpRequestsOnLoadActions.removeLoading(GET_LOAD_ACTION_TYPE))
                })
            }
            if (e.target.name === 'amount') {
                if (+e.target.value <= +loadAmount) {
                    setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}),)
                }
            } else {
                setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}),)
            }
        }
        error === e.target.name && setError('')
        if (backError?.error === "This load was already paid by this payment" && e.target.name === 'loadId') {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
        }
    }


    return (
        <div className={classes.addLoadCustomerPmt}>
            <div className={classes.closeButtonWithPaddings}>
                <CloseButton handleCLic={handleOpenClose}/>
            </div>
            <div className={classes.deleteModalWrapper}>
                <>
                    <p className={globalText.modalTitle}>{'Add Load'}</p>
                    <p className={globalText.modalText}>{'To add Load please, fill out the below fields.'}</p>
                    <div style={{marginBottom: '24px'}}>
                        <SelectTypeAutocomplete
                            loadType={'GET_CUSTOMERS'}
                            style={globalWrappers.simpleInput}
                            name={'customer'}
                            label={'name'}
                            title={'Customer*'}
                            handleSelect={handleChange}
                            defaultValue={inputs.customer}
                            list={customersList?.customers ? customersList?.customers : []}
                            error={error}
                        />
                    </div>

                    <div style={{marginBottom: '24px'}}>
                        <SelectTypeAutocomplete
                            loadType={'GET_MCS'}
                            style={globalWrappers.simpleInput}
                            name={'mc'}
                            label={'name'}
                            title={'Select an MC*'}
                            handleSelect={handleChange}
                            defaultValue={inputs.mc}
                            list={mcsList?.mcs ? mcsList?.mcs : []}
                            disabled={!inputs?.customer}
                            error={error}
                        />
                    </div>
                    <div style={{marginBottom: '24px'}}>
                        <SelectTypeAutocomplete
                            loadType={GET_LOAD_ACTION_TYPE}
                            style={globalWrappers.simpleInput}
                            name={'loadId'}
                            label={'displayId'}
                            title={'Select a Load*'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.loadId}
                            list={mcLoads?.count ? mcLoads?.loads?.filter((i) => i?.mc?.id === inputs.mc) : []}
                            disabled={!inputs?.customer}
                            // error={error}
                            typeError={
                                backError?.error === "This load was already paid by this payment" ? "This load was already paid by this payment" : ''
                            }
                            error={
                                backError?.error === "This load was already paid by this payment" ? "loadId" :
                                    error
                            }
                        />
                    </div>
                    <ValidationInput
                        className={!inputs?.loadId ? classes.amountInput : ''}
                        variant={"outlined"}
                        name={"amount"}
                        label={"Amount*"}
                        type={'number'}
                        typeError={error === 'amount' ? ErrorText.field : ''}
                        onChange={handleChange}
                        value={inputs.amount}
                        disabled={!inputs?.loadId}
                    />
                    <span className={classes.available}>Available Amount: {loadAmount}$</span>
                </>

                {backError?.error === 'Something went wrong, could not add load to the customer payment' &&
                    <ErrMessage text={
                        backError?.error === 'Something went wrong, could not add load to the customer payment' ?
                            'Something went wrong, could not add load to the customer payment' : ''
                    }/>
                }
                <CreateChancel
                    loader={!!loader?.length}
                    classes={globalWrappers.buttonsStyleFullWidth}
                    create={"Add Load"}
                    chancel={"Cancel"}
                    onCreate={handleCreate}
                    onClose={handleOpenClose}
                />
            </div>
        </div>
    )
}