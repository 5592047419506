import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AntSwitch,
  Colors,
  FindLoad,
  Images,
  PermissionsList,
  RolePermission,
  useGlobalText,
  userType,
} from 'utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { Circle, DocumentsComponent, Line, MinLoader } from 'components';
import { EditCarrierInputs } from '../../createCarrier';
import { Access } from './access';
import { carrierInfoFragments, PaymentSetup } from './index';
import { Blacklist } from './blacklist';
import { carrierActions, rmisActions } from 'store';
import { carrierDockTypes, carrierUploadDockTypes } from './constants';
import { CARRIER_DOCK_STATUSES } from '../../../../pages/carriers';

export const CarrierInfoTable = ({ info, handleChangeName }) => {
  const { carrierById } = useSelector((state) => ({
      carrierById: state.carriers.carrierById,
    }),
  );
  const globalStyle = useGlobalText();
  const classes = carrierInfoFragments();
  const dispatch = useDispatch();
  const loader = FindLoad('REFRESH_RMIS');
  const [docs, setDocks] = useState([]);
  const [switchBoolean, setSwitchBoolean] = useState(false);
  const [doNot, setDoNot] = useState('INACTIVE');
  const [feeExempt, setFeeExempt] = useState(false);
  const [ownRisk, setOwnRisk] = useState(false);

  useEffect(() => {
    if (info) {
      setSwitchBoolean(info?.rmisRequired);
      setDoNot(carrierById?.doNotLoad === true ? 'ACTIVE' : 'INACTIVE');
      setFeeExempt(carrierById?.feeExempt);
      setOwnRisk(carrierById?.riskCarrier);
    }
  }, []);

  useEffect(() => {
    if (info && info.documents) {
      setDocks(info.documents);
    }
  }, [info]);

  const handleAddDocument = (img) => {
    dispatch(carrierActions.addCarrierDocument(img, info.id));
  };

  const handleDeleteFile = (doc) => {
    dispatch(carrierActions.deleteCarrierDocument(info?.id, doc?.id));
  };

  const handleApprove = (doc) => {
    const params = {
      ...doc,
    };
    params.status =
      doc?.status === CARRIER_DOCK_STATUSES.PENDING ? CARRIER_DOCK_STATUSES.ACTIVE :
        doc?.status === CARRIER_DOCK_STATUSES.ACTIVE ? CARRIER_DOCK_STATUSES.INACTIVE :
          CARRIER_DOCK_STATUSES.ACTIVE;

    dispatch(carrierActions.editCarrierDocument(info?.id, params));
  };

  const handleFilter = (e) => {
    if (e.target.value === 'All DocumentsSection') {
      setDocks(info.documents);
    } else {
      const newList =
        info && info.documents.filter((i) => i.type === e.target.value);
      setDocks(newList);
    }
  };

  const handleSync = () => {
    dispatch(rmisActions.refreshRMIS(info?.id));
  };

  const handleSetRequirements = () => {
    setSwitchBoolean(!switchBoolean);
    const body = {
      id: info?.id,
      required: !switchBoolean,
    };
    dispatch(rmisActions.requiredRMIS(body));
  };

  const handleSetDoNot = () => {
    setDoNot(doNot === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE');
    dispatch(carrierActions.doNotLoad(info.id, doNot !== 'ACTIVE'));
  };

  const handleSetFeeExempt = () => {
    setFeeExempt(!feeExempt);
    dispatch(carrierActions.feeExempt(info.id, !feeExempt));
  };

  const handleOwnRisk = () => {
    setOwnRisk(!ownRisk);
    const params = {
      ...info,
    };
    params.riskCarrier = info.riskCarrier !== true;
    dispatch(carrierActions.editCarrier(info.id, params));
  };

  return (
    <div className="accordion-style">
      <div className="action-settings-wrapper">
        <div className="settings-icon-title">
          <img src={Images.settings} alt="icon" />
          <p>Settings</p>
        </div>
        <div className="setting-flex">
          <div className="setting-flex right">
            <span className="setting-title">{'Load At Your Own Risk'}</span>
            {RolePermission([PermissionsList.CARRIER_STATUS?.code]) &&
              <AntSwitch
                onClick={handleOwnRisk}
                checked={ownRisk}
              />
            }
          </div>
          <div className="setting-flex right">
            <span className="setting-title">{'Fee Exempt'}</span>
            {RolePermission([PermissionsList.CARRIER_STATUS?.code]) &&
              <AntSwitch
                onClick={handleSetFeeExempt}
                checked={feeExempt}
              />
            }
          </div>
          <div className="setting-flex">
            <span className="setting-title">{'Do Not Load'}</span>
            {RolePermission([PermissionsList.CARRIER_STATUS?.code]) &&
              <AntSwitch
                onClick={handleSetDoNot}
                checked={doNot === 'ACTIVE'}
              />
            }
          </div>
        </div>
      </div>



      {userType === 'ADMIN' && !info?.isFactored  && (
        <div className={classes.rmisActionsWrapper}>
          <div className={classes.rmisIconWrapper}>
            <img
              src={
                info?.rmisStatus === 'INACTIVE'
                  ? Images.rmisInactive
                  : Images.rmisActive
              }
              alt={'icon'}
            />
            <p
              style={{
                color: info?.rmisStatus === 'INACTIVE' ? '#9C9EA9' : '#2A374E',
              }}
            >
              RMIS
            </p>
          </div>
          <div className={classes.rmisSettings}>
            <div className={classes.requireRmis}>
              <p>Require RMIS</p>
              <AntSwitch
                onClick={handleSetRequirements}
                checked={switchBoolean}
              />
            </div>
            <button onClick={handleSync} className={classes.rmisSync}>
              {loader?.length ? (
                <MinLoader
                  position={'relative'}
                  margin={'0'}
                  color={Colors.ThemeBlue}
                />
              ) : (
                'RMIS Sync'
              )}
            </button>
          </div>
        </div>
      )}
      <Accordion>
        <AccordionSummary
          className={'accordion'}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="align-center">
            {info && <Circle number={1} back={Colors.TextPrimary} />}
            <p className={globalStyle.title}>Carrier Information</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <EditCarrierInputs handleChangeName={handleChangeName} info={info} />
        </AccordionDetails>
      </Accordion>

      {RolePermission([
        PermissionsList.ALL_ACCESS?.code,
        PermissionsList.CARRIER_PAYMENT_SETUP?.code,
      ]) && (
        <Accordion>
          <AccordionSummary
            className={'accordion'}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="align-center">
              {info && <Circle number={2} back={Colors.TextPrimary} />}
              <p className={globalStyle.title}>Payment Setup</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <PaymentSetup info={info} />
          </AccordionDetails>
        </Accordion>
      )}


      {RolePermission([
        PermissionsList.ALL_ACCESS?.code,
        PermissionsList.CREATE_UPDATE_CARRIER?.code,
      ]) && (
        <>
          {/*<Accordion>*/}
          {/*  <AccordionSummary*/}
          {/*    className={"accordion"}*/}
          {/*    expandIcon={<ExpandMoreIcon />}*/}
          {/*    aria-controls="panel1a-content"*/}
          {/*    id="panel1a-header"*/}
          {/*  >*/}
          {/*    <div className="align-center">*/}
          {/*      {info && <Circle number={3} back={Colors.TextPrimary} />}*/}
          {/*      <p className={globalStyle.title}>Equipment</p>*/}
          {/*    </div>*/}
          {/*  </AccordionSummary>*/}
          {/*  <AccordionDetails>*/}
          {/*    <Equipment />*/}
          {/*  </AccordionDetails>*/}
          {/*</Accordion>*/}

          <Accordion>
            <AccordionSummary
              className={'accordion'}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="align-center">
                {info && <Circle number={3} back={Colors.TextPrimary} />}
                <p className={globalStyle.title}>Carrier Assignment</p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Access />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              className={'accordion'}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="align-center">
                {info && <Circle number={4} back={Colors.TextPrimary} />}
                <p className={globalStyle.title}>Authority Access</p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Blacklist />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              className={'accordion'}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="align-center">
                {info && <Circle number={5} back={Colors.TextPrimary} />}
                <p className={globalStyle.title}>Documents</p>
              </div>
            </AccordionSummary>
            <AccordionDetails>

              <div className="flex-align-start">
                <Line height={'330px'} />
                <div style={{ marginTop: '24px', width: '100%' }}>
                  <DocumentsComponent
                    fileTypeList={carrierUploadDockTypes}
                    docTypes={carrierDockTypes}
                    actionType={'ADD_CARRIER_DOCUMENT'}
                    removeActionType={'DELETE_CARRIER_DOCUMENT'}
                    editActionType={'EDIT_CARRIER_DOCUMENT'}
                    handleDeleteFile={handleDeleteFile}
                    handleUpload={handleAddDocument}
                    handleFilter={handleFilter}
                    handleSwitch={handleApprove}
                    documents={docs}
                    name={'typeName'}
                    permissionForDelete={PermissionsList.CREATE_UPDATE_CARRIER?.code}
                    switchButton={true}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};
