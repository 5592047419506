import React, { useState } from "react";
import { inputsStyle } from "./styles";
import TextField from "@material-ui/core/TextField";
import { ErrMessage } from "../messages";
import { InputMinLoader } from "./inputMiniLoader";

export const ValidationInput = ({
  multiline,
  style,
  className,
  autoComplete,
  typeError,
  Length,
  disabled,
  value,
  type,
  onChange,
  name,
  label,
  validator,
  sendBoolean,
  variant,
  loader,
  handleSendAddress,
  handleDown,
  noErrMessage

}) => {
  const classes = inputsStyle();
  const [validEmail, setValidEmail] = useState(false);

  const chechValid = (e) => {
    let Value = e.target.value;
    if (Value.length >= 1) {
      if (validator) {
        if (validator.test(Value)) {
          setValidEmail(false);
          sendBoolean(false);
        } else {
          setValidEmail(true);
          sendBoolean(true);
        }
      }
    }else{
      setValidEmail(false);
      sendBoolean && sendBoolean(false);
    }
  };

  return (
    <>
      <div className={style ? style : classes.SignInInput}>
        <TextField
          onWheel={() => document.activeElement.blur()}
          className={className ? className : classes.inputTextField}
          variant={variant}
          label={label}
          name={name}
          type={type}
          value={value ? value : ""}
          InputLabelProps={{
            shrink: type === "date" ? true : type === "zero" ? true : !!value,
          }}
          inputProps={{ maxLength: Length }}
          id={name}
          autoComplete={autoComplete}
          error={!!typeError}
          disabled={disabled === true}
          maxLength={Length}
          onChange={(ev) => onChange(ev)}
          onFocus={() => setValidEmail(false)}
          onKeyDown={handleDown}
          onBlur={
            handleSendAddress
              ? (e) => handleSendAddress(e.target.value)
              : (e) => chechValid(e)
          }
          fullWidth
          multiline={multiline}
          InputProps={{
            endAdornment: loader && <InputMinLoader />,
          }}
        />
        {!noErrMessage &&
            <div style={{position: "relative"}}>
              <ErrMessage text={typeError}/>
            </div>
        }
      </div>
    </>
  );
};
