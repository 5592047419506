import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FindLoad, FindSuccess, Images, PermissionsList, RolePermission, useModal, userType} from "utils";
import {AddButton, DateRow, Loader, NoYet, TextRow} from "components";
import {AddAlert} from "./addAlert";
import {carrierActions, httpRequestsOnSuccessActions} from "store";
import {CustomDeleteModal} from "../../modals";

const ACTION_TYPE = 'DELETE_ALERT'

export const Alerts = ({carrierId}) => {
    const {alertsList} = useSelector((state) => ({
        alertsList: state.carriers.alertsList,
    }));
    const dispatch = useDispatch()
    const {open, close} = useModal()
    const loader = FindLoad('GET_ALERTS')
    const success = FindSuccess(ACTION_TYPE)

    useEffect(() => {
        if (carrierId) {
            dispatch(carrierActions.getAlerts(carrierId))
        }
    }, [dispatch, carrierId])

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
            close()
        }
    }, [success])

    if (loader?.length) return <Loader height='auto'/>

    return (
        <div className='full-width'>
            {userType === "ADMIN" &&
                RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                <div className='add-alert-btn'>
                    <AddButton
                        handleClick={() => open(<AddAlert carrierId={carrierId}/>)}
                        text={'Add Alert'}
                    />
                </div>
            }
            <div className='alerts-head-wrapper'>
                <div className='alerts-head-item' style={{width: '15%'}}>
                    Date
                </div>
                <div className='alerts-head-item' style={{width: '15%'}}>
                    Type
                </div>
                <div className='alerts-head-item' style={{width: '20%'}}>
                    Creator Name
                </div>
                <div className='alerts-head-item' style={{width: '40%'}}>
                    Description
                </div>
                {userType === "ADMIN" &&
                    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                    <div className='alerts-head-item' style={{width: '10%'}}>
                        Action
                    </div>
                }
            </div>
            <div className='alerts-body'>
                {alertsList?.length ?
                    alertsList?.map((i, j) => (
                        <div key={j} className='alerts-body-wrapper'>
                            <div className='alerts-body-item' style={{width: '15%'}}>
                                <DateRow date={i?.date}/>
                            </div>
                            <div className='alerts-body-item' style={{width: '15%'}}>
                                <TextRow name={i?.type ? i.type : 'Not Set'} textWidth={9} />
                            </div>
                            <div className='alerts-body-item' style={{width: '20%'}}>
                                <TextRow name={i?.creatorName}/>
                            </div>
                            <div className='alerts-body-item' style={{width: '40%'}}>
                                <TextRow name={i?.description}/>
                            </div>
                            {userType === "ADMIN" &&
                                RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                                <div className='alerts-body-item' style={{width: '10%'}}>
                                    <button onClick={() => open(<AddAlert carrierId={carrierId} item={i}/>)}>
                                        <img src={Images.edit} alt='icon'/>
                                    </button>
                                    <button onClick={() => open(
                                        <CustomDeleteModal
                                            actionType={'DELETE_ALERT'}
                                            text="Delete Alert?"
                                            info={'Alert'}
                                            handleDel={() => dispatch(carrierActions.deleteAlert(carrierId, i?.id, {}))}
                                        />
                                    )}
                                    >
                                        <img src={Images.removeRed} alt='icon'/>
                                    </button>
                                </div>
                            }
                        </div>
                    ))
                    :
                    <NoYet position={'screenCenter'} text={'No Alerts Yet'}/>
                }
            </div>
        </div>
    )
}