// import { Icon } from 'components';
// import { SVGNames } from '@eachbase/constants';
// import { notificationsActions } from 'store';

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNoteContStyles} from './styles';
import {CircularProgress} from '@material-ui/core';
import {Text} from './text';
import axios from 'axios';
import {notificationsActions} from "store";
import {Images} from "../../../utils";
import {useHistory} from "react-router-dom";

const NotificationItem = ({notifications, closeOpen, params, removeNotification, setNotifications}) => {
    const classes = useNoteContStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [marked, setmarked] = useState(true);

    const date1 = new Date(notifications.createdAt);
    const date2 = new Date();
    let month = date2.getMonth() + 1;
    let year = date2.getFullYear();
    let daysInMonth = new Date(year, month, 0).getDate();
    let diffTime = Math.abs(date2 - date1);
    let total_seconds = parseInt(Math.floor(diffTime / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    let days = parseInt(Math.floor(total_hours / 24));
    let seconds = parseInt(total_seconds % 60);
    let minutes = parseInt(total_minutes % 60);
    let hours = parseInt(total_hours % 24);
    let week = parseInt(Math.floor(days / 7));
    let filteredMonth = parseInt(Math.floor(days / daysInMonth));
    let filteredYear = parseInt(Math.floor(filteredMonth / 12));

    const handleDel = () => {
        dispatch(notificationsActions.removeNotification(notifications.id, params));
    };

    const time =
        filteredYear > 0
            ? `${filteredYear} years`
            : filteredMonth
            ? `${filteredMonth} months`
            : week > 0
                ? `${week} weeks`
                : days > 0
                    ? `${days} days`
                    : hours > 0
                        ? `${hours} hours`
                        : minutes > 0
                            ? `${minutes} minutes`
                            : `${seconds} seconds`;

    const handleSeeEvent = (id) => {
        closeOpen();
        // router.push(`singleEvent?eventid=${id}`);
    };
    const handleReview = () => {
        closeOpen();
        history.push(`/load/${notifications.loadId}`)
    }

    const handleSetMark = (id) =>{
        setmarked(false);
        axios.patch(`/notifications/${notifications.id}`, {}, {auth: true}).then(r =>{
            let config = {
                headers: {'access-token': localStorage.getItem('access-token')},
                params: params,
            };
            axios.get(`/notifications`, config).then((res) => {
                setNotifications(res.data)
            })
        })
     }
    return (
        <div className={classes.NotificationItem}>
            <div className={classes.noteWrapper}>
                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                    <div className={classes.iconCont}>
                        <img
                            className={classes.notImg}
                            src={
                                notifications.type === "CREDIT_APPROVED" ? Images.approvedNote :
                                    notifications.type === "CREDIT_REJECTED" ? Images.rejectedNote :
                                        notifications.type === 'EVENT_REJECTED' ? Images.userProfile : ''
                            }
                            alt={'icon'}
                        />
                    </div>
                    <div className={classes.messageCont}>
                        <p className={classes.message}>
                            <Text
                                type={notifications.type}
                                info={notifications}
                                classes={classes}
                                handleClose={closeOpen}
                                handleSeeComment={handleSeeEvent}
                            />
                        </p>

                        {notifications?.type !== "CREDIT_REJECTED" &&
                            <button
                                onClick={handleReview}
                                className={classes.review}>Review
                            </button>
                        }
                    </div>
                </div>

                <div className={classes.delIconCont}>

                    <div style={{display:'flex', alignItems:'center'}}>
                    {notifications.status === 'UNREAD' &&
                    <div
                        onClick={() => {
                            handleSetMark()
                            // dispatch(notificationsActions.markReadNotification({notificationId: notifications.id}));
                        }}
                        className={classes.unreadCircle}/>
                    }
                        <div>
                    { removeNotification === notifications.id ?
                            <CircularProgress
                                style={{
                                    width: '15px',
                                    height: '15px',
                                    margin: '0 auto',
                                    color: '#387DFF',
                                }}
                            />
                             :
                            <button onClick={handleDel}>
                                <img src={Images.clear} alt='icon'/>
                            </button>

                        }
                        </div>
                    </div>
                    <p className={classes.date}>{`${time} ago`}</p>
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;
