import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { carrierActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, mcActions } from 'store';
import {
  AntSwitch,
  createInputsWrapper,
  EmailValidator,
  ErrorText,
  FindErrorItem,
  FindLoad,
  FindSuccessItem,
  useGlobalStyles,
  useGlobalText,
} from 'utils';
import { CreateChancel, ValidationInput, SelectInput, SelectTypeAutocomplete } from 'components';
import { docker } from '../../carrierInfo/core';
import { createOfficeStyle } from './styles';

const CREATE_TYPE = 'CREATE_CARRIER';

export const CreateCarriersInput = ({ handleChangeName }) => {
  const { mcsList } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
  }));
  const globalText = useGlobalText();
  const globalStyles = useGlobalStyles();
  const globalScreens = createInputsWrapper();
  const classes = createOfficeStyle()
  const dispatch = useDispatch();
  const history = useHistory();
  const [disables, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState({});
  const [email, setEmail] = useState('');
  const [factored, setFactored] = useState(false);
  const actionType = CREATE_TYPE;
  const loader = FindLoad(CREATE_TYPE);
  const success = FindSuccessItem(CREATE_TYPE);
  const backError = FindErrorItem(actionType);
  const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;

  const removeError = () => {
    errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
  };

  useEffect(() => {
    dispatch(mcActions.getMcs())
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_TYPE));
      setDisabled(true);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      removeError();
    };
  }, []);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError(''),
    e.target.name === 'companyName' && handleChangeName(e.target.value),
      removeError();
  };

  const handleCreate = () => {
    const data = {
      'firstName': inputs.firstName,
      'lastName': inputs.lastName,
      'email': inputs.email,
      'companyName': inputs.companyName,
      'docketType': inputs.docketType,
      'docketNumber': inputs.docketNumber,
      "isFactored": factored,
    };

    if(factored && inputs.loadMC){
      data.loadMC = inputs.loadMC
    }else{
      delete data.loadMC
    }

    const checkFactored = factored ? !!inputs.loadMC : true
    if (inputs.firstName && inputs.lastName && inputs.email && !email && inputs.companyName && inputs.docketType && inputs.docketNumber && checkFactored) {
      dispatch(carrierActions.createCarrier(data));
    } else {
      setError(
        !inputs.firstName ? 'firstName' :
          !inputs.lastName ? 'lastName' :
            !inputs.email ? 'email' :
              !inputs.companyName ? 'companyName' :
                !inputs.docketType ? 'docketType' :
                  !inputs.docketNumber ? 'docketNumber' :
                    !checkFactored ? 'loadMC' :
                    '',
      );
    }
  };

  return (
    <div className={globalScreens.createInputsWrapper}>
      <div className={globalStyles.flexAble}>
        <div className={globalStyles.fullWidth}>
          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Basic Information</p>
            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'firstName'}
                type={'text'}
                label={'First Name*'}
                typeError={error === 'firstName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.firstName}
                disabled={disables}
              />
              <ValidationInput
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'lastName'}
                type={'text'}
                label={'Last Name*'}
                typeError={error === 'lastName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.lastName}
                disabled={disables}
              />
              <ValidationInput
                validator={EmailValidator}
                variant={'outlined'}
                name={'email'}
                type={'email'}
                label={'Email Address*'}
                typeError={
                  error === 'email' ? ErrorText.field :
                    email === 'Not valid email' ? 'Not valid email' :
                      errorText === 'Carrier already exist' ? 'Carrier already exist' :
                        errorText === 'Carrier already exists' ? 'Carrier already exists' :
                          errorText === 'User already exists.' ? 'User already exists' :
                            errorText === 'User already exists' ? 'User already exists' :
                              ''}
                sendBoolean={(bool) => bool === true ? setEmail('Not valid email') : setEmail('')}
                onChange={handleChange}
                value={inputs.email}
                disabled={disables}
              />
            </div>

            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'companyName'}
                type={'text'}
                label={'Company Name*'}
                typeError={error === 'companyName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.companyName}
                disabled={disables}
              />
              <SelectInput
                style={globalStyles.simpleInput}
                name={'docketType'}
                label={'Type*'}
                handleSelect={handleChange}
                value={inputs.docketType}
                list={docker}
                typeError={error === 'docketType' ? ErrorText.field : ''}
                type={'id'}
                disabled={disables}
              />
              <ValidationInput
                variant={'outlined'}
                name={'docketNumber'}
                label={'Authority Number*'}
                type={'text'}
                typeError={
                  error === 'docketNumber' ? ErrorText.field :
                    errorText === 'A Carrier with this Authority Number already exists.' ? 'A Carrier with this Authority Number already exists.' :
                      ''
                }
                onChange={handleChange}
                value={inputs.docketNumber}
                disabled={disables}
                Length={20}
              />
            </div>

            {factored &&
              <div className={globalScreens.basicInfoInputs} style={{width: '34%'}}>
                <SelectTypeAutocomplete
                  style={globalStyles.simpleInput}
                  name={'loadMC'}
                  label={'name'}
                  type={'mcNumber'}
                  title={'Load MC Number*'}
                  handleSelect={handleChange}
                  defaultValue={inputs?.loadMC}
                  // typeError={error === 'loadMC' ? }
                  list={mcsList?.mcs?.filter((i) => i?.authorityType === "CARRIER")}
                  disabled={disables}
                  error={error}
                  right={'0'}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <div className="align-center" style={{gap: 14, margin: '10px 0'}}>
        <AntSwitch
          name={'factored'}
          onClick={() => setFactored(!factored)}
          checked={factored}
        />
        <span className={classes.factoredText}>{'Factored Carrier'}</span>
      </div>

      <p>{error === 'Input is not field' ? error : ''}</p>

      <CreateChancel
        loader={!!loader.length}
        classes={globalStyles.buttonsStyle}
        create={'Create'}
        chancel={'Cancel'}
        onCreate={handleCreate}
        onClose={() => history.push('/carriers')}
      />
    </div>
  );
};
