import React from 'react';
import { DateRow, PriceRow, TextRow } from 'components';
import { renderColors } from '../../loads/transactions/core/constants';

const blueColor = 'positive-type';
const redColor = 'negative-type';

const sameTransactions = [
  { name: 'Processing Fee Reversal', id: 'LPF_CARRIER_CREDIT' },
  { name: 'Extra to rate', id: 'EXTRA_TO_RATE' },
  { name: 'Duties and fees', id: 'DUTIES_AND_FEES' },
  { name: 'Entry service fee', id: 'ENTRY_SERVICE_FEE' },
  { name: 'Single bond fee', id: 'SINGLE_BOND_FEE' },
  { name: 'FDA fee', id: 'FDA_FEE' },
  { name: 'ISF fee', id: 'ISF_FEE' },
];


export const factoredTransactionHead = [
  { name: '', title: 'Created Date', icon: 'date', custom: false, width: '100px' },
  {
    name: 'type', title: 'Type',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Rate Adjustment ', id: 'RATEADJUSTMENT' },
      { name: 'Warehousing', id: 'WAREHOUSING' },
      { name: 'Late fee', id: 'LATEFEE' },
      { name: 'Lumper', id: 'LUMPER' },
      { name: 'Pickup fee', id: 'PICK_UP_FEE' },
      { name: 'Deliver fee', id: 'DELIVER_FEE' },
      { name: 'Detention', id: 'DETENTION' },
      { name: 'Initial Rate', id: 'INITIALRATE' },
      { name: 'Short Pay', id: 'INITIALRATE' },
      { name: 'Customer Payment', id: 'CUSTOMERPAYMENT' },
      { name: 'Palletizing', id: 'PALLETIZING' },
      { name: 'Drayage', id: 'DRAYAGE' },
      { name: 'Chassis Fee', id: 'CHASSIS_FEE' },
      { name: 'Drop Fee', id: 'DROP_FEE' },
      { name: 'Attempt', id: 'ATTEMPT' },
      { name: 'FSC', id: 'FSC' },
      { name: 'Pre-Pull', id: 'PRE_PULL' },
      { name: 'Per Diem', id: 'PRE_DIEM' },
      { name: 'Pier Pass (CTF/TMF) fee', id: 'PIER_PASS' },
      { name: 'Container Storage', id: 'CONTAINER_STORAGE' },
      { name: 'Demurrage', id: 'DEMURRAGE' },
      { name: 'Chassis Split', id: 'CHASSIS_SPLIT' },
      ...sameTransactions,
    ],
  },
  { name: '', title: 'Customer Rate', custom: false },
  { name: 'qty', title: 'Qty', custom: false, width: '100px' },
  { name: '', title: 'Total', custom: false },
  { name: '', title: 'Description', custom: false },
];

export const factoredTransactionBody = [
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <div>{handleGetTypes(item)}</div> },
  {
    rowText: (item) =>
      <p className={renderColors(item)}>
        <PriceRow info={item?.amount} />
      </p>,
  },
  { rowText: (item) => <TextRow name={item?.qty ? item?.qty : 1} textWidth={10} /> },
  {
    rowText: (item) => <PriceRow info={item?.totalAmount ? item?.totalAmount : 0} />
  },
  { rowText: (item) => <TextRow name={item?.description} textWidth={13} /> },
];


export const ACTION_TYPE = 'GET_LOAD_TRANSACTIONS';


export const handleGetTypes = (data) => {
  return data?.type === 'CUSTOMERPAYMENT' ? 'Customer Payment' :
    data?.type === 'CARRIERPAYMENT' ? 'Carrier Payment' :
      data?.type === 'RATEADJUSTMENT' ? 'Rate Adjustment' :
        data?.type === 'DELIVER_FEE' ? 'Deliver Fee' :
          data?.type === 'PICK_UP_FEE' ? 'Pickup Fee' :
            data?.type === 'DRIVERASSIST' ? 'Driver Assist' :
              data?.type === 'WAREHOUSING' ? 'Warehousing' :
                data?.type === 'INITIALRATE' ? 'Initial Rate' :
                  data?.type === 'LATEFEE' ? 'Late fee' :
                    data?.type === 'SHORTPAY' ? 'Short Pay' :
                      data?.type === 'COLLECTIONAPPLIED' ? 'Collection Applied' :
                        data?.type === 'QUICKPAY' ? 'Quick Pay' :
                          data?.type === 'QUICKPAY_REVERSAL' ? 'Quickpay Reversal' :
                            data?.type === 'FUEL_FEE' ? 'Fuel advance fee' :
                              data?.type === 'CHASSIS_FEE' ? 'Chassis Fee' :
                                data?.type === 'DROP_FEE' ? 'Drop Fee' :
                                  data?.type === 'PRE_PULL' ? 'Pre-Pull' :
                                    data?.type === 'PIER_PASS' ? 'Pier Pass (CTF/TMF) fee' :
                                      data?.type === 'CONTAINER_STORAGE' ? 'Container Storage' :
                                        data?.type === 'CHASSIS_SPLIT' ? 'Chassis Split' :
                                          data?.type === 'OFFICE_CHARGE' ? 'Office Transaction' :
                                            data?.type === 'CORPORATE' ? 'Corporate Transaction' :
                                              data?.type === 'LPF_CARRIER_CHARGE' ? 'LPF Charge' :
                                                data?.type === 'LPF_REVERSAL' ? 'LPF reversal' :
                                                  data?.type === 'LPF_CARRIER_CREDIT' ? 'LPF Credit' :
                                                    data?.type === 'LPF_AGENT_CHARGE' ? 'LPF Agent Charge' :
                                                      data?.type === 'LPF_AGENT_CREDIT' ? 'LPF Agent Credit' :
                                                        data?.type === 'QUICKPAY' ? 'Quickpay' :
                                                          data?.type === 'EXTRA_TO_RATE' ? 'Extra to rate' :
                                                            data?.type === 'DUTIES_AND_FEES' ? 'Duties and fees' :
                                                              data?.type === 'ENTRY_SERVICE_FEE' ? 'Entry service fee' :
                                                                data?.type === 'SINGLE_BOND_FEE' ? 'Single bond fee' :
                                                                  data?.type === 'FDA_FEE' ? 'FDA fee' :
                                                                    data?.type === 'ISF_FEE' ? 'ISF fee' :
                                                                      data?.type?.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
                                                                        return g1.toUpperCase() + g2.toLowerCase();
                                                                      });
};


export const CORPORATE = 'CORPORATE';
export const EFS = 'EFS';
export const OFFICE_CHARGE = 'OFFICE_CHARGE';
export const FUEL = 'FUEL';
export const QUICKPAY = 'QUICKPAY';
