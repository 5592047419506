import {textResizer} from "utils";
import {DateRow, PriceRow, TextRow} from "components";
import React from "react";
import {handleGetTypes} from "../loads/transactions/core/constants";


export const carrierTransactionHead = [
    {name: '', title: 'Created Date', icon: 'date', custom: false},
    {name: '', title: 'Creator', custom: false},
    {name: 'qty', title: 'Qty', custom: false},
    {
        name: 'type', title: 'Type',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Rate Adjustment ', id: 'RATEADJUSTMENT'},
            {name: 'Warehousing', id: 'WAREHOUSING'},
            {name: 'Late fee', id: 'LATEFEE'},
            {name: 'Lumper', id: 'LUMPER'},
            {name: 'Detention', id: 'DETENTION'},
            {name: 'Initial Rate', id: 'INITIALRATE'},
            {name: 'Efs', id: 'EFS'},
            {name: 'Short Pay', id: 'INITIALRATE'},
            {name: 'Fuel', id: 'FUEL'},
            {name: 'Collection Applied', id: 'COLLECTIONAPPLIED'},
            {name: 'Quick Pay', id: 'QUICKPAY'},
            {name: 'Carrier Payment', id: 'CARRIERPAYMENT'},
            {name: 'Quickpay Reversal', id: 'QUICKPAY_REVERSAL'},
            {name: 'Fuel advance fee', id: 'FUEL_FEE'},
        ]
    },
    {name: '', title: 'Rate', custom: false},
    {name: '', title: 'Description', custom: false},

]

export const transactionBody = [
    {rowText: (item) => <DateRow date={item?.createdAt}/>},
    {rowText:  (item) => <TextRow name={item?.username} textWidth={10}  />},
    {rowText:  (item) => <TextRow name={item?.qty ? item?.qty : 1} textWidth={10}  />},
    {rowText: (item) => <div>{handleGetTypes(item)}</div>},
    {rowText:  (item) =>
            <p className={item.amount > 0 ? 'positive-type' : 'negative-type'}>
                <PriceRow info={item.amount}  />
            </p>
    },


    {rowText:  (item) => <TextRow big={'bigText'} size={20} name={item?.description}  />},
]
