import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const tableStyle = makeStyles(() => ({
    selectInput: {
        // width: '228px',
        // height: '36px',
        // background: '#FFFFFF',
        // border: '1px solid #438AFE',
        // borderRadius: '4px',
        // padding: '0 4px'
    },

    select: {
        width: '99%',
        height: '32px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#51566D',
    },

    selectItem:{
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#51566D',
    },

    tableHead: {
        background: Colors.BackgroundPrimary,
        borderRadius: "8px 0px 0px 0px",
        height: "50px",
        fontSize: "16px",
        fontWeight: '600',
        position: 'relative',
        zIndex: '10',

        "& .MuiTableCell-sizeSmall": {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "19px",
            color: Colors.TextSecondary,
            padding: "8px",
            "@media (min-width: 1920px)": {
                padding: "17px 16px 17px 16px",
            },
        },
        '& .MuiTableCell-stickyHeader': {
            background: Colors.BackgroundPrimary,
            height: "50px",
            fontSize: "16px",
            fontWeight: '600',
        }
    },

    tableRow: {
        cursor: 'pointer',
        "&:hover": {
            background: "#EAF2FF 0% 0% no-repeat padding-box",
        },
        height: "50px",
        "& .makeStyles-membersTableWrapper-26 .MuiTableContainer-root": {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 8px 12px #0052E01A",
        },
        "& .MuiTableCell-sizeSmall": {
            fontSize: "14px",
            lineHeight: "22px",
            color: Colors.TextPrimary,

            padding: "8px 16px 8px 16px",
            "@media (min-width: 1920px)": {
                padding: "16px",
            },
        },

        "& .MuiTableCell-sizeSmall:last-child": {
            paddingTop: "11px",
            fontSize: "16px",
            lineHeight: "30px",
            color: "#545F7E",
        },

        "& MuiSwitch-track": {
            borderRadius: "12px",
            background: "lightgray",
        },

        "& .MuiSwitch-colorPrimary.Mui-checked": {
            color: "white",
        },

        "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
            background: "#387DFF",
            borderRadius: "12px",
            opacity: "1",
        },
    },

    InfoAndImage: {
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "14px",
            lineHeight: "19px",
            fontWeight: "bold",
            color: Colors.TextSecondary,
        },
        "& img": {
            width: "24px",
            height: "24px",
            marginRight: "4px",
            "@media (min-width: 1920px)": {
                marginRight: "8px",
            },
        },
    },


    selectType: {
        width: '160px',
        background: 'white',
        marginBottom: '20px',

        '& .MuiSelect-outlined.MuiSelect-outlined': {
            background: 'transparent',
        },
        '& .MuiSelect-icon': {
            color: Colors.ThemeBlue
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBlue
        },
        '& .MuiOutlinedInput-root': {
            height: '36px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-7px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)'
        },
    },


    lineWrapper: {
        display: 'flex',
    },
    globalHistory: {
        height: 'auto',
        // height: 'calc(100vh - 380px)',
        overflow: 'auto',
    },
    filterButtons: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginBottom: '24px',
        marginTop: '8px',
    },
    managementHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    officeSelectInput: {
        marginBottom: '24px',
        width: '100%',
        marginTop: '32px',
    },

    inputsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '24px',
    },

    officeManagement: {
        width: '100%',
    },

    officeManagementHead: {
        height: '335px',
        overflow: 'auto',
        marginTop: '24px',
        "@media (min-width: 1920px)": {
            marginTop: '32px'
        }
    },
    officeManagementSmall: {
        height: '230px',

        overflow: 'auto',
        marginTop: '24px',
        "@media (min-width: 1920px)": {
            marginTop: '32px',
            height: '270px',
        }
    },
    authoritesManagementHead: {
        marginTop: '34px',
        "@media (min-width: 1920px)": {
            marginTop: '40px'
        },
        '& .authorites-item': {
            height: '20px'
        },
        height: '240px',
        overflow: 'auto',
    },
    authoritiesManagementHeadTitle: {
        '& div': {
            display: 'flex',
            alignItems: 'center',
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'none'
        }
    },
    officeManagementHeadTitle: {
        height: '48px',
        background: '#E6ECF380 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginBottom: '8px',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: '16px',

        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'none'
        }
    },
    loadDiv: {
        borderBottom: `1px solid ${Colors.TextLight}`,
        width: '500px',
        '& .MuiLinearProgress-barColorPrimary': {
            background: Colors.ThemeBlue,
        }
    },
    officeDocHeadTitle: {
        height: '48px',
        background: 'none',
        borderBottom: `1px solid ${Colors.TextLight}`,
        marginBottom: '8px',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',


        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'none'
        }
    },

    branchesWrapper: {
        width: '100%',
        marginLeft: '24px',
        "@media (min-width: 1920px)": {
            marginLeft: '32px',
        }
    },

    tableWrapper: {
        "@media (min-width: 1920px)": {
            marginLeft: '32px',
        },
        marginLeft: '24px',
        height: '100%',
        minHeight: '80vh',
        width: '100%',
        boxShadow: '0px 0px 12px #0052E01A',
        borderRadius: '8px',
        marginTop: '16px',
        marginBottom: '24px',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
        },
    },
    editButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '-50px'
    },
    simpleInput: {
        marginRight: '30px',
        width: '233px',
        "@media (min-width: 1919px)": {
            marginRight: "56px",
            width: '344px'
        },
    },
    basicInfoInputs: {
        display: "flex",
        marginTop: "12px",
        // "@media (min-width: 1920px)": {
        //     marginTop: "24px",
        // },
    },

    managementWrapper: {
        marginRight: '16px',
        width: '100%',
    },

    dragDrop: {
        background: '#F7F9FC 0% 0% no-repeat padding-box',
        border: `3px dashed ${Colors.TextWhiteBlue}`,
        borderRadius: '8px',
        height: '244px',
        width: '100%',
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        "@media (min-width: 1920px)": {
            height: '284px',
        },
        '& img': {
            marginBottom: '16px',
        }
    },
    // selectType:{
    //   background:'white',
    //   width:'70%',
    //   marginBottom:'20px',
    // },
    selectText: {
        fontSize: '16px',
        color: Colors.TextPrimary,
        marginBottom: '16px',
    },
    drag: {
        fontSize: '24px',
        fontWeight: '600',
        color: Colors.TextPrimary,
    },
    upload: {
        fontSize: '24px',
        fontWeight: '600',
        color: `${Colors.ThemeBlue}!important`,
        marginLeft: '8px',
    },
    downloadButton: {
        '& a': {
            width: '50px',
        },
        width: '50px',
        background: 'none',
        border: 'none',
        outline: 'none'
    },

    selectBranchInput: {
        marginBottom: '24px'
    },


    dateFiled: {
        marginRight: '16px',
        background: 'white',
        width: '180px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `${Colors.ThemeBorder}!important`,
        },
        '& .MuiOutlinedInput-root': {
            height: '36px'
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color: `${Colors.ThemeBlue}!important`
        },
    },

    search: {
        width: '92px',
        height: '36px',
        boxShadow: '0px 0px 6px #438AFE4D',
        borderRadius: '18px',
        background: Colors.ThemeBlue,
        color: Colors.BackgroundWhite,
        fontSize: '14px',
        border: 'none',
        marginRight: '40px',
        textTransform: 'capitalize',
        "&:hover": {
            background: Colors.ThemeBorderBlue,
        },
    },

    viewHistory: {
        textDecoration: 'none',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        textTransform: "capitalize",
        background: Colors.ThemeBlue,
        position: 'absolute',
        right: '36px',
        height: '36px',
        borderRadius: '18px',
        color: 'white',
        padding: '0 24px',
    },

    alignItemButton:{
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        background: 'transparent'
    },

    alignCenter:{
        display:'flex',
        alignItems:'center',
    }

}));
