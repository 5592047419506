import {DateRow, IdRow, PriceRow, TextRow, TypeRow} from "components";
import React from "react";
import { customerPaymentsStatuses } from '../customerPayment/constants';

export const carrierPmtHead = [
    // {name: 'id', title: 'ID', custom: false},
    {name: 'createdAt', title: 'Created Date', smallSize: 7, icon: 'date', type: 'date'},
    {name: 'payeeName', title: 'Payee', custom: false},
    {name: 'referenceNumber', title: 'Reference Number', custom: false, smallSize: 7},
    {
        name: 'pmtMethod', title: 'Method',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Check', id: 'CHECK'},
            {name: 'Ach', id: 'ACH'},
        ]
    },
    {name: 'amount', title: 'Amount', custom: false},
    {
        name: 'status', title: 'Status',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Applied', id: 'APPLIED'},
            {name: 'Voided', id: 'VOIDED'},
        ]
    },
]

export const carrierPmtBody = [
    // {rowText: (item) => <IdRow id={item?.id}/>},
    {rowText: (item) => <DateRow date={item?.createdAt}/>},
    {rowText:  (item) => <TextRow name={item?.payeeName} textWidth={10}  />},
    {rowText:  (item) => <TextRow name={item?.referenceNumber ? item?.referenceNumber : '...'} textWidth={10}  />},
    {rowText: (item) => <TypeRow text={item?.method}/>},
    {rowText:  (item) => <PriceRow info={item?.amount}  />},
    {rowText: (item) => <TypeRow text={item?.status}/>},
]

export const ACTION_TYPE = 'GET_CARRIER_PAYMENTS'

export const carrierPaymentsStatuses = [
    { id: 'OVERVIEW', name: 'Overview' },
    { id: 'DETAILED', name: 'Detailed' }
];

export const carrierPaymentCsv = {
    type:'carrierPayment',
    statusList: carrierPaymentsStatuses,
    dateFilter: true,
    requiredDateRange: true,
    requiredStatus: true,
}
