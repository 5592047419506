import React from "react";
import {useParams} from "react-router-dom";
import {History} from "fragments";
import {CustomBreadcrumbs} from "components";

export const HistoryPage = () => {
    const params = useParams()
    return (
        <div>
            <CustomBreadcrumbs
                thirdLink={`/load/${params.id}`}
                third={'Load Info'}
                parentLink={'/loads'}
                parent={'Loads'}
                child={'History'}
            />
            <History/>
        </div>
    )
}