import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    Colors,
    docTypes,
    PermissionsList,
    RolePermission,
    uploadDocTypes,
    useGlobalStyles,
    useGlobalText
} from "utils";
import {AddModalButton, Circle, CreateChancel, DocumentsComponent, ErrMessage, Line,} from "components";
import {createLoadStyle} from "./styles";
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    httpRequestsOnLoadActions,
    loadActions,
    LoadServices,
} from "store";
import { CANCELLED, IN_TRANSIT, PROCESSING } from '../../../../pages/accounting/constants';

const REQUEST_TYPE = 'ADD_LOAD_DOC'
const DELETE_REQUEST_TYPE = 'SET_LOAD_DOC_STATUS'

export const DocumentsSection = ({info, id}) => {
    const globalStyles = useGlobalStyles();
    const globalText = useGlobalText();
    const classes = createLoadStyle();
    const dispatch = useDispatch()
    const [docs, setDocks] = useState([])
    const [done, setDone] = useState(info && info.status !== "INTRANSIT")
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState('')
    const history = useHistory()
    const checkProcess = info?.documents?.length && info?.documents.find((i) => i.type === 'POD' && i?.status === "ACTIVE")
    const checkRate = info?.documents?.length && info?.documents.find((i) => i.type === 'RATECON' && i?.status === "ACTIVE")


    useEffect(() => {
        if (info?.documents) {
            setDocks(info.documents)
            if (info?.customer && info.customer.requireRateCon === false) {
                setDone(false)
            } else {
                if (info.status !== IN_TRANSIT) {
                    let docInfo = info.documents.filter((i) => i.type === 'RATECON' && i?.status === "ACTIVE")
                    if (docInfo.length) {
                        setDone(false)
                    }else{
                        setDone(true)
                    }
                }
            }
        }
    }, [info])

    const handleFilter = e => {
        if (e.target.value === 'All DocumentsSection') {
            setDocks(info.documents)
        } else {
            const newList = info && info.documents.filter((i) => i.type === e.target.value)
            setDocks(newList)
        }
    };

    const handleSubmit = async () => {
        setError('')
        if (info && info.isTonu === true && !info.carrier) {
            dispatch(httpRequestsOnErrorsActions.appendError('NOT_CARRIER'))
        } else {
            setLoading(true)
            try {
                await LoadServices.SubmitLoad(id)
                dispatch(httpRequestsOnSuccessActions.appendSuccess('LOAD_WAS_SUBMITTED'))
                dispatch(loadActions.getLoadById(id, 'noLoad'))
                setLoading(false)
            } catch (e) {
                if (e?.data?.message === 'Terms and condition was not found') {
                    dispatch(httpRequestsOnErrorsActions.appendError('TERMS_NOT_FOUND'))
                }
                setError(e?.data?.message)
                setLoading(false)
            }
        }
    }

    const handleProcessing = async () => {
        setError('')
        const loadStatus = {loadStatus: 'PROCESSING'}
        setLoader(true)
        try {
            await LoadServices.SetLoadProcessing(id, loadStatus)
            dispatch(loadActions.getLoadById(id, 'noLoad'))
            dispatch(httpRequestsOnSuccessActions.appendSuccess('LOAD_STATUS'))
            setLoader(false)
        } catch (e) {
            setLoader(false)
        }
    }

    const handleSetDocStatus = async (doc) => {
        try {
            dispatch(httpRequestsOnLoadActions.appendLoading(DELETE_REQUEST_TYPE))
            const info = {
                status: doc.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
            }
            await LoadServices.SetLoadDocumentStatus(id, doc.id, info)
            dispatch(loadActions.getLoadById(id, 'noLoad'))
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_REQUEST_TYPE))
            dispatch(httpRequestsOnSuccessActions.appendSuccess(DELETE_REQUEST_TYPE))
        } catch (e) {
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_REQUEST_TYPE))
        }
    }

    const handleUpload = async (loadDoc) => {
        try {
            const currentFile = {...loadDoc}
            currentFile.file.originalName = currentFile?.name
            await LoadServices.UploadLoadDocument(id, currentFile)
            dispatch(httpRequestsOnLoadActions.removeLoading(REQUEST_TYPE))
            dispatch(loadActions.getLoadById(id, 'noLoad'))
        } catch (e) {
            dispatch(httpRequestsOnLoadActions.removeLoading(REQUEST_TYPE))
        }
    }

    const checkProcessingButton =
        info?.status === IN_TRANSIT && info?.status !== CANCELLED && info?.customer?.requirePOD === false ? true :
        info?.isTonu === true && info?.status === IN_TRANSIT && info?.status !== CANCELLED ? true :
        (info?.status === IN_TRANSIT && info?.status !== CANCELLED && checkProcess)

    return (
        <div>
            <div className={classes.titleAndSwitcher}>
                <div className={globalStyles.centerItem}>
                    <Circle number={2} back={Colors.ThemeAqua}/>
                    <p className={globalText.title}>
                        <span style={{color: Colors.ThemeAqua}}>{checkProcess && checkRate ? '' : '*'}</span>
                        Documents
                    </p>
                </div>
            </div>
            <div className={globalStyles.flexAndFullWidth} style={{marginBottom: 30}}>
                <div className={'MuiAccordionDetails-root'}>
                    <Line height={'auto'}/>
                </div>
                <div className={classes.documentSection}>
                    <DocumentsComponent
                        removeActionType={DELETE_REQUEST_TYPE}
                        actionType={REQUEST_TYPE}
                        handleFilter={handleFilter}
                        handleUpload={handleUpload}
                        handleSwitch={handleSetDocStatus}
                        fileTypeList={uploadDocTypes}
                        docTypes={docTypes}
                        documents={docs}
                        noDel={true}
                        switchButton={true}
                    />
                    {info?.customer?.requireRateCon === true && info?.status !== IN_TRANSIT && done &&
                        <ErrMessage type={"Pass"} text={'For Submit Load Please Upload Rate Con file'}/>
                    }
                    {error &&
                        <ErrMessage style={{margin: '25px 0'}} type={"Pass"} text={error}/>
                    }
                    {info?.status !== CANCELLED && info?.status !== IN_TRANSIT && info?.status !== PROCESSING && !done && info?.status !== CANCELLED &&
                        <CreateChancel
                            disabled={done}
                            loader={loading}
                            classes={globalStyles.buttonsStyle}
                            create={"Submit Load"}
                            chancel={"Cancel"}
                            onCreate={handleSubmit}
                            onClose={() => history.push('/loads')}
                        />
                    }
                    {info?.status !== CANCELLED && checkProcessingButton ?
                        <AddModalButton
                            loading={loader}
                            styles={{marginTop: '28px', width: '238px'}}
                            handleClick={handleProcessing}
                            text={'Send to Processing'}
                        />
                        :
                      info?.status === IN_TRANSIT &&
                        <ErrMessage
                            type={"Pass"}
                            text={'To send the load to processing, at least one POD file is required'}
                        />
                    }
                </div>
            </div>
        </div>
    )
}