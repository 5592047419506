import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import {
    AddModalButton,
    AddressInput,
    CloseButton,
    CreateChancel,
    ValidationInput,
    TimePicker
} from "components";
import {ErrorText, globalModals, useGlobalStyles, useGlobalTextStyles} from "utils";
import {loadCommonStyle} from "./styles";
import {createLoadStyle} from "../styles";
import {httpRequestsOnErrorsActions, loadActions, LoadServices} from "store";
import {PicDropText, PickDropType} from "./constants";

export const DropPickModal = ({type, handleClose, modalInfo, id}) => {
    const dispatch = useDispatch()
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const classes = loadCommonStyle()
    const loadClasses = createLoadStyle();
    const globalStyles = useGlobalStyles();
    const [hour, setHour] = useState('')
    const [minute, setMinute] = useState('')
    const [loader, setLoader] = useState(false)
    const [inputs, setInputs] = useState({})
    const [error, setError] = useState('')
    const [address, setAddress] = useState({})
    const text = PicDropText(modalInfo, type)

    useEffect(() => {
        if (modalInfo && modalInfo.id)
            setInputs({
                "date": moment(modalInfo.date).format('MM-DD-YYYY'),
                "time": moment(modalInfo.time).format('HH:mm'),
                "instruction": modalInfo.instruction,
                "name": modalInfo?.name
            })

        if(modalInfo && modalInfo.time){
            const formattedTime =  moment(modalInfo.time).format('HH:mm')
            const h = formattedTime && formattedTime.slice(0,2)
            const m = formattedTime && formattedTime.slice(3,5)
            setHour(h)
            setMinute(m)
        }
        if (modalInfo?.address) {
            const sendAddress = {
                address: modalInfo?.address?.formattedAddress,
            }
            if (modalInfo?.address?.unitNumber) {
                sendAddress.unitNumber = modalInfo?.address?.unitNumber
            }
            setAddress(sendAddress)
        }
    }, [modalInfo])

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}),);
        error === e.target.name && setError('')
    }

    const handleFullAddress = (ev) => {
        setAddress(ev)

        if(error === 'Unable to verify the address'){
            setError('')
        }
        if (error === 'address' && ev) {
            setError('')
        }
    }

    const addTime = async () => {
        if (address?.address && inputs.date && hour && minute) {
            const newDate = new Date(inputs.date)
            const slicedHour = hour
            const slicedMinute = minute
            newDate.setHours(slicedHour)
            newDate.setMinutes(slicedMinute)
            const isoDate = moment(newDate).format()
            const stopType = PickDropType(type)

            const time = {
                "address": address,
                "date": isoDate,
                "time": isoDate,
            }
            if (inputs.name) {
                time.name = inputs.name
            }
            if (inputs.instruction) {
                time.instruction = inputs.instruction
            }
            if (!modalInfo) {
                time["type"] = stopType
            }


            try {
                setLoader(true)
                if (modalInfo) {
                    await LoadServices.EditLoadStops(id, modalInfo.id, time)
                } else {
                    await LoadServices.CreateLoadStops(id, time)
                }
                dispatch(loadActions.getLoadById(id, 'noLoad'))
                setLoader(false)
                handleClose()
            } catch (e) {
                setError(e?.data?.message)
                setLoader(false)
            }
        } else {
            setError(
                !address?.address ? 'address' :
                    !inputs.date ? 'date' :
                        !hour ? 'time' :
                            !minute ? 'time' :
                                ''
            )
        }
    }

    const handeChangeHour = (e) => {
        setHour(e.target.value)
        error === 'time' && setError('')
        if (!minute) {
            setMinute('00')
        }
    }

    const handeChangeMinute = (e) => {
        error === 'time' && setError('')
        setMinute(e.target.value)
    }



    return (
        <div className={globalModalsClasses.smallModalWrapper}>
            <div className={globalModalsClasses.smallModalClose}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div className={globalModalsClasses.modalWrapperContent}>
                <p className={globalText.modalTitle}>{text}</p>
                <p className={globalText.modalText}>
                    {modalInfo ?
                        ''
                        :
                        'To add pickup please, fulfil the below fields.'
                    }
                </p>
                <div>
                    <p className={classes.smallText}>Name</p>
                    <ValidationInput
                        className={inputs.name && globalStyles.inputTextFieldBlue}
                        variant={"outlined"}
                        name={"name"}
                        type={"text"}
                        typeError={error === 'name' ? ErrorText.field : ''}
                        label={"Name"}
                        onChange={handleChange}
                        value={inputs.name}
                    />
                </div>
                <p className={classes.smallText}>Address</p>
                <div>
                    <AddressInput
                        loadInput={'pick'}
                        direction={'column'}
                        errorBoolean={
                           error === 'address' ? 'Input is not field' :
                            error === 'Unable to verify the address' ? 'Unable to verify the address' :
                               ''}
                        info={modalInfo}
                        handleSelectValue={handleFullAddress}
                    />
                </div>
                <p className={classes.smallText}>Date & Time </p>
                <div className={classes.dateAndTime}>
                    <ValidationInput
                        className={inputs.date && globalStyles.inputTextFieldBlue}
                        variant={"outlined"}
                        name={"date"}
                        type={"date"}
                        typeError={error === 'date' ? ErrorText.field : ''}
                        label={"Date*"}
                        onChange={handleChange}
                        value={modalInfo ? moment(inputs?.date).format('YYYY-MM-DD') : inputs.date}
                    />
                    <div style={{marginLeft: '16px'}}>
                        <TimePicker
                            minute={minute}
                            hour={hour}
                            changeHour={handeChangeHour}
                            changeMinute={handeChangeMinute}
                            err={error === 'time'}
                        />
                    </div>
                </div>
                <p style={{margin: '10px 0 0 0'}} className={classes.smallText}>Instructions</p>
                <div>
                    <ValidationInput
                        onChange={handleChange}
                        typeError={error === 'instruction' ? ErrorText.field : ''}
                        className={inputs.instruction ? loadClasses.inputDescriptionBlue : loadClasses.inputDescription}
                        variant={"outlined"}
                        name={"instruction"}
                        Length={100}
                        value={inputs.instruction}
                        label={"Instruction"}
                        type={"text"}
                        multiline={true}
                    />
                    <div className={classes.maxCharacters}>
                        <p>{`Max ${inputs.instruction ? inputs.instruction.length : 0}/100 characters`}</p>
                    </div>
                </div>
                {modalInfo ?
                    <CreateChancel
                        loader={loader}
                        classes={classes.buttonsStyle}
                        create={"Save"}
                        chancel={"Cancel"}
                        onCreate={addTime}
                        onClose={handleClose}
                    />
                    :
                    <AddModalButton
                        loading={loader}
                        styles={{marginTop: '16px'}}
                        handleClick={addTime}
                        text={'Add'}
                    />
                }
            </div>
        </div>
    )
}