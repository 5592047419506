import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {customerInformation} from "./styles";
import {AntSwitch, FindLoad, FindSuccessItem, useGlobalText} from "utils";
import {EditSaveButtons, Line, PriceConvertor, SelectInput, ValidationInput} from "components";
import {customerActions, httpRequestsOnSuccessActions} from "store";

export const CreditDebit = ({info}) => {
    const {mcsList, debtInfo, availableCredit} = useSelector((state) => ({
        mcsList: state.mcs.mcsList,
        debtInfo: state.customers.debtInfo,
        availableCredit: state.customers.availableCredit,
    }));
    const classes = customerInformation()
    const globalText = useGlobalText()
    const params = useParams()
    const dispatch = useDispatch()
    const [switchBoolean, setSwitchBoolean] = useState(false)
    const [switchConsign, setSwitchConsign] = useState(false)
    const [limit, setLimit] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [noteAcc, setNoteAcc] = useState(true)
    const [note, setNote] = useState(info?.creditNote ? info?.creditNote : '')
    const [mcId, setMcId] = useState('')
    const success = FindSuccessItem('EDIT_CUSTOMER')
    const loader = FindLoad('GET_CUSTOMER_DEBT')
    const activeSuccess = FindSuccessItem('ACTIVATE_DENY')
    const inactiveSuccess = FindSuccessItem('INACTIVATE_DENY')
    const debt = debtInfo && availableCredit
    const available = limit - debt

    useEffect(() => {
        if (info) {
            setSwitchBoolean(info.autoDeny === 'ACTIVE')
            setLimit(info.creditLimit)
            setSwitchConsign(info?.isConsignment)
        }
    }, [info])

    const handleSelectMc = (ev) => {
        setMcId(ev.target.value)
        if (ev.target.value) {
            dispatch(customerActions.getCustomerDebt(params.id, ev.target.value))
        } else {
            dispatch(customerActions.getCustomerDebt(params.id))
        }
    }

    const handleSwitch = () => {
        if (switchBoolean) {
            dispatch(customerActions.inactivateDeny(info.id))
            setSwitchBoolean(false)
        } else {
            dispatch(customerActions.activateDeny(info.id))
            setSwitchBoolean(true)
        }
    }

    const handleSwitchConsign = () => {
        dispatch(customerActions.consignCustomer(info.id, !switchConsign))
        setSwitchConsign(!switchConsign)
    }

    useEffect(() => {
        if (activeSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ACTIVATE_DENY'))
            setSwitchBoolean(true)
        }
        if (inactiveSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('INACTIVATE_DENY'))
            setSwitchBoolean(false)
        }
    }, [activeSuccess, inactiveSuccess])

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_CUSTOMER'))
            setDisabled(true)
        }
    }, [success]);

    const handleEdit = () => {
        const data = {
            "name": info.name,
            "email": info.email,
            "phone": info.phone,
            "billingEmail": info?.billingEmail,
            "address": info?.address?.formattedAddress,
            "secondAddress": info?.secondAddress?.formattedAddress,
            "type": info.type,
            "mc": info.mc,
            "creditLimit": limit,
        }
        data.creditNote = note ? note : null
        dispatch(customerActions.editCustomer(params.id, data))
    }

    return (
        <div className={classes.fullScreen}>
            <Line height={'820px'}/>
            <div className={classes.creditDebitWrapper}>
                <div className={classes.credit}>
                    <div className={classes.creditWrapper}>
                        <p className={globalText.smallText}>Customer Credit</p>
                        <div className={classes.creditLeft}>
                            <div className={classes.requestConsignment}>
                                <span style={{width:'148px'}} className={globalText.smallSwitchText}>Request Consignment</span>
                                <AntSwitch
                                    onClick={handleSwitchConsign}
                                    checked={switchConsign}
                                />
                            </div>
                            <span className={globalText.smallSwitchText}>Auto Deny</span>
                            <AntSwitch
                                onClick={handleSwitch}
                                checked={switchBoolean}
                            />
                            <div style={{marginLeft: '16px'}}>
                                <EditSaveButtons
                                    margin={false}
                                    type={'EDIT_CUSTOMER'}
                                    handleChancel={() => setDisabled(true)}
                                    handleSetEdit={() => setDisabled(false)}
                                    handleSaveInfo={handleEdit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.inputs}>
                        <ValidationInput
                            variant={"outlined"}
                            type={"text"}
                            label={"Available Credit"}
                            disabled={true}
                            value={available}
                        />
                        <ValidationInput
                            style={classes.creditInput}
                            variant={"outlined"}
                            name={"lastName"}
                            type={"number"}
                            label={"Credit Limit*"}
                            onChange={(ev) => setLimit(ev.target.value)}
                            value={limit}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className={classes.creditAuthority}>
                    <div className={classes.credit}>
                        <div className={classes.titleInputRow}>
                            <p>Customer Debt</p>
                            <SelectInput
                                loader={!!loader?.length}
                                style={classes.simpleInput}
                                name={"mcAuthorities"}
                                label={"Select Authorities"}
                                handleSelect={handleSelectMc}
                                list={mcsList?.mcs?.length ? mcsList.mcs : []}
                                value={mcId ? mcId : ''}
                                type={'id'}
                            />
                        </div>
                        <div className={classes.infoRows}>
                            <div style={{marginRight: '24px'}} className={classes.infoRowsWrapper}>
                                <div className={classes.rows}>
                                    <p className={classes.title}>Customer's Debt:</p>
                                    <span className={classes.price}>
                                        <PriceConvertor price={debtInfo?.owedAmount}/>
                                        {/*{debtInfo?.owedAmount ? `$${debtInfo.owedAmount}` : '$0'}*/}
                                    </span>
                                </div>
                                <div className={classes.rows}>
                                    <p className={classes.title}>Number of Loads</p>
                                    <span className={classes.price}>{debtInfo?.loadCount}</span>
                                </div>
                            </div>
                            <div className={classes.infoRows}>
                                <div className={classes.infoRowsWrapper}>
                                    <div className={classes.rows}>
                                        <p className={classes.title}>Paid Amount</p>
                                        <span className={classes.price}>
                                             <PriceConvertor price={debtInfo?.paidAmount}/>
                                            {/*{debtInfo?.paidAmount ? `$${debtInfo.paidAmount}` : '$0'}*/}
                                        </span>
                                    </div>
                                    <div className={classes.rows}>
                                        <p className={classes.title}>Paid Average</p>
                                        <span className={classes.price}>{debtInfo?.paidAverage}</span>
                                    </div>
                                    <div className={classes.rows}>
                                        <p className={classes.title}>Paid Loads</p>
                                        <span className={classes.price}>{debtInfo?.paidLoadCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={classes.credit} style={{width: '50%'}}>
                    <div className={classes.creditWrapper}>
                        <p className={globalText.smallText}>Notes</p>
                        <div className={classes.creditLeft}>
                            <div style={{marginLeft: '16px'}}>
                                <EditSaveButtons
                                    margin={false}
                                    type={'EDIT_CUSTOMER'}
                                    handleChancel={() => setNoteAcc(true)}
                                    handleSetEdit={() => setNoteAcc(false)}
                                    handleSaveInfo={handleEdit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.inputs}>
                        <textarea
                            onChange={(e) => setNote(e.target.value)}
                            value={note}
                            className={classes.notesInput}
                            disabled={noteAcc}
                            placeholder={info?.creditNote ? '' : 'Not Yet'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}