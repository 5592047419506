import React from 'react';
import { Images, useGlobalText, useModal } from 'utils';
import { buttonsStyle } from './styles';
import { CsvModal } from '../modal';

export const CsvFile = ({ params }) => {
  const classes = buttonsStyle()
  const globalText = useGlobalText();
  const { open } = useModal();


  return(
    <div>
      <button
        className={classes.csvExportBtn}
        onClick={() => open(<CsvModal params={params} />)}
      >
          <img src={Images.download} alt="icon" />
        <p
          className={globalText.downloadText}
        >
          Export CSV File
        </p>
      </button>
    </div>
  )
}