import {DateRow, PriceRow, TextRow, TypeRow} from "components";
import React from "react";

export const headList = [
    {name: 'displayId', title: 'Load ID', custom: false, rowWidth: '7%'},
    {name: '', title: 'Submitted date', custom: false, rowWidth: '10%', bigSize: 10, smallSize: 10},
    {name: 'office', title: 'Office', custom: false, rowWidth: '10%', nameRow: 'name'},
    {name: 'agent', title: 'Agent', custom: false, rowWidth: '10%', nameRow: 'name'},
    {name: 'mc', title: 'Authority', custom: false, rowWidth: '10%', nameRow: 'name'},
    {name: 'customer', title: 'Customer', custom: false, rowWidth: '10%', nameRow: 'name'},
    {name: 'carrier', title: 'Carrier', custom: false, rowWidth: '10%', nameRow:'name'},
    {name: 'carrierRate', title: 'Carrier Rate', custom: false, rowWidth: '10%', bigSize: 10, smallSize: 9},
    {name: 'customerRate', title: 'Customer Rate', custom: false, rowWidth: '10%', bigSize: 10, smallSize: 9},
    {name: 'profit', title: 'Profit', custom: false, rowWidth: '10%'},
    {name: '', title: '', custom: false, rowWidth: '3%', notSearch:true},
]

export const loadPayrollHead = [
    {name: 'date', title: 'Date', icon: 'date'},
    {name: 'type', title: 'Type'},
    {name: 'amount', title: 'Amount', custom: false},
    {name: 'agent', title: 'Agent', custom: false},
    {name: 'office', title: 'Office', custom: false},
    {name: 'branch', title: 'Branch', custom: false},
    {name: 'description', title: 'Description', custom: false, smallSize: 6},
]

export const loadPayrollBody = [
    {rowText: (item) => <DateRow date={item?.createdAt}/>},
    {rowText: (item) => <TypeRow text={
            item?.type === 'GROSSPROFIT' ? 'Gross Profit' :
                item?.type === 'SERVICEFEE' ? 'Service Fee' :
                    item?.type === 'OFFICE_EXPENSE' ? 'Office Expense' :
                        item?.type
        } />},
    {rowText:  (item) => <div className={item?.amount < 0 ? 'negative-price' : 'positive-price'}><PriceRow info={item?.amount}  /></div>},
    {rowText:  (item) => <TextRow name={item?.agent ? `${item?.agent?.firstName} ${item?.agent?.lastName}` : 'Not Set'} textWidth={12}  />},
    {rowText:  (item) => <TextRow name={item?.office ? item?.office?.name : 'Not Set'} textWidth={12}  />},
    {rowText:  (item) => <TextRow name={item?.branch ? item?.branch?.name : 'Not Set'} textWidth={12}  />},
    {rowText:  (item) => <TextRow name={item?.description ? item?.description : 'Not Set'} textWidth={12}  />},
]