import React from 'react';
import { DateRow, PriceRow, TextRow, VoidButton, VoidedButton } from 'components';
import { PermissionsList, RolePermission, userType } from 'utils';
import { handleReturnColor } from 'components/table/constants';


const blueColor = 'positive-type';
const redColor = 'negative-type';

const sameTransactions = [
  { name: 'Processing Fee Reversal', id: 'LPF_CARRIER_CREDIT' },
  { name: 'Extra to rate', id: 'EXTRA_TO_RATE' },
  { name: 'Duties and fees', id: 'DUTIES_AND_FEES' },
  { name: 'Entry service fee', id: 'ENTRY_SERVICE_FEE' },
  { name: 'Single bond fee', id: 'SINGLE_BOND_FEE' },
  { name: 'FDA fee', id: 'FDA_FEE' },
  { name: 'ISF fee', id: 'ISF_FEE' },
];

const TxnType = {
  //users can use these
  RATEADJUSTMENT: 'RATEADJUSTMENT',
  WAREHOUSING: 'WAREHOUSING',
  DRIVERASSIST: 'DRIVERASSIST',
  LATEFEE: 'LATEFEE',
  LUMPER: 'LUMPER',
  PICK_UP_FEE: 'PICK_UP_FEE',
  DELIVER_FEE: 'DELIVER_FEE',
  DETENTION: 'DETENTION',
  LAYOVER: 'LAYOVER',
  QUICKPAY_REVERSAL: 'QUICKPAY_REVERSAL',

  //system also can use these
  INITIALRATE: 'INITIALRATE',
  SHORTPAY: 'SHORTPAY',
  CLAIM: 'CLAIM',
  EFS: 'EFS',
  FUEL: 'FUEL',
  CUSTOMERPAYMENT: 'CUSTOMERPAYMENT',
  CARRIERPAYMENT: 'CARRIERPAYMENT',
  COLLECTIONAPPLIED: 'COLLECTIONAPPLIED',
  QUICKPAY: 'QUICKPAY',
  FUEL_FEE: 'FUEL_FEE',

  PALLETIZING: 'PALLETIZING',
  DRAYAGE: 'DRAYAGE',
  CHASSIS_FEE: 'CHASSIS_FEE',
  DROP_FEE: 'DROP_FEE',
  ATTEMPT: 'ATTEMPT',
  FSC: 'FSC',
  PRE_PULL: 'PRE_PULL',
  PRE_DIEM: 'PRE_DIEM',
  PIER_PASS: 'PIER_PASS',
  CONTAINER_STORAGE: 'CONTAINER_STORAGE',
  CHASSIS_SPLIT: 'CHASSIS_SPLIT',
  DEMURRAGE: 'DEMURRAGE',

  CORPORATE: 'CORPORATE',
  OFFICE_CHARGE: 'OFFICE_CHARGE',

  LPF_CARRIER_CHARGE: 'LPF_CARRIER_CHARGE',
  LPF_CARRIER_CREDIT: 'LPF_CARRIER_CREDIT',
  LPF_AGENT_CHARGE: 'LPF_AGENT_CHARGE',
  LPF_AGENT_CREDIT: 'LPF_AGENT_CREDIT',
};


export const transactionHead = [
  { name: '', title: 'Created Date', icon: 'date', custom: false, width: '100px' },
  { name: '', title: 'Creator', custom: false },
  {
    name: 'type', title: 'Type',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Rate Adjustment ', id: 'RATEADJUSTMENT' },
      { name: 'Warehousing', id: 'WAREHOUSING' },
      { name: 'Late fee', id: 'LATEFEE' },
      { name: 'Lumper', id: 'LUMPER' },
      { name: 'Pickup fee', id: 'PICK_UP_FEE' },
      { name: 'Deliver fee', id: 'DELIVER_FEE' },
      { name: 'Detention', id: 'DETENTION' },
      { name: 'Initial Rate', id: 'INITIALRATE' },
      { name: 'Short Pay', id: 'INITIALRATE' },
      { name: 'Customer Payment', id: 'CUSTOMERPAYMENT' },
      { name: 'Palletizing', id: 'PALLETIZING' },
      { name: 'Drayage', id: 'DRAYAGE' },
      { name: 'Chassis Fee', id: 'CHASSIS_FEE' },
      { name: 'Drop Fee', id: 'DROP_FEE' },
      { name: 'Attempt', id: 'ATTEMPT' },
      { name: 'FSC', id: 'FSC' },
      { name: 'Pre-Pull', id: 'PRE_PULL' },
      { name: 'Per Diem', id: 'PRE_DIEM' },
      { name: 'Pier Pass (CTF/TMF) fee', id: 'PIER_PASS' },
      { name: 'Container Storage', id: 'CONTAINER_STORAGE' },
      { name: 'Demurrage', id: 'DEMURRAGE' },
      { name: 'Chassis Split', id: 'CHASSIS_SPLIT' },
      ...sameTransactions,
    ],
  },
  { name: '', title: 'Rate', custom: false },
  { name: 'qty', title: 'Qty', custom: false, width: '100px' },
  { name: '', title: 'Total', custom: false },
  { name: '', title: 'Description', custom: false },
  {
    name: 'status', title: 'Action',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Void', id: 'APPLIED' },
      { name: 'Voided', id: 'VOID' },
    ],
    width: '100px',
  },
];


export const carrierTransactionHead = [
  { name: '', title: 'Created Date', icon: 'date', custom: false, width: '100px' },
  { name: '', title: 'Creator', custom: false },
  {
    name: 'carrierType', title: 'Type',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Rate Adjustment ', id: 'RATEADJUSTMENT' },
      { name: 'Warehousing', id: 'WAREHOUSING' },
      { name: 'Late fee', id: 'LATEFEE' },
      { name: 'Lumper', id: 'LUMPER' },
      { name: 'Pickup fee', id: 'PICK_UP_FEE' },
      { name: 'Deliver fee', id: 'DELIVER_FEE' },
      { name: 'Detention', id: 'DETENTION' },
      { name: 'Initial Rate', id: 'INITIALRATE' },
      { name: 'Efs', id: 'EFS' },
      { name: 'Short Pay', id: 'INITIALRATE' },
      { name: 'Fuel', id: 'FUEL' },
      { name: 'Collection Applied', id: 'COLLECTIONAPPLIED' },
      { name: 'Quick Pay', id: 'QUICKPAY' },
      { name: 'Carrier Payment', id: 'CARRIERPAYMENT' },
      { name: 'Quickpay Reversal', id: 'QUICKPAY_REVERSAL' },
      { name: 'Fuel advance fee', id: 'FUEL_FEE' },

      { name: 'Palletizing', id: 'PALLETIZING' },
      { name: 'Drayage', id: 'DRAYAGE' },
      { name: 'Chassis Fee', id: 'CHASSIS_FEE' },
      { name: 'Drop Fee', id: 'DROP_FEE' },
      { name: 'Attempt', id: 'ATTEMPT' },
      { name: 'FSC', id: 'FSC' },
      { name: 'Pre-Pull', id: 'PRE_PULL' },
      { name: 'Per Diem', id: 'PRE_DIEM' },
      { name: 'Pier Pass (CTF/TMF) fee', id: 'PIER_PASS' },
      { name: 'Container Storage', id: 'CONTAINER_STORAGE' },
      { name: 'Demurrage', id: 'DEMURRAGE' },
      { name: 'Chassis Split', id: 'CHASSIS_SPLIT' },
      { name: 'Processing Fee', id: 'PROCESSING_FEE' },
      { name: 'Processing Fee Charge', id: 'LPF_CARRIER_CHARGE' },
      { name: 'Quickpay', id: 'QUICKPAY' },
      ...sameTransactions,
    ],
  },
  { name: '', title: 'Rate', custom: false },
  { name: 'qty', title: 'Qty', custom: false, width: '100px' },
  { name: '', title: 'Total', custom: false },
  { name: '', title: 'Description', custom: false },
  {
    name: 'carrierVoid', title: 'Action',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Void', id: 'APPLIED' },
      { name: 'Voided', id: 'VOID' },
    ],
    width: '100px',
  },
];

export const transactionBody = [
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.username} textWidth={10} /> },
  { rowText: (item) => <div>{handleGetTypes(item)}</div> },
  {
    rowText: (item) =>
      <p className={item.amount > 0 ? blueColor : redColor}>
        <PriceRow info={item?.amount} />
      </p>,
  },
  { rowText: (item) => <TextRow name={item?.qty ? item?.qty : 1} textWidth={10} /> },
  {
    rowText: (item) =>
      <p className={item.amount > 0 ? blueColor : redColor}>
        <PriceRow info={item?.totalAmount ? item?.totalAmount : 0} />
      </p>,
  },

  { rowText: (item) => <TextRow name={item?.description} textWidth={13} /> },
  { button: (item, info, load) => renderButton(item, info, load), notClickable: true },
];

export const renderColors = (item) => {
  switch (item.type) {
    case TxnType.QUICKPAY:
    case TxnType.CARRIERPAYMENT:
    case TxnType.LPF_CARRIER_CHARGE:
    case TxnType.FUEL:
    case TxnType.FUEL_FEE:
    case TxnType.EFS:
    case TxnType.LPF_AGENT_CHARGE:
      if (item.amount > 0) return redColor;
      return blueColor;
    //Everything else is the opposite
    default:
      if (item.amount > 0) return blueColor;
      return redColor;
  }
};

export const transactionCarrierBody = [
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.username} textWidth={10} /> },
  { rowText: (item) => <div>{handleGetTypes(item)}</div> },
  {
    rowText: (item) =>
      <p className={renderColors(item)}>
        <PriceRow info={item?.amount} />
      </p>,
  },
  { rowText: (item) => <TextRow name={item?.qty ? item?.qty : 1} textWidth={10} /> },
  {
    rowText: (item) =>
      <p className={renderColors(item)}>
        <PriceRow info={item?.totalAmount} />
      </p>,
  },
  { rowText: (item) => <TextRow name={item?.description} textWidth={13} /> },
  { button: (item, info, load) => renderButton(item, info, load), notClickable: true },
];


export const ACTION_TYPE = 'GET_LOAD_TRANSACTIONS';
export const CARRIER_TXNS_ACTION_TYPE = 'GET_LOAD_CARRIER_TRANSACTIONS';
export const OTHER_TXNS_ACTION_TYPE = 'GET_LOAD_OTHER_TRANSACTIONS';

const renderButton = (item, info) => {
  if (info?.status === 'VOID') {
    return <VoidedButton />;
  } else if (
    userType === 'ADMIN' && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.TRANSACTION_VOID_BUTTON?.code]) &&
    info?.type !== TransactionTypes.QUICKPAY_REVERSAL && info?.type !== TransactionTypes.QUICKPAY &&
    info?.type !== TransactionTypes.CUSTOMERPAYMENT && info?.type !== TransactionTypes.CARRIERPAYMENT &&
    info?.type !== TransactionTypes.FUEL_FEE && info?.type !== TransactionTypes.LPF_AGENT_CHARGE &&
    info?.type !== TransactionTypes.LPF_AGENT_CREDIT && info?.type !== TransactionTypes.LPF_CARRIER_CHARGE &&
    info?.type !== TransactionTypes.LPF_AGENT_CREDIT && info?.type !== TransactionTypes.INITIALRATE) {
    return <VoidButton handleClick={() => item(info)} />;
  } else {
    return '  ';
  }
};

export const handleGetTypes = (data) => {
  return data?.type === 'CUSTOMERPAYMENT' ? 'Customer Payment' :
    data?.type === 'CARRIERPAYMENT' ? 'Carrier Payment' :
      data?.type === 'RATEADJUSTMENT' ? 'Rate Adjustment' :
        data?.type === 'DELIVER_FEE' ? 'Deliver Fee' :
          data?.type === 'PICK_UP_FEE' ? 'Pickup Fee' :
            data?.type === 'DRIVERASSIST' ? 'Driver Assist' :
              data?.type === 'WAREHOUSING' ? 'Warehousing' :
                data?.type === 'INITIALRATE' ? 'Initial Rate' :
                  data?.type === 'LATEFEE' ? 'Late fee' :
                    data?.type === 'SHORTPAY' ? 'Short Pay' :
                      data?.type === 'COLLECTIONAPPLIED' ? 'Collection Applied' :
                        data?.type === 'QUICKPAY' ? 'Quick Pay' :
                          data?.type === 'QUICKPAY_REVERSAL' ? 'Quickpay Reversal' :
                            data?.type === 'FUEL_FEE' ? 'Fuel advance fee' :
                              data?.type === 'CHASSIS_FEE' ? 'Chassis Fee' :
                                data?.type === 'DROP_FEE' ? 'Drop Fee' :
                                  data?.type === 'PRE_PULL' ? 'Pre-Pull' :
                                    data?.type === 'PIER_PASS' ? 'Pier Pass (CTF/TMF) fee' :
                                      data?.type === 'CONTAINER_STORAGE' ? 'Container Storage' :
                                        data?.type === 'CHASSIS_SPLIT' ? 'Chassis Split' :
                                          data?.type === 'OFFICE_CHARGE' ? 'Office Transaction' :
                                            data?.type === 'CORPORATE' ? 'Corporate Transaction' :
                                              data?.type === 'LPF_CARRIER_CHARGE' ? 'LPF Charge' :
                                                data?.type === 'LPF_REVERSAL' ? 'LPF reversal' :
                                                  data?.type === 'LPF_CARRIER_CREDIT' ? 'LPF Credit' :
                                                    data?.type === 'LPF_AGENT_CHARGE' ? 'LPF Agent Charge' :
                                                      data?.type === 'LPF_AGENT_CREDIT' ? 'LPF Agent Credit' :
                                                        data?.type === 'QUICKPAY' ? 'Quickpay' :
                                                          data?.type === 'EXTRA_TO_RATE' ? 'Extra to rate' :
                                                            data?.type === 'DUTIES_AND_FEES' ? 'Duties and fees' :
                                                              data?.type === 'ENTRY_SERVICE_FEE' ? 'Entry service fee' :
                                                                data?.type === 'SINGLE_BOND_FEE' ? 'Single bond fee' :
                                                                  data?.type === 'FDA_FEE' ? 'FDA fee' :
                                                                    data?.type === 'ISF_FEE' ? 'ISF fee' :
                                                                      data?.type?.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
                                                                        return g1.toUpperCase() + g2.toLowerCase();
                                                                      });
};


export const CORPORATE = 'CORPORATE';
export const EFS = 'EFS';
export const OFFICE_CHARGE = 'OFFICE_CHARGE';
export const FUEL = 'FUEL';
export const PROCESSING_REVERSAL = 'LPF_CARRIER_CREDIT';
export const QUICKPAY = 'QUICKPAY';


export const transactionType = userType === 'ADMIN' ?
  [
    { name: 'Rate Adjustment', id: 'RATEADJUSTMENT' },
    { name: 'Lumper', id: 'LUMPER' },
    { name: 'Deliver Fee', id: 'DELIVER_FEE' },
    { name: 'Pickup Fee', id: 'PICK_UP_FEE' },
    { name: 'Detention', id: 'DETENTION' },
    { name: 'Layover', id: 'LAYOVER' },
    { name: 'Late Fee', id: 'LATEFEE' },
    { name: 'Warehousing', id: 'WAREHOUSING' },
    { name: 'Driver Assistance', id: 'DRIVERASSIST' },
    { name: 'Short Pay', id: 'SHORTPAY' },
    { name: 'Palletizing', id: 'PALLETIZING' },
    { name: 'Drayage', id: 'DRAYAGE' },
    { name: 'Chassis Fee', id: 'CHASSIS_FEE' },
    { name: 'Drop Fee', id: 'DROP_FEE' },
    { name: 'Attempt', id: 'ATTEMPT' },
    { name: 'FSC', id: 'FSC' },
    { name: 'Pre-Pull', id: 'PRE_PULL' },
    { name: 'Per Diem', id: 'PRE_DIEM' },
    { name: 'Pier Pass (CTF/TMF) fee', id: 'PIER_PASS' },
    { name: 'Container Storage', id: 'CONTAINER_STORAGE' },
    { name: 'Demurrage', id: 'DEMURRAGE' },
    { name: 'Chassis Split', id: 'CHASSIS_SPLIT' },


    // { name: 'Processing Fee Charge', id: 'LPF_CARRIER_CHARGE' },

    ...sameTransactions,

    { name: 'Quickpay Reversal', id: 'QUICKPAY_REVERSAL', warn: true },
    { name: 'Quickpay', id: 'QUICKPAY', warn: true },
    { name: 'EFS', id: 'EFS', warn: true },
    { name: 'Fuel', id: 'FUEL', warn: true },
    { name: 'Office Transaction', id: 'OFFICE_CHARGE', warn: true },
    { name: 'Corporate Transaction', id: 'CORPORATE', warn: true },

  ]
  :
  [
    { name: 'Rate Adjustment', id: 'RATEADJUSTMENT' },
    { name: 'Detention / Layover', id: 'DETENTION' },
    { name: 'Late Fee', id: 'LATEFEE' },
    { name: 'Warehousing', id: 'WAREHOUSING' },
    { name: 'Driver Assistance', id: 'DRIVERASSIST' },

    { name: 'Palletizing', id: 'PALLETIZING' },
    { name: 'Drayage', id: 'DRAYAGE' },
    { name: 'Chassis Fee', id: 'CHASSIS_FEE' },
    { name: 'Drop Fee', id: 'DROP_FEE' },
    { name: 'Attempt', id: 'ATTEMPT' },
    { name: 'FSC', id: 'FSC' },
    { name: 'Pre-Pull', id: 'PRE_PULL' },
    { name: 'Per Diem', id: 'PRE_DIEM' },
    { name: 'Pier Pass (CTF/TMF) fee', id: 'PIER_PASS' },
    { name: 'Container Storage', id: 'CONTAINER_STORAGE' },
    { name: 'Demurrage', id: 'DEMURRAGE' },
    { name: 'Chassis Split', id: 'CHASSIS_SPLIT' },
    { name: 'Office Transaction', id: 'OFFICE_CHARGE' },
    // { name: 'Processing Fee Charge', id: 'LPF_CARRIER_CHARGE' },
    // { name: 'Processing Fee Reversal', id: 'LPF_CARRIER_CREDIT' },
    // {name: 'LPF reversal', id: 'LPF_REVERSAL'},

    ...sameTransactions,
  ];


export const otherTransactionHead = [
  { name: '', title: 'Created Date', icon: 'date', custom: false, width: '100px' },
  { name: '', title: 'Creator', custom: false },
  {
    name: 'otherType', title: 'Type',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Office Transaction', id: 'OFFICE_CHARGE' },
      { name: 'Corporate Transaction', id: 'CORPORATE' },

    ],
  },
  { name: '', title: 'Rate', custom: false },
  { name: 'qty', title: 'Qty', custom: false, width: '100px' },
  { name: '', title: 'Total', custom: false },
  { name: '', title: 'Description', custom: false },
  {
    name: 'otherVoid', title: 'Action',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Void', id: 'APPLIED' },
      { name: 'Voided', id: 'VOID' },
    ],
    width: '100px',
  },
];

export const otherTransactionBody = [
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.username} textWidth={10} /> },
  { rowText: (item) => <div>{handleGetTypes(item)}</div> },
  { rowText: (item) => <p className={renderColors(item)}><PriceRow info={item?.amount} /></p> },
  { rowText: (item) => <TextRow name={item?.qty ? item?.qty : 1} textWidth={10} /> },
  { rowText: (item) => <p className={renderColors(item)}><PriceRow info={item?.totalAmount} /></p> },
  { rowText: (item) => <TextRow name={item?.description} textWidth={13} /> },
  userType === 'ADMIN' && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.TRANSACTION_VOID_BUTTON?.code]) ?
    { button: (item, info, load) => renderButton(item, info, load), notClickable: true }
    :
    '',
];

export const TransactionTypes = {
  RATEADJUSTMENT: 'RATEADJUSTMENT',
  WAREHOUSING: 'WAREHOUSING',
  DRIVERASSIST: 'DRIVERASSIST',
  LATEFEE: 'LATEFEE',
  LUMPER: 'LUMPER',
  PICK_UP_FEE: 'PICK_UP_FEE',
  DELIVER_FEE: 'DELIVER_FEE',
  DETENTION: 'DETENTION',
  LAYOVER: 'LAYOVER',
  QUICKPAY_REVERSAL: 'QUICKPAY_REVERSAL',
  EXTRA_TO_RATE: 'EXTRA_TO_RATE',
  DUTIES_AND_FEES: 'DUTIES_AND_FEES',
  ENTRY_SERVICE_FEE: 'ENTRY_SERVICE_FEE',
  SINGLE_BOND_FEE: 'SINGLE_BOND_FEE',
  FDA_FEE: 'FDA_FEE',
  ISF_FEE: 'ISF_FEE',

  //system also can use these
  INITIALRATE: 'INITIALRATE',
  SHORTPAY: 'SHORTPAY',
  CLAIM: 'CLAIM',
  EFS: 'EFS',
  FUEL: 'FUEL',
  CUSTOMERPAYMENT: 'CUSTOMERPAYMENT',
  CARRIERPAYMENT: 'CARRIERPAYMENT',
  COLLECTIONAPPLIED: 'COLLECTIONAPPLIED',
  QUICKPAY: 'QUICKPAY',
  FUEL_FEE: 'FUEL_FEE',

  PALLETIZING: 'PALLETIZING',
  DRAYAGE: 'DRAYAGE',
  CHASSIS_FEE: 'CHASSIS_FEE',
  DROP_FEE: 'DROP_FEE',
  ATTEMPT: 'ATTEMPT',
  FSC: 'FSC',
  PRE_PULL: 'PRE_PULL',
  PRE_DIEM: 'PRE_DIEM',
  PIER_PASS: 'PIER_PASS',
  CONTAINER_STORAGE: 'CONTAINER_STORAGE',
  CHASSIS_SPLIT: 'CHASSIS_SPLIT',
  DEMURRAGE: 'DEMURRAGE',

  CORPORATE: 'CORPORATE',
  OFFICE_CHARGE: 'OFFICE_CHARGE',

  LPF_CARRIER_CHARGE: 'LPF_CARRIER_CHARGE',
  LPF_CARRIER_CREDIT: 'LPF_CARRIER_CREDIT',
  LPF_AGENT_CHARGE: 'LPF_AGENT_CHARGE',
  LPF_AGENT_CREDIT: 'LPF_AGENT_CREDIT',
};