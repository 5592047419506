import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {templateActions} from "store";
import {FindLoad} from "utils";
import {CustomBreadcrumbs, Loader} from "components";
import {TemplateCard} from "./templateById";
import {CreateLoadTable} from "./templateById/createLoadTable";

const ACTION_TYPE = 'GET_TEMPLATE_BY_ID'

export const TemplateInfo = ({}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const loader = FindLoad(ACTION_TYPE)

    useEffect(() => {
        dispatch(templateActions.getTemplateById(params?.id))
    }, [])

    if (loader?.length) {
        return <Loader/>
    }

    return (
        <div>
            <CustomBreadcrumbs
                parentLink={'/templates'}
                parent={'Load Templates'}
                child={'Create Template'}
            />
            <div className='template-card-section'>
                <TemplateCard/>
            </div>
            <div className='template-load-sections'>
                <CreateLoadTable/>
            </div>
        </div>
    )
}