import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Circle, CustomBreadcrumbs, Loader} from "components";
import {Colors, FindLoad, useGlobalText} from "utils";
import {equipmentActions} from "store";
import {EquipmentDetailInputs} from "./core/equipmentDetailInputs";
import {ZonesAndStates} from "./core";

const ACTION_TYPE = 'GET_EQUIPMENT_BY_ID'

export const EquipmentDetails = ({}) => {
    const {equipmentById} = useSelector((state) => ({
        equipmentById: state.equipment.equipmentById,
    }));
    const globalStyle = useGlobalText();
    const params = useParams();
    const dispatch = useDispatch()
    const loader = FindLoad(ACTION_TYPE)

    useEffect(() => {
        dispatch(equipmentActions.getEquipmentById(params?.id))
    }, [dispatch])

    if (loader?.length) return <Loader/>

    return (

        <div>
            <CustomBreadcrumbs
                parentLink={'/carriers'}
                parent={'Carriers'}
                paramsName={'paramsName'}
                child={'Equipment Details'}
                third={'Carrier Info'}
                thirdLink={`/carrier/${params?.carrierId}`}
            />
            <div className='equipment-details-page'>
                <Accordion>
                    <AccordionSummary
                        className={"accordion"}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="align-center">
                            <Circle number={1} back={Colors.TextPrimary}/>
                            <p className={globalStyle.title}>Equipment Details</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <EquipmentDetailInputs info={equipmentById}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        className={"accordion"}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="align-center">
                            <Circle number={2} back={Colors.TextPrimary}/>
                            <p className={globalStyle.title}>Zones and States</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ZonesAndStates/>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}