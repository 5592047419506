
export const ToastFail =(fail)=>{

    if(fail){
        return (
               fail === 'WENT_WRONG' ? 'Something went wrong' :
               fail === 'EDIT_OFFICE' ? 'Something went wrong' :
               fail === 'CREATE_OFFICE' ? 'Something went wrong' :
               fail === 'CREATE_BRANCH' ? 'Something went wrong' :
               fail === 'EDIT_BRANCH' ? 'Something went wrong' :
               fail === 'CREATE_ADMIN' ? 'Something went wrong' :
               fail === 'EDIT_ADMIN' ? 'Something went wrong' :
               fail === 'CREATE_CUSTOMER' ? 'Something went wrong' :
               fail === 'EDIT_CUSTOMER' ? 'Something went wrong' :
               fail === 'CREATE_FACTORING' ? 'Something went wrong' :
               fail === 'EDIT_FACTORING' ? 'Something went wrong' :
               fail === 'CREATE_MC' ? 'Something went wrong' :
               fail === 'EDIT_MC' ? 'Something went wrong' :
               fail === 'CREATE_CARRIER' ? 'Something went wrong' :
               fail === 'EDIT_CARRIER' ? 'Something went wrong' :
               fail === 'CREATE_AGENT' ? 'Something went wrong' :
               fail === 'EDIT_AGENT' ? 'Something went wrong' :
               fail === 'CREATE_OFFICE_MANAGERS' ? 'Something went wrong' :
               fail === 'SELECT_OFFICE_MANAGERS' ? 'Something went wrong' :
                fail === 'RESET_PASSWORD_REQUEST' ? 'Something went wrong' :
                fail === 'CREATE_UPLOAD' ? 'Something went wrong' :
                fail === 'EDIT_LOAD' ? 'Something went wrong' :
                fail === 'CREATE_LOAD' ? 'Something went wrong' :
                fail === 'REQUEST_TYPE' ? 'Something went wrong' :
                fail === 'NOT_CARRIER' ? 'For Submit load please fill carrier' :
                fail === 'DO_NOT_PAY' ?  'Something went wrong' :
                fail === 'ALLOW_FUEL' ?  'Something went wrong' :
                fail === 'NEGATIVE_TRANSACTION_FAIL' ?  'This transaction will make the load carrier rate negative, please enter a different amount or contact an admin.' :
                fail === 'TERMS_NOT_FOUND' ?  'Terms and condition was not found' :
                fail === 'CARRIER_FUEL' ?  'Card Number must be a number conforming to the specified constraints' :
                fail === 'RMIS_NOT_ACTIVATED' ?  'Rmis is inactive' :
                fail === 'REFRESH_RMIS' ?  'Could not find carrier in RMIS' :
                fail === 'CUSTOMER_PAYMENT_VOIDED' ?  'This load transaction has already been voided.' :
                fail === 'VOID_CARRIER_PAYMENT_FAIL' ? 'Something went wrong' :
                fail === 'CARRIER_AUTHORITY_EXIST' ? 'A Carrier with this Authority Number already exists.' :
                fail === 'VOID_CUSTOMER_PAYMENT_LOAD' ? 'Something went wrong, could not void load' :
                false
        )
    }
}