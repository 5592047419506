import { makeStyles } from '@material-ui/core/styles';
import { Colors, Shadow } from 'utils';

const drawerWidth = 100;
export const navBarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  transition: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '88px',
  },
  transitionOpen: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '180px',
    '@media (min-width: 1919px)': {
      marginLeft: '230px',
    },

  },

  appBar: {
    background: `${Colors.BackgroundWhite} 0% 0% no-repeat padding-box`,
    boxShadow: Shadow.menuShadow,
    opacity: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  IconButtonStyle: {
    height: '32px',
    width: '32px',
    marginTop: '90px',
    position: 'fixed',
    color: Colors.BackgroundWhite,
    border: `1px solid ${Colors.BackgroundWhite}`,
    zIndex: 2005,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    background: Colors.BackgroundDark,
    opacity: '1',
    width: '200px',
    zIndex: '2000 !important',
    overflowX: 'hidden',
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '@media (min-width: 1279px)': {
      width: '200px',
    },
    '@media (min-width: 1920px)': {
      width: '248px',
    },

  },
  drawerClose: {
    zIndex: '2000 !important',
    background: Colors.BackgroundDark,
    opacity: '1',
    width: '104px',
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  Toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  openToolbar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '200px',
    '@media (min-width: 1919px)': {
      marginLeft: '260px',
    },
  },
  notAndUser: {
    display: 'flex',
    alignItems: 'center',
  },
  closeToolbar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '100px',

    '@media (min-width: 1920px)': {
      marginLeft: '120px',
    },
  },
  content: {
    flexGrow: 1,
    margin: '90px 20px 24px 24px',
    paddingBottom: '40px',

    '@media (min-width: 1920px)': {
      margin: '90px 40px 24px 40px',
    },
  },

  headerContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  menuItems: {
    marginTop: '60px',
    '& .MuiListItemText-root': {
      margin: '0',
    },
    '& a': {
      textDecoration: 'none',
    },

    '& .MuiListItem-button': {
      '&:hover': {
        background: '#FFFFFF1A 0% 0% no-repeat padding-box',
        borderRadius: '4px',
      },
    },
    '& .MuiAccordion-rounded': {
      background: 'none',
      boxShadow: 'none',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px',
      minHeight: '36px',
      height: '36px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '36px',
      height: '36px',
    },
  },

  ActiveListItem: {
    borderRadius: '4px',
    marginTop: '24px',
    height: '36px',
  },
  ActiveListItemAccordian: {
    marginTop: '24px',
  },
  ListItem: {
    borderRadius: '4px',
    marginTop: '24px',
    height: '36px',
  },
  menuItemsStyle: {
    '& .MuiTypography-body1': {
      fontWeight: 'bold !important',
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0px',
      color: Colors.TextGray,
      opacity: '1',
      marginLeft: '12px!important',
      whiteSpace: 'break-spaces',
      '@media (min-width: 144px)': {
        whiteSpace: 'nowrap',
      },
    },
  },
  menuActiveItemsStyle: {
    '& .MuiTypography-body1': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 'bold !important',
      letterSpacing: '0px',
      color: Colors.TextWhite,
      opacity: '1',
      marginLeft: '12px!important',
      whiteSpace: 'break-spaces',
      '@media (min-width: 144px)': {
        whiteSpace: 'nowrap',
      },
    },
  },

  boxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  userInfo: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',

    '& img': {
      width: '40px',
      height: '40px',
      border: '1px solid white',
      borderRadius: '24px',
    },
  },

  userInfoText: {
    fontSize: '14px',
    lineHeight: '19px',
    color: Colors.TextSecondary,
    opacity: '1',
    paddingLeft: '8px',
  },

  logOutInfo: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '54px',
    '& img': {
      width: '18px',
      height: '18px',
    },
  },

  logOut: {
    textAlign: 'right',
    font: 'normal normal normal 12px/17px',
    letterSpacing: '0',
    color: '#FFFFFF',
    opacity: '1',
    paddingLeft: '8px',
  },

  imageBackActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    minWidth: '40px',
    height: '36px',
    background: '#FFFFFF1A 0% 0% no-repeat padding-box',
    borderRadius: '4px',

  },
  imageBackPassive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    minWidth: '40px',
    height: '36px',
    background: 'transparent',
    borderRadius: '4px',

  },

  wrapp: {
    '& .MuiListItem-gutters': {
      paddingLeft: '8px !important',
    },
  },

  leftSection: {
    background: '#FFFFFF0D 0% 0% no-repeat padding-box',
    width: '50px',
    height: '70em',
    position: 'fixed',
  },

  imageWidth: {
    width: '25px',
    height: '25px',
  },
  accordionDetails: {
    marginLeft: '50px',
    width: '80%',
  },
  countBox: {
    display:'flex',
    alignItems:'center',
    padding: '4px 5px',
    borderRadius: '40px',
    background: '#D72525',
    color: '#FFF',
    fontSize: '13px',
    fontWeight: '600',
    height: '18px'
  },
  countBoxItemLine:{
    marginTop: '-20px',
    position: 'absolute',
    marginLeft: '20px',
  }

}));
