import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    AddModalButton, AddressInput,
    CloseButton, MaskInput, SelectInput,
    ValidationInput
} from "components";
import {
    EmailValidator,
    ErrorText, FindErrorItem, FindLoad,
    FindSuccessItem,
    globalModals,
    useGlobalTextStyles
} from 'utils'
import {agentActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, officeActions,} from "store";
import {officesInfoFragments} from "./styles";

export const AddManagerModal = ({handleClose, info}) => {
    const {agentList, officeAgentList, officeBranchesList} = useSelector((state) => ({
        agentList: state.agents.agentList,
        officeAgentList: state.agents.officeAgentList,
        officeBranchesList: state.offices.officeBranchesList,
    }));
    const dispatch = useDispatch()
    const params = useParams()
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const classes = officesInfoFragments()
    const [inputs, setInputs] = useState(info ? info : {});
    const [error, setError] = useState('')
    const [selectedAgentList, setSelectedAgentList] = useState('')
    const [email, setEmail] = useState('')
    const [fullAddress, setFullAddress] = useState(null)
    const [unitNumber, setUnitNumber] = useState(null)
    const successSelect = FindSuccessItem('SELECT_OFFICE_MANAGERS')
    const successEdit = FindSuccessItem('EDIT_AGENT')
    const backError = FindErrorItem(info ? 'EDIT_AGENT' : 'CREATE_OFFICE_MANAGERS')
    const existUser = backError?.error === "User already exists"
    const existEmail = backError?.error === "An agent with this email exists"
    const loadCreate = FindLoad('CREATE_OFFICE_MANAGERS')
    const loadSelect = FindLoad('SELECT_OFFICE_MANAGERS')
    const loadEdit = FindLoad('EDIT_AGENT')
    const newList = agentList?.agents?.filter((e) => (e?.office?.id === params.id))
    const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error

    const filteredList = newList.filter(function (array_el) {
        return officeAgentList.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    useEffect(() => {
        if (info?.address) {
            if (info?.address?.unitNumber) {
                setUnitNumber(info?.address?.unitNumber)
            }
            setFullAddress(info?.address?.formattedAddress)
        }
        if(info?.id){
            setInputs({
                "email": info.email,
                "firstName": info.firstName,
                "lastName": info.lastName,
                "username": info?.username ? info.username : `${info?.firstName ? info.firstName : ''} ${info?.lastName ? info.lastName : ''}`,
                "phoneNumber": inputs?.phoneNumber ,
                "officeId": params.id,
                "branchId": inputs?.branch?.id,
            })
        }
    }, [info])

    const addManager = () => {
        const phoneNumber = inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + ''
        const data = {
            "email": inputs.email,
            "firstName": inputs.firstName,

            "lastName": inputs.lastName,
            "username": inputs?.username ? inputs.username : `${inputs?.firstName ? inputs.firstName : ''} ${inputs?.lastName ? inputs.lastName : ''}`,
            "phoneNumber": phoneNumber,
            "address": {
                address: fullAddress,
                unitNumber: unitNumber ? unitNumber : null,
            },
            "officeId": params.id,
            "branchId": inputs.branchId,
        }

        if (selectedAgentList) {
            const agentId = selectedAgentList
            const officeId = params.id
            dispatch(officeActions.selectOfficeManager(agentId, officeId))
        } else {
            if (!email.length) {
                if (inputs.firstName && inputs.lastName && inputs.email && inputs?.phoneNumber && inputs.branchId && fullAddress) {
                    if (info) {
                        dispatch(agentActions.editAgent(info.id, data))
                    } else {
                        dispatch(officeActions.createOfficeManager(data))
                    }
                } else {
                    setError(
                        !inputs.firstName ? 'firstName' :
                            !inputs.lastName ? 'lastName' :
                                !inputs.email ? 'email' :
                                    !inputs.phoneNumber ? 'phoneNumber' :
                                        !inputs.branchId ? 'branchId' :
                                            !fullAddress ? 'address' :
                                                ''
                    )
                }
            }
        }
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}))
        error === e.target.name && setError(''),
        e.target.name === 'email' && backError?.error &&
        dispatch(httpRequestsOnErrorsActions.removeError(info ? 'EDIT_AGENT' : 'CREATE_OFFICE_MANAGERS'))
    }

    const handleFullAddress = (ev) => {
            setFullAddress(ev?.address)
        // httpOnError.length && dispatch(httpRequestsOnErrorsActions.removeError('CREATE_OFFICE'))
        if (error === 'address') setError('')
    }

    const handleChangeFromList = (ev) => {
        setSelectedAgentList(ev.target.value)
    }

    useEffect(() => {
        if (successSelect) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('SELECT_OFFICE_MANAGERS'))
            handleClose()
        }
        if (successEdit) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_AGENT'))
            dispatch(agentActions.getAgentByOffice(params.id))
            handleClose()
        }
    }, [successSelect, successEdit]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(info ? 'EDIT_AGENT' : 'CREATE_OFFICE_MANAGERS'))
        }
    }, [])

    return (
        <div className={globalModalsClasses.smallModalWrapper}>
            <div className={globalModalsClasses.smallModalClose}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div className={globalModalsClasses.modalWrapperContent}>
                <p className={globalText.modalTitle}>{info ? 'Edit Office Manager Info' : 'Want to Add Office Manager?'}</p>
                <p className={globalText.modalText}>{!info && filteredList && filteredList.length ?
                    'Please select or fill out the fields below fields to add an Office Manager. '
                    :
                    'Please fill out the fields below fields to add an Office Manager. '
                }</p>
                {!info && filteredList && filteredList.length ?
                    <SelectInput
                        name={"agentList"}
                        label={"Select from Agent List"}
                        handleSelect={handleChangeFromList}
                        value={selectedAgentList}
                        list={filteredList}
                        type={'agent'}
                    /> : ''
                }
                {!selectedAgentList &&
                    <>
                        {!info && filteredList && filteredList.length ?
                            <p className={globalText.orText}>OR</p> : ''
                        }
                        <ValidationInput
                            onChange={handleChange}
                            value={inputs.firstName}
                            typeError={error === 'firstName' && ErrorText.field}
                            label={"First Name*"}
                            type={"text"}
                            variant={"outlined"}
                            name={"firstName"}
                        />
                        <ValidationInput
                            onChange={handleChange}
                            value={inputs.lastName}
                            typeError={error === 'lastName' && ErrorText.field}
                            variant={"outlined"}
                            name={"lastName"}
                            label={"Last Name*"}
                            type={"text"}
                        />
                        <ValidationInput
                            onChange={handleChange}
                            value={inputs.username ? inputs.username :
                                `${inputs?.firstName ? inputs.firstName : ''} ${inputs?.lastName ? inputs.lastName : ''}`
                        }
                            typeError={error === 'username' && ErrorText.field}
                            variant={"outlined"}
                            name={"username"}
                            label={"User Name"}
                            type={"text"}
                        />
                        <ValidationInput
                            validator={EmailValidator}
                            variant={"outlined"}
                            name={"email"}
                            type={"email"}
                            label={"Email Address*"}
                            typeError={
                                existUser === true ? "User already exists" :
                                    existEmail === true ? "An agent with this email exists" :
                                        error === 'email' ? ErrorText.field :
                                            email === 'Not valid email' ? 'Not valid email' :
                                                ''}
                            sendBoolean={(bool) => bool === true ? setEmail("Not valid email") : setEmail('')}
                            onChange={handleChange}
                            value={inputs.email}
                        />
                        <MaskInput
                            border={'black'}
                            styles={{marginRight: 0}}
                            name='phoneNumber'
                            label="Phone Number*"
                            value={inputs.phoneNumber}
                            handleChange={handleChange}
                            info={info}
                            error={
                                error === 'phoneNumber' ? ErrorText.field :
                                    errorText === 'phoneNumber must be a valid phone number' && ErrorText.phone}
                        />
                        <SelectInput
                            style={classes.selectBranchInput}
                            name={"branchId"}
                            label={"Branch*"}
                            handleSelect={handleChange}
                            value={inputs?.branch?.id || inputs?.branchId}
                            list={officeBranchesList?.branches?.length ? officeBranchesList?.branches : []}
                            typeError={error === 'branchId' ? ErrorText.field : ''}
                            type={'id'}
                        />
                        <AddressInput
                            noBlue={true}
                            info={info}
                            oneInput={true}
                            errorBoolean={error === 'address' ? 'Input is not field' : ''}
                            handleSelectValue={handleFullAddress}
                        />
                        <ValidationInput
                            style={classes.suiteInput}
                            onChange={(e) => setUnitNumber(e.target.value)}
                            value={unitNumber}
                            typeError={error === 'unitNumber' && ErrorText.field}
                            variant={"outlined"}
                            name={"unitNumber"}
                            label={"Suite"}
                            type={"text"}
                        />
                    </>
                }
                <AddModalButton
                    loading={!!loadCreate?.length || !!loadSelect?.length || !!loadEdit?.length}
                    styles={{marginTop: '26px'}}
                    handleClick={addManager}
                    text={info ? 'Save' : 'Add'}
                />
            </div>
        </div>
    )
}