import React from 'react';
import { paginationStyle } from './style';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';

export const PaginationItem = ({ count, handleReturn, listInfo, styles, page, loader }) => {
  const classes = paginationStyle();
  const limitCountNumber = 100;
  const history = useHistory();
  const info = history?.location?.state;
  // const page =  info?.page ? info?.page : 1;
  const firsCountNumber = page ? page <= 1 ? 1 : (page - 1) * limitCountNumber + 1 : 1;
  const lastCount = page ? +((page - 1) * 100) + listInfo?.length : listInfo?.length;
  const handleChangePage = (val) => {
    handleReturn(val);
  };

  return (
    <div style={{ ...styles }} className={classes.PaginationWrapper}>
          <div>
            <p className={classes.showCountText}>
              {`Showing ${firsCountNumber ? firsCountNumber : 0} to ${lastCount ? lastCount : 0} of ${count ? count : 0} entries`}
            </p>
          </div>
          <Pagination
            onChange={(event, val) => handleChangePage(val)}
            page={info?.page ? info?.page : 1}
            count={count && Math.ceil(count / limitCountNumber)}
            color={'primary'}
          />
    </div>
  );
};
