import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequestsOnSuccessActions, loadActions, loadAuthActions } from 'store';
import { CsvFile, DateFilters, FullTable, PriceConvertor, TableWrapper } from 'components';
import { PermissionsList, RolePermission, useGlobalStyles, useModal } from 'utils';
import { ACTION_TYPE, RECEIVABLE, receivablesBody, receivablesCsv, receivablesHead } from './constants';
import { TotalItems } from './core/totalItems';

export const Receivables = ({}) => {
  const { loadsList, receivablesInfo } = useSelector((state) => ({
    loadsList: state.loads.loadsList,
    receivablesInfo: state.loads.receivablesInfo,
  }));
  const dispatch = useDispatch();
  const classes = useGlobalStyles();
  const history = useHistory();
  const info = history?.location?.state;
  const { open } = useModal();
  const body = receivablesBody(open);

  useEffect(() => {
    dispatch(loadActions.getReceivablesInfo());
  }, []);

  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  };

  useEffect(() => {
    handleGet('loading');
    const interval = setInterval(() => {
      handleGet('noLoad');
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);

  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
    dispatch(loadActions.getLoadsByStatus({ ...filteredInfo, department: RECEIVABLE }, loader));
  };

  const handleSubmit = async (info, it) => {
    if (it === 'verify') {
      dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', info.id));
      try {
        await loadAuthActions.verifyBilling(info.id);
        handleGet('noLoad');
        dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD', info.id));
        dispatch(httpRequestsOnSuccessActions.appendSuccess('BILLING_VERIFY'));
      } catch (e) {
        dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD', info.id));
      }
    }
  };

  const pushPageDetails = (id) => {
    history.push({
      pathname: `/load/${id}`,
      state: { loadParams: { ...info }, parent: '/receivables', detail: 'billing' },
    });
  };

  return (
    <div>
      <div className={classes.filtersWrapper}>
        <DateFilters status={RECEIVABLE} />
        {RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.EXPORT_RECEIVABLE_CSV?.code]) &&
          <CsvFile params={receivablesCsv} />
        }
      </div>

      <TableWrapper
        buttonsTab={true}
        link={true}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        {receivablesInfo &&
          <div className={classes.totalWrapper}>
            <div className={classes.centerItem}>
              <p className={classes.totalTitle}>Totals</p>

             <div className={classes.receivablesTotalsBox}>
              <TotalItems
                text={<PriceConvertor price={receivablesInfo?.current?.amount} />}
                count={` (${receivablesInfo?.current?.count})`}
                title={'Current:'}
                color={'#00C8511A'}
              />
              <TotalItems
                text={<PriceConvertor price={receivablesInfo?.dueNow?.amount} />}
                count={` (${receivablesInfo?.dueNow?.count})`}
                title={'Due Now:'}
                color={'#438AFE1A'}
              />
              <TotalItems
                text={<PriceConvertor price={receivablesInfo?.pastDue?.amount} />}
                count={` (${receivablesInfo?.pastDue?.count})`}
                title={'Past Due:'}
                color={'#FFA3301A'}
              />
              <TotalItems
                text={<PriceConvertor price={receivablesInfo?.urgent?.amount} />}
                title={'Urgent:'}
                count={` (${receivablesInfo?.urgent?.count})`}
                color={'#D725251A'}
              />
             </div>

            </div>
            <div>
              <TotalItems
                text={<PriceConvertor price={receivablesInfo?.grandTotal?.amount} />}
                count={` (${receivablesInfo?.grandTotal?.count})`}
                title={'Total: '}
                color={'#E6ECF380'}
                styles={{marginRight: 0}}
              />
            </div>
          </div>
        }
        <FullTable
          head={receivablesHead}
          body={body}
          loadingType={ACTION_TYPE}
          list={loadsList?.loads}
          listCount={loadsList?.count}
          handleClick={pushPageDetails}
          noText={'Receivables'}
          handleClickButton={handleSubmit}
        />
      </TableWrapper>
    </div>
  );
};