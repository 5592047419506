import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {AddButton, Circle, FullTable, Line, PriceConvertor} from "components";
import {Colors, PermissionsList, RolePermission, SavePage, useGlobalStyles, useGlobalText, useModal} from "utils";
import {CustomDeleteModal} from "fragments";
import {transactionBody, transactionHead, ACTION_TYPE, transactionStyles, AddTransactionModal} from "./core";
import {authService} from "../../../store/loads/loads.service";
import {httpRequestsOnLoadActions, httpRequestsOnSuccessActions, loadActions} from "store";
import {
    CARRIER_TXNS_ACTION_TYPE,
    carrierTransactionHead,
    OTHER_TXNS_ACTION_TYPE, otherTransactionBody, otherTransactionHead,
    transactionCarrierBody
} from "./core/constants";
import {CANCELLED} from "../../../pages/accounting/constants";
import { LoadService } from "../load.service";

const VOID_TYPE = 'TRANSACTION_VOID'

export const Transactions = ({load}) => {
    const {loadTransactions, loadCarrierTxns, loadOtherTxns} = useSelector((state) => ({
        loadTransactions: state.loads.loadTransactions,
        loadCarrierTxns: state.loads.loadCarrierTxns,
        loadOtherTxns: state.loads.loadOtherTxns,
    }));
    const dispatch = useDispatch()
    const globalStyles = useGlobalStyles();
    const globalText = useGlobalText();
    const classes = transactionStyles()
    const checkLoadTxnsCreation  =
                               load?.status === 'PROCESSING' ? true :
                                 load?.status === 'INTRANSIT' ? true :
                                   load?.status === 'COMPLETED'


    const history = useHistory()
    const pushInfo = history?.location?.state
    const {open, close} = useModal()
    const otherTransactions = loadOtherTxns?.txns?.filter((i) => i.onModel === 'OFFICE' || i.onModel === "CORP")

    useEffect(() => {
        const txnsInfo = {
            ...pushInfo
        }
        delete txnsInfo.loadParams
        delete txnsInfo.carrierVoid
        delete txnsInfo.carrierType
        delete txnsInfo.activeTab
        delete txnsInfo.page
        delete txnsInfo.limit
        delete txnsInfo.skip
        dispatch(loadActions.getLoadTransactions(load?.id, '', txnsInfo))

        const carrierTxnsInfo = {
            ...pushInfo
        }
        delete carrierTxnsInfo.loadParams
        delete carrierTxnsInfo.activeTab
        delete carrierTxnsInfo.page
        delete carrierTxnsInfo.limit
        delete carrierTxnsInfo.skip

        if (pushInfo?.carrierType) {
            carrierTxnsInfo.type = pushInfo?.carrierType
            delete carrierTxnsInfo.carrierType

        } else {
            delete carrierTxnsInfo.type
        }

        if (pushInfo?.carrierVoid) {
            carrierTxnsInfo.status = pushInfo?.carrierVoid
            delete carrierTxnsInfo.carrierVoid
        } else {
            delete carrierTxnsInfo.status
        }
        dispatch(loadActions.getLoadCarrierTransactions(load?.id, '', carrierTxnsInfo))


        const otherTxnsInfo = {
            ...pushInfo
        }
        delete otherTxnsInfo.loadParams
        delete otherTxnsInfo.activeTab
        delete otherTxnsInfo.page
        delete otherTxnsInfo.limit
        delete otherTxnsInfo.skip

        if (pushInfo?.otherType) {
            otherTxnsInfo.type = pushInfo?.otherType
            delete otherTxnsInfo.otherType
        } else {
            delete otherTxnsInfo.type
        }

        if (pushInfo?.otherVoid) {
            otherTxnsInfo.status = pushInfo?.otherVoid
            delete otherTxnsInfo.otherVoid
        } else {
            delete otherTxnsInfo.status
        }

        dispatch(loadActions.getLoadOtherTransactions(load?.id, '', otherTxnsInfo))
    }, [pushInfo])

    const handleOpenModal = (info) => {
        if (load?.status !== CANCELLED) {
            open(
                <CustomDeleteModal
                    actionType={VOID_TYPE}
                    type={'void'}
                    text={'Void Transaction?'}
                    handleDel={() => handleVoid(info)}
                />
            )
        }
    }

    const handleVoid = async (info) => {
        dispatch(httpRequestsOnSuccessActions.removeSuccess(VOID_TYPE))
        dispatch(httpRequestsOnLoadActions.appendLoading(VOID_TYPE))
        try {
            await authService.editLoadTransactionsService(info?.load, info?.id)
            dispatch(loadActions.getLoadById(info?.load, 'noLoad'))
            close()
            dispatch(httpRequestsOnLoadActions.removeLoading(VOID_TYPE))
            dispatch(httpRequestsOnSuccessActions.appendSuccess(VOID_TYPE))
            SavePage(history, pushInfo, {...pushInfo})
        } catch (e) {
            close()
            dispatch(httpRequestsOnLoadActions.removeLoading(VOID_TYPE))
            SavePage(history, pushInfo, {...pushInfo})
        }
    }

    return (
        <div>
            <div>
                <div className={classes.addSection}>
                    {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.ADD_TRANSACTION?.code], 'agentView') && checkLoadTxnsCreation &&
                        <AddButton
                            handleClick={() => open(<AddTransactionModal info={load}/>)}
                            text={'Add Transaction'}
                        />
                    }
                </div>
                <div className={globalStyles.centerItem}>
                    <div>
                        <div className={globalStyles.flexAble}>
                            <Circle number={1} back={Colors.ThemeAqua}/>
                            <p className={globalText.title}>Totals</p>
                        </div>
                        <div className={classes.lineAndTable}>
                            <Line height={'460px'}/>
                            <div>
                                <div style={{width: '100%'}} className={classes.itemsWrapper}>
                                    <p className={classes.title}>Load Profit</p>
                                    <div className={globalStyles.flexAble}>
                                        <div className={classes.items}>
                                            <p>Total: </p>
                                            <span>
                                                  <PriceConvertor price={load?.customerRate - load?.carrierRate - load?.agentCharges}/>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{width: '100%'}} className={classes.itemsWrapper}>
                                    <p className={classes.title}>Customer Totals</p>
                                    <div className={globalStyles.flexAble}>
                                        <div className={classes.items}>
                                            <p>Rate Total: </p>
                                            <span>
                                                <PriceConvertor price={load?.customerRate}/>
                                            </span>
                                        </div>
                                        <div className={classes.items}>
                                            <p>Paid Total:</p>
                                            <span>
                                                  <PriceConvertor price={load?.customerPaid}/>
                                            </span>
                                        </div>
                                        <div className={classes.items}>
                                            <p>Corporate:</p>
                                            <span>
                                                  <PriceConvertor price={load?.bill?.corporateCharges}/>
                                            </span>
                                        </div>
                                        <div style={{margin: 0}} className={classes.items}>
                                            <p>Balance: </p>
                                            <span>
                                                  <PriceConvertor price={LoadService.getBillBalance(load?.bill)}/>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{width: '100%'}} className={classes.itemsWrapper}>
                                    <p className={classes.title}>Carrier Total</p>
                                    <div className={globalStyles.flexAble}>
                                        <div className={classes.items}>
                                            <p>Rate Total: </p>
                                            <span>
                                                  <PriceConvertor price={load?.carrierRate}/>
                                            </span>
                                        </div>
                                        <div className={classes.items}>
                                            <p>Carrier Charges: </p>
                                            <span>
                                                <PriceConvertor
                                                    price={load?.carrierCharges ? (load?.carrierCharges * 100) / 100 : 0}/>
                                            </span>
                                        </div>
                                        <div className={classes.items}>
                                            <p>Paid Total:</p>
                                            <span>
                                               <PriceConvertor price={load?.carrierPaid}/>
                                            </span>
                                        </div>
                                        <div className={classes.items}>
                                            <p>Corporate:</p>
                                            <span>
                                                  <PriceConvertor price={load?.payable?.corporateCharges}/>
                                            </span>
                                        </div>
                                        <div style={{margin: 0}} className={classes.items}>
                                            <p>Balance: </p>
                                            <span>
                                                <PriceConvertor
                                                    price={LoadService.getPayableBalance(load?.payable)}/>
                                            </span>
                                        </div>
                                        
                                    </div>
                                    <div className={classes.collections}>
                                        <div className={classes.items}>
                                            <p>Collections:</p>
                                            <span>
                                                  <PriceConvertor price={load?.payable?.collections}/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={globalStyles.centerItem}>
                    <div className={globalStyles.spaceBetween}>
                        <div className={globalStyles.flexAble}>
                            <Circle number={2} back={Colors.ThemeAqua}/>
                            <p className={globalText.title}>Customer Transactions</p>
                        </div>
                    </div>
                </div>
                <div className={classes.lineAndTable}>
                    <Line height={'auto'}/>
                    <FullTable
                        height={'medium'}
                        head={transactionHead}
                        body={transactionBody}
                        loadingType={ACTION_TYPE}
                        list={loadTransactions?.txns?.filter((i) => i.onModel === 'CUSTOMER')}
                        noText={'Customer Transaction'}
                        handleClickButton={handleOpenModal}
                    />
                </div>
            </div>
            <div style={{marginTop: '50px'}}>
                <div className={globalStyles.centerItem}>
                    <div className={globalStyles.spaceBetween}>
                        <div className={globalStyles.flexAble}>
                            <Circle number={3} back={Colors.ThemeAqua}/>
                            <p className={globalText.title}>Carrier Transactions</p>
                        </div>
                    </div>
                </div>
                <div className={classes.lineAndTable}>
                    <Line height={'auto'}/>
                    <FullTable
                        height={'medium'}
                        head={carrierTransactionHead}
                        body={transactionCarrierBody}
                        loadingType={CARRIER_TXNS_ACTION_TYPE}
                        list={loadCarrierTxns?.txns?.filter((i) => i.onModel === 'CARRIER')}
                        noText={'Carrier Transaction'}
                        handleClickButton={handleOpenModal}
                    />
                </div>
            </div>


            {otherTransactions?.length ?
                <div style={{marginTop: '50px'}}>
                    <div className={globalStyles.centerItem}>
                        <div className={globalStyles.spaceBetween}>
                            <div className={globalStyles.flexAble}>
                                <Circle number={4} back={Colors.ThemeAqua}/>
                                <p className={globalText.title}>Other Transactions</p>
                            </div>
                        </div>
                    </div>
                    <div style={{width: 'auto'}} className='info-table-no-line'>
                        <FullTable
                            height={'medium'}
                            head={otherTransactionHead}
                            body={otherTransactionBody}
                            loadingType={OTHER_TXNS_ACTION_TYPE}
                            list={loadOtherTxns?.txns?.filter((i) => i.onModel === 'OFFICE' || i.onModel === "CORP")}
                            noText={'Other Transaction'}
                            handleClickButton={handleOpenModal}
                        />
                    </div>
                </div>
                :
                ''
            }
        </div>
    )
}