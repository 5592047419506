import {customerPaymentInfo} from "./core/styles";
import {
    AddCircle,
    Circle,
    CloseBill,
    DeleteElement, DocumentsComponent, EditSaveButtons,
    Line,
    SelectInput,
    SimpleModal, ValidationInput, VoidedButton
} from "components";
import {
    Colors,
    ErrorText, FindError,
    FindErrorItem,
    FindSuccessItem,
    PermissionsList,
    RolePermission,
    useGlobalStyles,
    useGlobalText
} from "utils";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {checkStatus, paymentMethods} from "../core/constants";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import {
    customerPaymentActions,
    httpRequestsOnErrorsActions,
    httpRequestsOnLoadActions,
    httpRequestsOnSuccessActions,
} from "store";
import {Customers} from "./core";
import {authService} from "../../../store/customerPayment/customerPayment.service";
import {AddLoad} from "../core";

const REQUEST_TYPE = 'EDIT_CUSTOMER_PAYMENT'
const ADD_PMT_DOCK = 'ADD_PMT_DOCK'
const DELETE_PMT_DOCK = 'DELETE_PMT_DOCK'
const CUSTOMER_PAYMENT_VOIDED = 'CUSTOMER_PAYMENT_VOIDED'

export const CustomerPaymentInfoFragment = ({info, customerPaymentLoad}) => {
    const classes = customerPaymentInfo()
    const globalStyles = useGlobalStyles()
    const globalText = useGlobalText()
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [inputs, setInputs] = useState({})
    const [error, setError] = useState({})
    const [selectType, setSelectType] = useState('FACTORING')
    const [disables, setDisabled] = useState(true)
    const [open, setOpen] = useState(false)
    const success = FindSuccessItem(REQUEST_TYPE)
    const backError = FindErrorItem(REQUEST_TYPE)
    const [loadVoid, setLoadVoid] = useState(false)
    const [addLoad, setAddLoad] = useState(false)
    const [docs, setDocks] = useState([])
    const voidBackError = FindError(CUSTOMER_PAYMENT_VOIDED)

    useEffect(() => {
        if (info && info?.documents) {
            setDocks(info.documents)
        }
    }, [info])

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE))
            setDisabled(true)
        }
    }, [success])

    useEffect(() => {
        if (info) {
            setSelectType(info ? info.factoringName ? 'FACTORING' : 'CUSTOMER' : 'FACTORING')
            setInputs({
                amount: info.amount,
                pmtDate: moment(info.pmtDate).format('YYYY-MM-DD'),
                pmtMethod: info.pmtMethod,
                reference: info.reference,
                factoring: info.factoringId ? info.factoringId : ''
            })
        }
    }, [info])

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}),)
        error === e.target.name && setError('')
    }

    const handleEdit = () => {
        if (inputs.pmtDate && inputs.reference && inputs.pmtMethod && inputs.amount) {
            const date = {
                amount: +inputs.amount,
                pmtDate: inputs.pmtDate,
                pmtMethod: inputs.pmtMethod,
                reference: inputs.reference,
            }
            dispatch(customerPaymentActions.editCustomerPayment(date, info.id, 'noLoad'))
        } else {
            setError(
                !inputs.pmtDate ? 'pmtDate' :
                    !inputs.reference ? 'reference' :
                        !inputs.pmtMethod ? 'pmtMethod' :
                            !inputs.amount ? 'amount' :
                                ''
            )
        }
    }

    const handleOpenCloseDelModal = () => {
        setOpen(!open)
    }

    const handleVoidPayment = async () => {
        dispatch(httpRequestsOnErrorsActions.removeError(CUSTOMER_PAYMENT_VOIDED ))
        setLoadVoid(true)
        try {
            await authService.voidCustomerService(info.id)
            setLoadVoid(false)
            dispatch(httpRequestsOnSuccessActions.appendSuccess('VOID_CUSTOMER_PAYMENT_SUCCESS'))
            history.push('/customerPayments')
            setOpen(false)
        } catch (e) {
            dispatch(httpRequestsOnErrorsActions.appendError(CUSTOMER_PAYMENT_VOIDED ))
            setLoadVoid(false)
            setOpen(false)
        }
    }

    const handleChancel = () => {
        dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE))
        setDisabled(true)
        setError('')
        setInputs({
            amount: info.amount,
            pmtDate: moment(info.pmtDate).format('YYYY-MM-DD'),
            pmtMethod: info.pmtMethod,
            reference: info.reference,
            factoring: info.factoringId ? info.factoringId : ''
        })
    }

    const handleAddDocument = async (img) => {
        try {
            await authService.addCustomerPaymentDocument(img, info.id)
            dispatch(httpRequestsOnLoadActions.removeLoading(ADD_PMT_DOCK))
            dispatch(customerPaymentActions.getCustomerPaymentById(params.id, 'noLoad'))
        } catch (e) {
            dispatch(httpRequestsOnLoadActions.removeLoading(ADD_PMT_DOCK))
        }
    }

    const handleDeleteFile = async (doc) => {
        try {
            dispatch(httpRequestsOnLoadActions.appendLoading(DELETE_PMT_DOCK))
            await authService.deleteCustomerPaymentDocument(info.id, doc?.id)
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_PMT_DOCK))
            dispatch(httpRequestsOnSuccessActions.appendSuccess(DELETE_PMT_DOCK))
            dispatch(customerPaymentActions.getCustomerPaymentById(params.id, 'noLoad'))
        } catch (e) {
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_PMT_DOCK))
        }
    }

    const handleSetDocStatus = async (doc) => {
        try {
            dispatch(httpRequestsOnLoadActions.appendLoading(DELETE_PMT_DOCK))
            const info = {
                status: doc.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
            }
            await authService.setPmtDocumentStatus(params?.id, doc.id, info)
            dispatch(customerPaymentActions.getCustomerPaymentById(params.id, 'noLoad'))
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_PMT_DOCK))
            dispatch(httpRequestsOnSuccessActions.appendSuccess(DELETE_PMT_DOCK))
        } catch (e) {
            dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_PMT_DOCK))
        }
    }

    return (
        <div>
            <div>
                <div className={globalStyles.spaceBetween}>
                    <div style={{width: '100%'}} className={globalStyles.centerItem}>
                        <Circle number={1} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Payment Info</p>
                    </div>
                    <div className={globalStyles.flexEnd}>
                        {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.VOID_CUSTOMER_PAYMENT?.code]) &&
                            info && checkStatus(info) &&
                            <CloseBill
                                handleCLick={() => handleOpenCloseDelModal('voidPayment')}
                                text={'Void'} width={'77px'} right={'16px'}
                            />
                        }
                        {info && !checkStatus(info) ?
                            <VoidedButton/>
                            :
                            RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_CUSTOMER_PAYMENT?.code]) &&
                            <EditSaveButtons
                                type={REQUEST_TYPE}
                                handleChancel={handleChancel}
                                handleSetEdit={() => setDisabled(false)}
                                handleSaveInfo={handleEdit}
                            />
                        }
                    </div>
                </div>

                <div className={globalStyles.flexAndFullWidth}>
                    <div className={'MuiAccordionDetails-root'}>
                        <Line height={'180px'}/>
                    </div>
                    <div className={globalStyles.fullWidth}>
                        <div className={classes.spaceBetween}>
                            <ValidationInput
                                className={info && globalStyles.inputTextFieldBlue}
                                disabled={disables}
                                style={globalStyles.simpleInput}
                                variant={"outlined"}
                                onChange={handleChange}
                                value={inputs.pmtDate}
                                type={"date"}
                                label={"Date*"}
                                name='pmtDate'
                                typeError={error === 'pmtDate' && ErrorText.field}
                            />
                            <ValidationInput
                                className={info && globalStyles.inputTextFieldBlue}
                                disabled={disables}
                                style={globalStyles.simpleInput}
                                variant={"outlined"}
                                name={"reference"}
                                label={"Reference Number*"}
                                type={'text'}
                                typeError={error === 'reference' ? ErrorText.field : ''}
                                onChange={handleChange}
                                value={inputs.reference}
                            />
                            <SelectInput
                                className={info && globalStyles.inputTextFieldBlue}
                                disabled={disables}
                                style={globalStyles.simpleInput}
                                name={"pmtMethod"}
                                label={"Payment Method*"}
                                handleSelect={handleChange}
                                value={inputs.pmtMethod}
                                list={paymentMethods}
                                typeError={error === 'pmtMethod' ? ErrorText.field : ''}
                                type={'id'}
                            />
                            <ValidationInput
                                className={info && globalStyles.inputTextFieldBlue}
                                disabled={disables}
                                variant={"outlined"}
                                name={"amount"}
                                label={"Amount*"}
                                type={'number'}
                                typeError={error === 'amount' ? ErrorText.field :
                                    backError?.error === 'The used amount from this pmt is higher than the total pmt amount you are trying to set' ? 'The used amount from this pmt is higher than the total pmt amount you are trying to set' :
                                        ''}
                                onChange={handleChange}
                                value={inputs.amount}
                            />
                        </div>
                        <FormControl disabled={true} className={classes.checkbox}>
                            <FormLabel className={classes.title} id="demo-row-radio-buttons-group-label">This payment is
                                from:</FormLabel>
                            <RadioGroup
                                onChange={(e) => setSelectType(e.target.value)}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="selectType"
                                value={selectType}
                            >
                                <FormControlLabel
                                    defaultChecked={selectType === 'FACTORING'}
                                    value="FACTORING"
                                    control={<Radio/>}
                                    label="Factoring Comapny"
                                />
                                <FormControlLabel
                                    defaultChecked={selectType === 'CUSTOMER'}
                                    value="CUSTOMER"
                                    control={<Radio/>}
                                    label="Customer"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>

            <div>
                <div className={globalStyles.spaceBetween}>
                    <div className={globalStyles.centerItem}>
                        <Circle number={2} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Loads</p>
                    </div>

                    <AddCircle
                        handleCLic={() => setAddLoad(!addLoad)}
                        text={"Add Load"}
                    />
                </div>
                <div className={globalStyles.flexAndFullWidth}>
                    <div className={'MuiAccordionDetails-root'}>
                        <Line height={'auto'} minHeight={'200px'}/>
                    </div>
                    <Customers info={customerPaymentLoad}/>
                </div>
            </div>

            <div>
                <div className={globalStyles.centerItem}>
                    <Circle number={3} back={Colors.ThemeAqua}/>
                    <p className={globalText.title}>Documents</p>
                </div>

                <div className={classes.customerPaymentDock}>
                    <DocumentsComponent
                        actionType={ADD_PMT_DOCK}
                        removeActionType={DELETE_PMT_DOCK}
                        handleDeleteFile={handleDeleteFile}
                        handleUpload={handleAddDocument}
                        handleSwitch={handleSetDocStatus}
                        documents={docs}
                    />
                </div>
            </div>

            <SimpleModal
                handleOpenClose={() => setAddLoad(!addLoad)}
                openDefault={addLoad}
                content={
                    <AddLoad
                        loadDate={info}
                        closeModal={() => setAddLoad(!addLoad)}
                        handleOpenClose={() => setAddLoad(!addLoad)}
                    />
                }
            />

            <SimpleModal
                handleOpenClose={handleOpenCloseDelModal}
                openDefault={open}
                content={
                    <DeleteElement
                        loading={loadVoid}
                        type={'void'}
                        text={'Are you sure you want to void this payment?'}
                        handleClose={handleOpenCloseDelModal}
                        handleDel={handleVoidPayment}
                        info={'Bill'}
                        modalText={'This action will undo everything that payment application caused.'}
                    />
                }
            />
        </div>
    )
}