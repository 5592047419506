import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {DateRow, FullTable, Loader, PaginationItem, PriceRow, SearchAndFilter, TableNoInfo, TextRow} from "components";
import {FindLoad, SendPageSave, useGlobalStyles} from "utils";
import {payrollActions} from "store";
import {headList, loadPayrollBody, loadPayrollHead} from "./core/constants";
import { GET_PAYROLL_LOADS } from '../../store/payroll/payroll.types';
import { filterFromTable } from '../../components/table/constants';

export const LoadPayroll = ({}) => {
    const {payrolls, payrollLoads} = useSelector((state) => ({
        payrolls: state.payroll.payrolls,
        payrollLoads: state.payroll.payrollLoads,
    }));
    const dispatch = useDispatch()
    const history = useHistory()
    const info = history?.location?.state
    const globalStyle = useGlobalStyles();
    const loader = FindLoad('GET_PAYROLL_LOADS')
    const [expanded, setExpanded] = React.useState(null);
    const [list, setList] = useState([])
    const [searched, setSearched] = useState({ name: '', text: '' });

    useEffect(() => {
        if (payrollLoads?.loads?.length) {
            setList(payrollLoads?.loads)
        }
    }, [payrollLoads])

    const handleChange = (id) => {
        if (id === expanded) {
            setExpanded(null);
        } else {
            dispatch(payrollActions.getPayrolls({load: id}))
            setExpanded(id);
        }
    };

    const handleSearch = (e, nameRow, searchKey, type) => {
        setSearched({
            name: e.target.name,
            value: e.target.value,
        });

        if (e.target.name) {
            if (!e.target.value) {
                setList(payrollLoads?.loads)
            } else {
                const info = filterFromTable(e, nameRow, list, type);
                setList(info);
            }
        }
    }
        //
        // const profit = (el) => {
        //     return el?.customerRate - el?.carrierRate - el?.agentCharges || 0
        // }
        // if (e.target.name) {
        //     setSearch(e.target.value)
        //     const info = payrollLoads?.loads?.filter((el) =>
        //         e.target.name === 'profit' ?
        //             profit(el).toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
        //             :
        //             nameRow ?
        //                 el[e.target.name] && el[e.target.name][nameRow] && el[e.target.name][nameRow].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
        //                 :
        //                 el[e.target.name] && el[e.target.name].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1)
        //     setList(info)
        // }
        // if (!e.target.value) {
        //     setList(payrollLoads?.loads)
        // }
    // }

    const changePage = (number) => {
        SendPageSave(number, info, history)
    };

    if (loader?.length) {
        return <Loader/>
    }

    return (
        <div className='load-payroll-wrapper'>
            <div className={globalStyle.tableWrapper} style={{background: 'white',}}>
                <div className='accordion-table-head-wrapper'>
                    {headList?.map((i, j) => (
                        <div className='accordion-table-head' key={j} style={{width: i?.rowWidth}}>
                            <SearchAndFilter
                              handleSearch={(e) => handleSearch(e, i?.nameRow, i?.searchKey, i?.icon)}
                              item={i}
                              searched={searched}
                            />
                        </div>
                    ))}
                </div>

                <div className='accordion-table-body-scroll'>
                    {list?.length ? list?.map((i, j) => (
                        <React.Fragment key={i?.id}>
                            <Accordion expanded={expanded === i?.id} onChange={() => handleChange(i?.id)} key={j}>
                                <AccordionSummary
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <div className='accordion-table-body-wrapper'>
                                        <div style={{width: '7%'}} className='accordion-table-body'>
                                            {i?.displayId}
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <DateRow date={i?.submittedAt}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <TextRow name={i?.office?.name}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <TextRow name={i?.agent?.name}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <TextRow name={i?.mc?.name}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <TextRow name={i?.customer?.name}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <TextRow name={i?.carrier?.name}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <PriceRow info={i?.carrierRate}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <PriceRow info={i?.customerRate}/>
                                        </div>
                                        <div style={{width: '10%'}} className='accordion-table-body'>
                                            <PriceRow info={i?.customerRate - i?.carrierRate - i?.agentCharges}/>
                                        </div>
                                        <div style={{width: '3%'}} className='accordion-table-body'>
                                            {i?.id === expanded ?
                                                <ExpandLessIcon/>
                                                :
                                                <ExpandMoreIcon/>
                                            }
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {expanded === i?.id &&
                                        <div className='loaf-payroll-table'>
                                            <FullTable
                                                head={loadPayrollHead}
                                                body={loadPayrollBody}
                                                loadingType={'GET_PAYROLLS'}
                                                list={payrolls?.payrolls}
                                                noText={'Details'}
                                                height={'small'}
                                            />
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    )) :
                        <div style={{height: '100px'}}>
                            <TableNoInfo text={`No Load Yet`}/>
                        </div>
                    }
                </div>
                {list?.length > 0 ?
                    <div style={{background: 'white', border: '0 0 8px 8px'}}>
                        <PaginationItem
                            listInfo={list}
                            handleReturn={(number) => changePage(number)}
                            count={payrollLoads?.count}
                            styles={{margin: '0 0 21px 0'}}
                        />
                    </div>
                    : ''
                }
            </div>
        </div>
    );
}
