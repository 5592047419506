import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Colors,
    createInputsWrapper,
    ErrorText, PermissionsList,
    RolePermission,
    useGlobalStyles,
    useGlobalText, userType
} from "utils";
import {
    Circle,
    CreateChancel,
    EditSaveButtons,
    Line,
    SelectInput,
    SelectTypeAutocomplete,
    ValidationInput
} from "components";
import {createLoadStyle} from "./styles";
import {bookedAs, loadTypeList, teamSolo} from "./common";
import {httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import {CANCELLED} from "../../../../pages/accounting/constants";

export const LoadDetails = ({info, handleChangeScreen, destination, params, mcsListReserve, tonu}) => {
    const globalScreens = createInputsWrapper();
    const globalStyles = useGlobalStyles();
    const globalText = useGlobalText();
    const classes = createLoadStyle();
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const [inputs, setInputs] = useState({});
    const [loader, setLoader] = useState(false);
    const [disables, setDisabled] = useState(info && info.type ? true : tonu === true)
    const [soldAs, setSoldAd] = useState([])

    useEffect(async () => {
        if(mcsListReserve?.length){
            const filteredSold = await mcsListReserve && mcsListReserve?.filter((i) => (i.authorityType !== "CARRIER"))
            setSoldAd(filteredSold)
        }
    }, [mcsListReserve])

    useEffect(() => {
        if(!info?.type && !info?.soldMc) {
            const inputDefault = {
                serviceType: 'SOLO', serviceGiven: 'SOLO', bookedAs: 'FULL', sold: 'FULL'
            }
            if (soldAs) {
                const newList = soldAs?.filter((i) => (i.authorityType === "DEFAULT"))
                if (newList && newList?.length) {
                    inputDefault.soldMc = newList[0]?.id
                }
            }
            setInputs(inputDefault)
        }
    },[soldAs])

    useEffect(() => {
        if (info && info?.type) {
            const newList = {
                "soldMc": info.soldMc,
                "bookedAs": info.bookedAs,
                "serviceType": info.serviceType,
                "serviceGiven": info.serviceGiven,
                "sold": info.sold,
                "type": info.type,
                "temperature": info.temperature,
                "commodity": info.commodity,
                "weight": info.weight,
            }
            info.customerReference ? newList.customerReference = info.customerReference : ''
            info.contactName ? newList.contactName = info.contactName : ''
            info.description ? newList.description = info.description : ''
            info.ft ? newList.ft = info.ft : ''
            info?.containerNumber ? newList.containerNumber = info.containerNumber : ''
            setInputs(newList)
        }
    }, [info])

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),)
        error === e.target.name && setError('')
    };



    const handleCreate = async () => {

        let errDrayCheck = inputs.type === 'DRAYAGE' ? !!inputs.containerNumber : true
        let errRailCheck = inputs.type === 'RAIL' ? !!inputs.containerNumber : true
        let errTempCheck = inputs.type === 'REEFER' ? (!!inputs.temperature || inputs.temperature === 0) : true

        if (
            inputs.type &&
            errDrayCheck && errRailCheck && errTempCheck &&
            inputs.commodity && inputs.serviceType && inputs.serviceGiven && inputs.bookedAs && inputs.sold &&
            inputs.weight && inputs.soldMc && inputs.customerReference) {
            setLoader(true)
            const info = {...inputs,}
            info.customerReference = inputs.customerReference
            inputs?.temperature  || inputs?.temperature === 0 ? info.temperature = +inputs.temperature : delete info.temperature
            info.weight = inputs.weight
            inputs?.ft ? info.ft = +inputs?.ft : ''
            try {
                await LoadServices.CreateLoadDetails(params.id, info)
                dispatch(loadActions.getLoadById(params.id, 'noLoad'))
                dispatch(httpRequestsOnSuccessActions.appendSuccess('LOAD_DETAILS'))
                handleChangeScreen()
                setLoader(false)
                setDisabled(true)
            } catch (e) {
                setLoader(false)
            }
        } else {
            let errDray = inputs.type === 'DRAYAGE' ? !inputs.containerNumber && 'containerNumber' : null
            let errRail = inputs.type === 'RAIL' ? !inputs.containerNumber && 'containerNumber' : null
            let errTempCheck = inputs.type === 'REEFER' ? !inputs.temperature && 'temperature' : null

            setError(
                !inputs.type ? 'type' :
                    errDray ? errDray :
                        errRail ? errRail :
                            errTempCheck ? errTempCheck :
                            !inputs.commodity ? 'commodity' :
                                !inputs.serviceType ? 'serviceType' :
                                    !inputs.serviceGiven ? 'serviceGiven' :
                                        !inputs.bookedAs ? 'bookedAs' :
                                            !inputs.sold ? 'sold' :
                                                !inputs.weight ? 'weight' :
                                                    !inputs.soldMc ? 'soldMc' :
                                                        !inputs.customerReference ? 'customerReference' :
                                                            ""
            )
        }
    }

    return (
        <div>
            <div className={classes.titleAndSwitcher}>
                <div className={globalStyles.centerItem}>
                    <Circle number={2} back={Colors.ThemeAqua}/>
                    <p className={globalText.title}>Load Details</p>
                </div>

                {info?.status !== CANCELLED &&
                    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EDIT_LOAD_DETAILS?.code], 'agentView') &&
                    <>
                        {(tonu ? true : info?.type) &&
                            <EditSaveButtons
                                type={'LOAD_DETAILS'}
                                handleChancel={() => setDisabled(true)}
                                handleSetEdit={() => setDisabled(false)}
                                handleSaveInfo={handleCreate}
                                tableType={'load'}
                                loadLoading={loader}
                            />
                        }
                    </>
                }
            </div>

            <div className={globalStyles.flexAndFullWidth}>
                {destination &&
                    <div className={'MuiAccordionDetails-root'}>
                        <Line height={'240px'}/>
                    </div>
                }
                <div style={destination === false ? {width: '100%', marginLeft: '48px'} : {width: '100%'}}>
                    <div className={globalScreens.basicInfoInputs}>
                        <div className={classes.inputsWrapper}>
                            <SelectInput
                                className={inputs.type && globalStyles.inputTextFieldBlue}
                                name={"type"}
                                label={"Load Type*"}
                                handleSelect={handleChange}
                                style={globalStyles.loadSimpleInput}
                                value={inputs.type}
                                list={loadTypeList}
                                typeError={error === 'type' ? ErrorText.field : ''}
                                type={'mc'}
                                disabled={disables}
                            />
                            {inputs.type === 'REEFER' &&
                                <ValidationInput
                                    style={globalStyles.loadSimpleInput}
                                    className={ (inputs.temperature === 0 || inputs.temperature) && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"temperature"}
                                    label={"Temperature*"}
                                    type={'number'}
                                    typeError={error === 'temperature' ? ErrorText.field : ''}
                                    onChange={handleChange}
                                    value={inputs.temperature === 0  ? '0' : inputs.temperature}
                                    disabled={disables}
                                />
                            }
                            {(inputs?.type === 'DRAYAGE' || inputs?.type === 'RAIL') &&
                                <ValidationInput
                                    style={globalStyles.loadSimpleInput}
                                    className={inputs.containerNumber && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"containerNumber"}
                                    label={"Container number*"}
                                    type={'text'}
                                    typeError={error === 'containerNumber' ? ErrorText.field : ''}
                                    onChange={handleChange}
                                    value={inputs.containerNumber}
                                    disabled={disables}
                                />
                            }
                            {inputs.type === 'OTHER' &&
                                <div className={classes.descriptionWrapper}>
                                    <ValidationInput
                                        style={globalStyles.loadSimpleInput}
                                        className={inputs.description && globalStyles.inputTextFieldBlue}
                                        variant={"outlined"}
                                        name={"description"}
                                        label={"Description*"}
                                        Length={50}
                                        type={'text'}
                                        typeError={error === 'description' ? ErrorText.field : ''}
                                        onChange={handleChange}
                                        value={inputs.description}
                                        disabled={disables}
                                    />
                                    <p>{`${inputs.description ? inputs.description.length : 0}/50`}</p>
                                </div>
                            }
                        </div>
                        <div className={classes.halfScreen}>
                            <ValidationInput
                                style={globalStyles.loadSimpleInputFull}
                                className={inputs.commodity && globalStyles.inputTextFieldBlue}
                                variant={"outlined"}
                                name={"commodity"}
                                label={"Commodity*"}
                                type={'text'}
                                typeError={error === 'commodity' ? ErrorText.field : ''}
                                onChange={handleChange}
                                value={inputs.commodity}
                                disabled={disables}
                            />
                        </div>
                    </div>
                    <div className={globalStyles.spaceBetween} style={{height:'65px', alignItems:'flex-start'}}>
                        <div className={classes.inputsWrapper}>
                            <SelectInput
                                className={inputs.serviceType && globalStyles.inputTextFieldBlue}
                                name={"serviceType"}
                                label={"Service Type*"}
                                handleSelect={handleChange}
                                style={globalStyles.loadSimpleInput}
                                value={inputs.serviceType}
                                list={teamSolo}
                                typeError={error === 'serviceType' ? ErrorText.field : ''}
                                type={'mc'}
                                disabled={disables}
                            />
                            <SelectInput
                                className={inputs.serviceGiven && globalStyles.inputTextFieldBlue}
                                name={"serviceGiven"}
                                label={"Service Given*"}
                                handleSelect={handleChange}
                                style={globalStyles.loadSimpleInput}
                                value={inputs.serviceGiven}
                                list={teamSolo}
                                typeError={error === 'serviceGiven' ? ErrorText.field : ''}
                                type={'mc'}
                                disabled={disables}
                            />
                        </div>
                        <div className={classes.halfScreen}>
                            <SelectInput
                                className={inputs.bookedAs && globalStyles.inputTextFieldBlue}
                                name={"bookedAs"}
                                label={"Booked As*"}
                                handleSelect={handleChange}
                                style={globalStyles.loadSimpleInput}
                                value={inputs.bookedAs}
                                list={bookedAs}
                                typeError={error === 'bookedAs' ? ErrorText.field : ''}
                                type={'mc'}
                                disabled={disables}
                            />
                            <SelectInput
                                style={globalStyles.loadSimpleInputFull}
                                className={inputs.sold && globalStyles.inputTextFieldBlue}
                                name={"sold"}
                                label={"Sold As*"}
                                handleSelect={handleChange}
                                value={inputs.sold}
                                list={bookedAs}
                                typeError={error === 'sold' ? ErrorText.field : ''}
                                type={'mc'}
                                disabled={disables}
                            />
                        </div>
                    </div>
                    <div className={globalScreens.basicInfoInputs}>
                        <div className={classes.inputsWrapper}>

                            <div className={classes.weightFr}>
                                <ValidationInput
                                    style={
                                        (inputs.bookedAs === 'PARTIAL' || inputs?.type === 'DRAYAGE' || inputs?.type === 'BOX_TRUCK')
                                            ? globalStyles.loadSimpleInput : ''}
                                    className={inputs.weight && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"weight"}
                                    label={"Weight*"}
                                    type={'text'}
                                    typeError={error === 'weight' ? ErrorText.field : ''}
                                    onChange={handleChange}
                                    value={inputs.weight}
                                    disabled={disables}
                                />
                                {(inputs.bookedAs === 'PARTIAL' || inputs?.type === 'DRAYAGE' || inputs?.type === 'BOX_TRUCK') &&
                                    <ValidationInput
                                        className={inputs.weight && globalStyles.inputTextFieldBlue}
                                        variant={"outlined"}
                                        name={"ft"}
                                        label={"FT"}
                                        type={'number'}
                                        typeError={error === 'ft' ? ErrorText.field : ''}
                                        onChange={handleChange}
                                        value={inputs.ft}
                                        disabled={disables}
                                    />
                                }
                            </div>
                            {soldAs?.length ?
                                <SelectTypeAutocomplete
                                    loadType={userType === 'ADMIN' ? 'GET_MCS' : 'GET_AGENT_ASSIGNED_MCS'}
                                    style={globalStyles.loadSimpleInput}
                                    name={'soldMc'}
                                    label={'name'}
                                    type={'mcNumber'}
                                    title={'Sold As Authority*'}
                                    handleSelect={handleChange}
                                    defaultValue={inputs?.soldMc}
                                    list={soldAs ? soldAs : []}
                                    disabled={disables}
                                    error={error}
                                />
                                :
                                <SelectTypeAutocomplete
                                    style={globalStyles.loadSimpleInput}
                                    title={'Sold As Authority*'}
                                    list={[]}
                                    disabled={disables}
                                    error={error}
                                />
                            }
                        </div>
                        <div className={classes.halfScreen}>
                            <ValidationInput
                                style={globalStyles.loadSimpleInput}
                                className={inputs.contactName && globalStyles.inputTextFieldBlue}
                                variant={"outlined"}
                                name={"contactName"}
                                label={"Contact Name"}
                                type={'text'}
                                typeError={error === 'contactName' ? ErrorText.field : ''}
                                onChange={handleChange}
                                value={inputs.contactName}
                                disabled={disables}
                            />
                            <ValidationInput
                                style={globalStyles.loadSimpleInputFull}
                                className={inputs.customerReference && globalStyles.inputTextFieldBlue}
                                variant={"outlined"}
                                name={"customerReference"}
                                label={"Customer Reference*"}
                                type={'text'}
                                typeError={error === 'customerReference' ? ErrorText.field : ''}
                                onChange={handleChange}
                                value={inputs.customerReference}
                                disabled={disables}
                            />
                        </div>
                    </div>
                    {destination === false && info?.status !== CANCELLED &&
                        <CreateChancel
                            loader={loader}
                            classes={globalStyles.buttonsStyle}
                            create={"Continue"}
                            chancel={"Cancel"}
                            onCreate={handleCreate}
                        />
                    }
                </div>
            </div>
        </div>
    )
}