import {
  getCarriers,
  createCarrier,
  getCarrierById,
  editCarrier,
  addFactoring,
  carrierFuel,
  carrierPayment,
  assignCarrierAccess,
  getCarrierOfficeAccess,
  getCarrierAgentAccess,
  getCarrierBranchAccess,
  getCarrierPayments,
  getCarrierPaymentById,
  inactivateActivateCarrier,
  getPaymentsCarrierById,
  carrierPaymentTerm,
  addCarrierDocument,
  deleteCarrierDocument,
  addFuelCard,
  editFuelCard,
  deleteFuelCard,
  doNotLoad,
  carrierPaymentCarriers,
  searchCarriers,
  feeExempt,
  getAlerts,
  editAlert,
  deleteAlert,
  addAlert,
  getReviews, addReview, editReview, deleteReview, inviteCarrierApp, reInviteCarrierApp, editCarrierDocument,
} from './carrier.action';

export { carrierReducer } from './carrier.reducer';
export { watchCarrier } from './carrier.saga';

export const carrierActions = {
  /** Create, Edit Carrier */
  createCarrier,
  inviteCarrierApp,
  reInviteCarrierApp,
  editCarrier,
  /** End */

  /** Get Carriers */
  getCarriers,
  searchCarriers,
  getCarrierById,
  /** End */

  /** Activate or Inactivate Carrier */
  inactivateActivateCarrier,
  /** End */

  /** Carrier Info */
  addFactoring,
  carrierFuel,
  carrierPayment,
  carrierPaymentTerm,
  /** End */

  /** Carrier Access */
  assignCarrierAccess,
  getCarrierOfficeAccess,
  getCarrierAgentAccess,
  getCarrierBranchAccess,
  /** End */

  /** Carrier Payment */
  getCarrierPayments,
  getCarrierPaymentById,
  getPaymentsCarrierById,
  carrierPaymentCarriers,
  /** End */

  /** Carrier Document */
  addCarrierDocument,
  deleteCarrierDocument,
  editCarrierDocument,
  /** End */

  /** Carrier Payment Fuel Card*/
  addFuelCard,
  editFuelCard,
  deleteFuelCard,
  /** End */

  /** Carrier Do not load */
  doNotLoad,
  /** End */

  /** Carrier Fee */
  feeExempt,
  /** End */

  /** Alert */
  getAlerts,
  addAlert,
  editAlert,
  deleteAlert,
  /** End */

  /** Review */
  getReviews,
  addReview,
  editReview,
  deleteReview,
  /** End */
}

