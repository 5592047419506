
/**Create Carrier */
export const CREATE_CARRIER = 'CREATE_CARRIER';
export const CREATE_CARRIER_SUCCESS = 'CREATE_CARRIER_SUCCESS';
export const INVITE_CARRIER_TO_APP = 'INVITE_CARRIER_TO_APP';
export const RE_INVITE_CARRIER_TO_APP = 'RE_INVITE_CARRIER_TO_APP';

/**Edit Carrier */
export const EDIT_CARRIER = 'EDIT_CARRIER'

/**Get Carriers */
export const GET_CARRIERS = 'GET_CARRIERS';
export const GET_CARRIERS_SUCCESS = 'GET_CARRIERS_SUCCESS';

export const SEARCH_CARRIERS = 'SEARCH_CARRIERS';

/**Get Carrier by id */
export const GET_CARRIER_BY_ID = 'GET_CARRIER_BY_ID';
export const GET_CARRIER_BY_ID_SUCCESS = 'GET_CARRIER_BY_ID_SUCCESS';

/**Activate Inactivate Carrier */
export const INACTIVATE_ACTIVATE_CARRIER = 'INACTIVATE_ACTIVATE_CARRIER';

/** Carrier Info */
export const ADD_CARRIER_FACTORING = 'ADD_CARRIER_FACTORING';
export const ADD_CARRIER_FACTORING_SUCCESS = 'ADD_CARRIER_FACTORING_SUCCESS';
export const CARRIER_FUEL = 'CARRIER_FUEL';
export const CARRIER_PAYMENT = 'CARRIER_PAYMENT';
export const CARRIER_PAYMENT_TERM = 'CARRIER_PAYMENT_TERM';

/** Carrier Access */

export const GET_CARRIER_OFFICE_ACCESS = 'GET_CARRIER_OFFICE_ACCESS';
export const GET_CARRIER_OFFICE_ACCESS_SUCCESS = 'GET_CARRIER_OFFICE_ACCESS_SUCCESS';

export const GET_CARRIER_AGENT_ACCESS = 'GET_CARRIER_AGENT_ACCESS';
export const GET_CARRIER_AGENT_ACCESS_SUCCESS = 'GET_CARRIER_AGENT_ACCESS_SUCCESS';

export const GET_CARRIER_BRANCH_ACCESS = 'GET_CARRIER_BRANCH_ACCESS';
export const GET_CARRIER_BRANCH_ACCESS_SUCCESS = 'GET_CARRIER_BRANCH_ACCESS_SUCCESS';

export const ASSIGN_CARRIER_ACCESS = 'ASSIGN_CARRIER_ACCESS';

/** End */

/** Carrier Payment */
export const GET_CARRIER_PAYMENTS = 'GET_CARRIER_PAYMENTS';
export const GET_CARRIER_PAYMENTS_SUCCESS = 'GET_CARRIER_PAYMENTS_SUCCESS';
export const GET_CARRIER_PAYMENT_BY_ID = 'GET_CARRIER_PAYMENT_BY_ID';
export const GET_CARRIER_PAYMENT_BY_ID_SUCCESS = 'GET_CARRIER_PAYMENT_BY_ID_SUCCESS';
export const GET_PAYMENTS_CARRIER = 'GET_PAYMENTS_CARRIER';
export const GET_PAYMENTS_CARRIER_SUCCESS = 'GET_PAYMENTS_CARRIER_SUCCESS';

export const GET_CARRIER_PAYMENT_CARRIER = 'GET_CARRIER_PAYMENT_CARRIER';
export const GET_CARRIER_PAYMENT_CARRIER_SUCCESS = 'GET_CARRIER_PAYMENT_CARRIER_SUCCESS';

/** Carrier Documents */
export const ADD_CARRIER_DOCUMENT = 'ADD_CARRIER_DOCUMENT';
export const EDIT_CARRIER_DOCUMENT = 'EDIT_CARRIER_DOCUMENT';
export const DELETE_CARRIER_DOCUMENT = 'DELETE_CARRIER_DOCUMENT';

/** Carrier Payment Fuel */
export const ADD_CARRIER_FUEL_CARD = 'ADD_CARRIER_FUEL_CARD';
export const EDIT_CARRIER_FUEL_CARD = 'EDIT_CARRIER_FUEL_CARD';
export const DELETE_CARRIER_FUEL_CARD = 'DELETE_CARRIER_FUEL_CARD';

/** Carrier Do not load */
export const DO_NOT_LOAD = 'DO_NOT_LOAD';

/** Carrier Fee */
export const FEE_EXEMPT = 'FEE_EXEMPT';

/** Alert */
export const GET_ALERTS = 'GET_ALERTS'
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS'

export const ADD_ALERT = 'ADD_ALERT'

export const EDIT_ALERT = 'EDIT_ALERT'

export const DELETE_ALERT = 'DELETE_ALERT'

/** Review */
export const GET_REVIEWS = 'GET_REVIEWS'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'

export const ADD_REVIEW = 'ADD_REVIEW'

export const EDIT_REVIEW = 'EDIT_REVIEW'

export const DELETE_REVIEW = 'DELETE_REVIEW'
