import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Colors, Backgrounds} from "utils";
import StepConnector from "@material-ui/core/StepConnector";

export const stepStyles = makeStyles(() => ({
    stepHeader: {
        background: Backgrounds.headerLightBlue,
        padding: '24px 0 36px 0',
        display: 'flex',
        justifyContent: 'space-between',
    },
    stepBody: {
        // padding: '32px 40px 40px 40px',
        "@media (max-width: 1400px)": {
            padding: '32px',
        },
    },
    buttonsContainer: {
        marginTop: 0
    }
}));

export const useStyles = makeStyles((theme) => ({
    tabLabel:{
        minWidth:'100px'
    },

    root: {
        width: '100%',
        '& .MuiStepLabel-root': {
            alignItems: 'flex-start',
            width: 'auto',
        },
        '& .MuiStepLabel-iconContainer': {
            // marginLeft:'5px'
        },
        '& .MuiStep-alternativeLabel': {
            flex: 0
        },
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            alignItems: 'flex-start'
        }
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    step_label_root: {
        fontSize: '16px',
        color: `${Colors.TextSecondary}!important`,
        lineHeight: '22px!important',
        fontWeight: '600!important',

        // "& .MuiStepLabel-labelContainer:first-of-type": {
        //     width:'129px'
        // }
    },

    tabHeader: {
        display: 'flex',
        flexDirection: 'row !important',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingRight: '24px',

        background: `${Colors.BackgroundPrimary}!important`,
        borderRadius: '8px 8px 0px 0px',
        color: Colors.TextSecondary,
        '& .MuiTabScrollButton-root.Mui-disabled': {
            display: 'none'
        },
        '& .MuiTabs-indicator': {
            backgroundColor: Colors.ThemeBlue,
            height: '4px',
        },

        '& .MuiTab-wrapper': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: Colors.TextDarkGrey,
            textTransform: 'capitalize',


        },

        '& .MuiTab-textColorInherit.Mui-selected': {
            '& span': {
                color: Colors.TextSecondary,
            }
        }
    },

    reportTabHeader: {
        display: 'flex',
        flexDirection: 'row !important',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: '0 12px',
        background: `${Colors.BackgroundPrimary}!important`,
        borderRadius: '8px',
        color: Colors.TextSecondary,
        boxShadow:'none',

        '& .MuiTabScrollButton-root.Mui-disabled': {
            display: 'none'
        },
        '& .MuiTabs-indicator': {
            backgroundColor: Colors.ThemeBlue,
            height: '4px',
        },

        '& .MuiTab-wrapper': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: Colors.TextDarkGrey,
            textTransform: 'capitalize',
        },

        '& .MuiTab-textColorInherit.Mui-selected': {
            '& span': {
                color: Colors.TextSecondary,
            }
        }
    },

    tabHeaderVerification: {
        display: 'flex',
        flexDirection: 'row !important',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingRight: '24px',
        background: `#f5dfdf !important`,
        borderRadius: '8px 8px 0px 0px',
        color: Colors.TextSecondary,

        '& .MuiTabs-root': {
            background: '#f5dfdf !important'
        },
        '& .MuiTabScrollButton-root.Mui-disabled': {
            display: 'none'
        },
        '& .MuiTabs-indicator': {
            backgroundColor: Colors.ThemeBlue,
            height: '4px',
        },

        '& .MuiTab-wrapper': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: Colors.TextDarkGrey,
            textTransform: 'capitalize',
        },
        '& .MuiTab-textColorInherit': {
            background: `#f5dfdf !important`,
            opacity: '1 !important',
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            '& span': {
                color: Colors.TextSecondary,
            }
        }
    },
    body: {
        background: `${Colors.BackgroundPrimary}!important`,
        borderRadius: '8px 8px 0px 0px',
        color: Colors.TextSecondary,

    },
    tabPanel: {
        height: '80vh',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '0 0 8px 8px',
        background: 'white',
    },

    reportTabPanel: {
        height: '80vh',
        borderRadius: '0 0 8px 8px',
        background: 'transparent',
        '& .MuiBox-root':{
            padding: '16px 0',
            "@media (min-width: 1919px)": {
                padding: '24px 0',
            },
        }
    },

    tabPanelFull: {
        minHeight: '80vh',
        height: '100%',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '0 0 8px 8px',
        background: 'white',

        '& .MuiBox-root':{
            padding: '16px',
            "@media (min-width: 1919px)": {
                padding: '24px',
            },
        }
    }


}));

export const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 36,
        height: 36,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '&::after': {
            content: `''`,
            position: 'absolute',
            left: '1px',
            top: '1px',
            width: '34px',
            height: '34px',
            backgroundColor: 'transparent',
            borderRadius: '50%',
            border: '1px solid white'
        }
    },
    active: {
        backgroundColor: '#347AF0'
    },
    completed: {
        backgroundColor: '#347AF0',
    }
});

export const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
        left: 'calc(-50% + 38px)',
        right: 'calc(50% + 38px)'
    },

    line: {
        border: `1px dashed ${Colors.TextLight}`,
        borderRadius: 'unset',
    }
})(StepConnector);

