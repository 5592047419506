import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    AddModalButton, AddressInput,
    CloseButton, MaskInput, SelectInput,
    ValidationInput
} from "components";
import {
    EmailValidator,
    ErrorText,
    FindErrorItem,
    FindLoad,
    globalModals,
    useGlobalTextStyles
} from 'utils'
import {
    agentActions,
    branchActions,
    httpRequestsOnErrorsActions,
} from "store";

export const AddManagerModal = ({handleClose, modalType, info, forId}) => {
    const {branchManagersList, byList} = useSelector((state) => ({
        branchManagersList: state.agents.branchManagersList,
        byList: state.agents.byList,
    }));
    const globalModalsClasses = globalModals();
    const globalText = useGlobalTextStyles();
    const dispatch = useDispatch()
    const params = useParams()
    const [inputs, setInputs] = useState(info ? {...info} : {});
    const [error, setError] = useState('')
    const [selectedAgentList, setSelectedAgentList] = useState('')
    const [fullAddress, setFullAddress] = useState(null)
    const [unitNumber, setUnitNumber] = useState(null)
    const [email, setEmail] = useState('')
    const editLoader = FindLoad('EDIT_AGENT')
    const createLoader = FindLoad('CREATE_BRANCH_MANAGERS')
    const selectLoader = FindLoad('SELECT_BRANCH_MANAGERS')
    const type = modalType === 'create' ? true : modalType === 'edit'
    const modalTitle =
        modalType === 'edit' ? 'Want to Edit Branch Manager?' :
            modalType === 'select' ? 'Want to Add Branch Manager?' :
                'Want to Add Branch Agent?'
    const modalSubTitle = info ? 'Want to Edit Branch Manager?' : modalType === 'select' ? 'Please select from Agent list.' : 'Please fill out the fields below fields to add a Branch Agent.'
    const backError = FindErrorItem(info ? 'EDIT_AGENT' : 'CREATE_BRANCH_MANAGERS')
    const errorFormBack = backError?.error
    const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error

    useEffect(() => {
        if (info?.address) {
            if (info?.address?.unitNumber) {
                setUnitNumber(info?.address?.unitNumber)
            }
            setFullAddress(info?.address?.formattedAddress)
        }
        if (info?.id) {
            setInputs({
                "email": info.email,
                "firstName": info.firstName,
                "lastName": info.lastName,
                "username": info?.username ? info.username : `${info?.firstName ? info.firstName : ''} ${info?.lastName ? info.lastName : ''}`,
                "phoneNumber": inputs?.phoneNumber,
                "officeId": forId && forId?.office?.id,
                "branchId": inputs?.branch?.id,
            })
        }
    }, [info])

    const addManager = () => {
        const phoneNumber = inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + ''

        const data = {
            "email": inputs.email,
            "firstName": inputs.firstName,
            "lastName": inputs.lastName,
            "username": inputs?.username ? inputs.username : `${inputs?.firstName ? inputs.firstName : ''} ${inputs?.lastName ? inputs.lastName : ''}`,
            "phoneNumber": phoneNumber,
            "address": {
                address: fullAddress,
                unitNumber: unitNumber ? unitNumber : null,
            },
            "officeId": forId && forId.office.id,
            "branchId": params.id,
        }
        if (modalType === 'select') {
            if (selectedAgentList) {
                const selectedData = {
                    "managerId": selectedAgentList,
                    "branchId": params.id,
                    "officeId": forId && forId.office.id
                }
                dispatch(branchActions.selectBranchManager(selectedData))
            } else {
                setError('agentList')
            }
        } else {
            if (!email.length) {
                if (inputs.firstName && inputs.lastName && inputs.email && inputs?.phoneNumber && fullAddress) {
                    if (info) {
                        dispatch(agentActions.editAgent(info.id, data, 'branch'))
                    } else {
                        dispatch(branchActions.createBranchManager(data))
                    }
                } else {
                    setError(
                        !inputs.firstName ? 'firstName' :
                            !inputs.lastName ? 'lastName' :
                                !inputs.email ? 'email' :
                                    !inputs.phoneNumber ? 'phoneNumber' :
                                        !fullAddress ? 'address' :
                                            'address'
                    )
                }
            }
        }
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}),);
        error === e.target.name && setError('')
        e.target.name === 'email' &&
        errorFormBack === "User already exists" ||
        errorFormBack === "An agent with this email exists" ||
        errorFormBack === 'Agent already exists' && handleRemoveError()
    }

    const handleFullAddress = (ev) => {
        setFullAddress(ev?.address)
        if (error === 'address') setError('')
    }
    // const handleFullAddress = (ev) => {
    //     setFullAddress(ev)
    //     if (error === 'address') setError('')
    // }

    const handleChangeFromList = (ev) => {
        setSelectedAgentList(ev.target.value)
        error === 'agentList' && setError('')
    }

    const filteredList = byList?.length &&
        byList.filter((i) => (
            !i.managedOffices.length
        ))

    const filteredBranchManagersList = filteredList && filteredList.filter(function (array_el) {
        return branchManagersList && branchManagersList.filter(function (anotherOne_el) {
            return anotherOne_el.id === array_el.id;
        }).length === 0
    });

    const handleRemoveError = () => {
        dispatch(httpRequestsOnErrorsActions.removeError(info ? 'EDIT_AGENT' : 'CREATE_BRANCH_MANAGERS'))
    }

    useEffect(() => {
        return () => handleRemoveError()
    }, [])

    return (
        <div className={globalModalsClasses.smallModalWrapper}>
            <div className={globalModalsClasses.smallModalClose}>
                <CloseButton handleCLic={handleClose}/>
            </div>
            <div className={globalModalsClasses.modalWrapperContent}>
                <p className={globalText.modalTitle}>{modalTitle}</p>
                <p className={globalText.modalText}>{modalSubTitle}</p>
                {modalType === 'select' &&
                    <SelectInput
                        name={"agentList"}
                        label={"Select from Agent List"}
                        handleSelect={handleChangeFromList}
                        value={selectedAgentList}
                        list={filteredBranchManagersList && filteredBranchManagersList.filter((i) => i.status === 'ACTIVE')}
                        typeError={error === 'agentList' ? ErrorText.field : ''}
                        type={'agent'}
                    />
                }

                {type &&
                    <>
                        <ValidationInput
                            onChange={handleChange}
                            value={inputs.firstName}
                            typeError={error === 'firstName' && ErrorText.field}
                            label={"Full Name*"}
                            type={"text"}
                            variant={"outlined"}
                            name={"firstName"}
                        />
                        <ValidationInput
                            onChange={handleChange}
                            value={inputs.lastName}
                            typeError={error === 'lastName' && ErrorText.field}
                            variant={"outlined"}
                            name={"lastName"}
                            label={"Last Name*"}
                            type={"text"}
                        />
                        <ValidationInput
                            onChange={handleChange}
                            value={inputs?.username ? inputs?.username :
                                `${inputs?.firstName ? inputs.firstName : ''} ${inputs?.lastName ? inputs.lastName : ''}`
                            }
                            typeError={error === 'username' && ErrorText.field}
                            variant={"outlined"}
                            name={"username"}
                            label={"User Name"}
                            type={"text"}
                        />
                        <ValidationInput
                            validator={EmailValidator}
                            variant={"outlined"}
                            name={"email"}
                            type={"email"}
                            label={"Email Address*"}
                            typeError={
                                errorFormBack === "User already exists" ? "User already exists" :
                                    errorFormBack === "An agent with this email exists" ? "An agent with this email exists" :
                                        errorFormBack === 'Agent already exists' ? 'Agent already exists' :
                                            error === 'email' ? ErrorText.field :
                                                email === 'Not valid email' ? 'Not valid email' : ''
                            }
                            sendBoolean={(bool) => bool === true ? setEmail("Not valid email") : setEmail('')}
                            onChange={handleChange}
                            value={inputs.email}
                        />
                        <MaskInput
                            border={'black'}
                            styles={{marginRight: 0}}
                            name='phoneNumber'
                            label="Phone Number*"
                            value={inputs.phoneNumber}
                            handleChange={handleChange}
                            info={info}
                            error={
                                error === 'phoneNumber' ? ErrorText.field :
                                    errorText === 'phoneNumber must be a valid phone number' && ErrorText.phone}
                        />
                        <AddressInput
                            noBlue={true}
                            info={info}
                            oneInput={true}
                            errorBoolean={error === 'address' ? 'Input is not field' : ''}
                            handleSelectValue={handleFullAddress}
                        />
                        <ValidationInput
                            style={globalModalsClasses.suiteInput}
                            onChange={(e) => setUnitNumber(e.target.value)}
                            value={unitNumber}
                            typeError={error === 'unitNumber' && ErrorText.field}
                            variant={"outlined"}
                            name={"unitNumber"}
                            label={"Suite"}
                            type={"text"}
                        />
                    </>
                }
                <AddModalButton
                    loading={!!editLoader.length || !!createLoader.length || !!selectLoader.length}
                    styles={{marginTop: '26px'}}
                    handleClick={addManager}
                    text={info ? 'Save' : 'Add'}
                />
            </div>
        </div>
    )
}