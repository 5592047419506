import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequestsOnLoadActions, httpRequestsOnSuccessActions, loadActions, LoadServices } from 'store';
import { DynamicNote, FullTable, SimpleModal, TableWrapper } from 'components';
import { userType } from 'utils';
import { creditBody, creditHead, ACTION_TYPE, navigateToLoad } from './constants';
import { CREDITCHECK } from '../accounting/constants';

const REJECT_TYPE = 'REJECT_CREDIT';
export const Credit = ({}) => {
  const { loadsList } = useSelector((state) => ({
    loadsList: state.loads.loadsList,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const [isOpen, setIsOpen] = useState(null);

  const handleClose = () => {
    setIsOpen(null);
  };

  const renderParams = () => {
    const filteredInfo = {
      ...info,
      status: CREDITCHECK
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  };

  useEffect(() => {
    handleGet('loading');
    const interval = setInterval(() => {
      handleGet('noLoad');
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);

  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
    dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }, loader));
  };

  const handleSubmit = async (item, name) => {
    if (name === 'DISAPPROVE') {
      setIsOpen(item?.id);
    } else {
      dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', item.id));
      try {
        await LoadServices.approveCredit(item.id);
        handleGet('noLoad');
        dispatch(loadActions.getLoadTotals());
        dispatch(httpRequestsOnSuccessActions.appendSuccess('LOAD_STATUS'));
        dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
      } catch (e) {
        dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
      }
    }
  };

  const handleReject = async (text) => {
    dispatch(httpRequestsOnLoadActions.appendLoading(REJECT_TYPE));
    dispatch(httpRequestsOnSuccessActions.removeSuccess(REJECT_TYPE));
    try {
      await LoadServices.disapproveCredit(isOpen, text);
      handleGet('noLoad');
      dispatch(loadActions.getLoadTotals());
      dispatch(httpRequestsOnLoadActions.removeLoading(REJECT_TYPE));
      dispatch(httpRequestsOnSuccessActions.appendSuccess(REJECT_TYPE));
    } catch (e) {
      dispatch(httpRequestsOnLoadActions.removeLoading(REJECT_TYPE));
      dispatch(httpRequestsOnSuccessActions.removeSuccess(REJECT_TYPE));
    }
  };

  return (
    <div>
      <TableWrapper
        buttonsTab={true}
        link={true}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        <FullTable
          head={creditHead}
          body={creditBody}
          loadingType={ACTION_TYPE}
          list={loadsList?.loads}
          listCount={loadsList?.count}
          clickType={'item'}
          handleClick={(item) => navigateToLoad(item, history, info, '/credit')}
          noText={'Credits'}
          handleClickButton={handleSubmit}
          dateFilter={true}
        />
      </TableWrapper>

      <SimpleModal
        openDefault={isOpen}
        handleOpenClose={handleClose}
        content={
          <DynamicNote
            handleClose={handleClose}
            title={'Add Note'}
            subTitle={'To leave a note rejecting the load from credit check.'}
            handleRequest={handleReject}
            ACTION_TYPE={'REJECT_CREDIT'}
            buttonText={'Add'}
          />
        }
      />
    </div>
  );
};