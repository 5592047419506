import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import moment from "moment";
import {httpRequestsOnSuccessActions, noteActions} from "store";
import {AddButton, AddNotes, Loader, TableNoInfo, TextRow} from "..";
import {FindLoad, FindSuccess, Images, useModal} from "utils";
import {cardStyle} from ".";
import {CustomDeleteModal} from "fragments";

const ACTION_TYPE = 'GET_GLOBAL_NOTES'
const DELETE_ACTION_TYPE = 'DELETE_GLOBAL_NOTE'

export const Notes = ({}) => {
    const allNotes = useSelector((state) => state.note.notes);
    const classes = cardStyle();
    const dispatch = useDispatch();
    const params = useParams();
    const [sortType, setSortType] = useState('')
    const deleteSuccess = FindSuccess(DELETE_ACTION_TYPE);
    const getLoader = FindLoad(ACTION_TYPE);
    const {open, close} = useModal()

    useEffect(() => {
        const pushInfo = {
            skip: 0,
            limit: 1000,
        }
        dispatch(noteActions.getGlobalNotes(params.id, {...pushInfo}));
    }, []);

    useEffect(() => {
        if (deleteSuccess.length) {
            close()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION_TYPE));
        }
    }, [deleteSuccess]);

    const handleDeleteNote = (item) => {
        dispatch(noteActions.deleteGlobalNote(item?.id, params.id));
    };

    const handleOpen = (item,) => {
        open( <AddNotes noteModalTypeInfo={item} onResource={params.id}/>)
    };

    const handleClickSort = (name) => {
        setSortType(name === sortType ? '' : name)
    }

    if (getLoader?.length) {
        return <Loader/>
    }

    return (
        <>
            <div className={classes.addButtonWrapper}>
                <AddButton
                    handleClick={() => open( <AddNotes onResource={params.id}/>)}
                    text={'Add Comment'}
                />
            </div>
            <div>
                <div className={classes.headWrapper}>
                    <div
                        onClick={() => handleClickSort('date')}
                        style={{width: '20%'}} className={classes.headItem}
                    >
                        Date
                    </div>
                    <div
                        onClick={() => handleClickSort('name')}
                        style={{width: '20%'}} className={classes.headItem}
                    >
                        Creator Name
                    </div>
                    <div style={{width: '55%'}} className={classes.headItem}>Subject</div>
                    <div style={{width: '5%'}} className={classes.headItem}>Action</div>
                </div>
                <div className={classes.noteBodyWrapper}>
                    {allNotes?.length ? allNotes.map((i, k) => (
                            <div key={k} className={classes.bodyWrapper}>
                                <div onClick={() => handleOpen(i)}
                                     style={{width: '20%'}}
                                     className={classes.bodyItem}
                                >
                                    {moment(i?.createdAt).format('MM/DD/YYYY')}
                                </div>
                                <div onClick={() => handleOpen(i)}
                                     style={{width: '20%'}}
                                     className={classes.bodyItem}
                                >
                                    <TextRow name={`${i?.user?.firstName} ${i?.user?.lastName}`} textWidth={10}/>
                                </div>
                                <div onClick={() => handleOpen(i)}
                                     style={{width: '55%'}}
                                     className={classes.bodyItem}
                                >
                                    <TextRow name={i?.subject} textWidth={10}/>
                                </div>
                                <div style={{width: '5%'}} className={classes.bodyItem}>
                                    <button
                                        className={classes.removeButton}
                                        onClick={() => open( <CustomDeleteModal
                                            actionType={DELETE_ACTION_TYPE}
                                            text="Delete note?"
                                            info={'Note'}
                                            handleDel={() => handleDeleteNote(i)}
                                        />)}
                                    >
                                        <img style={{cursor: "pointer"}} src={Images.clear} alt=''/>
                                    </button>
                                </div>
                            </div>
                        ))
                        :
                        <TableNoInfo text={'No Notes Yet'}/>
                    }
                </div>
            </div>
        </>
    );
};
