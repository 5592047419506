import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {carrierActions} from "store";
import {History, Loader, Notes, SimpleTabs} from "components";
import {FindLoad} from "utils";
import {CarrierPaymentInfoFragment} from "fragments";

export const CarrierPaymentInfo = () => {
    const {carrierPaymentById, carrierPmtCarriers} = useSelector((state) => ({
            carrierPaymentById: state.carriers.carrierPaymentById,
            carrierPmtCarriers: state.carriers.carrierPmtCarriers,
        })
    )
    const params = useParams()
    const dispatch = useDispatch()
    const loader = FindLoad('GET_CARRIER_PAYMENT_BY_ID')

    useEffect(() => {
        if (params) {
            dispatch(carrierActions.getCarrierPaymentById(params.id))
            dispatch(carrierActions.carrierPaymentCarriers(params.id))
        }
    }, [params.id])

    const tabsLabels = [
        {
            label: 'Payment Details'
        },
        {
            label: 'History'
        },
        {
            label: 'Notes'
        },
    ]

    const tabsContent = [
        {
            tabComponent: loader.length ? <Loader/> : <CarrierPaymentInfoFragment info={carrierPaymentById} carrierPmtCarriers={carrierPmtCarriers}/>,
            tab: 'Payment Details'
        },
        {
            tabComponent: <History onModel={'carrierPmt'} resourceId={params.id}/>,
            tab: 'History'
        },
        {
            tabComponent: <Notes/>,
            tab: 'Notes'
        }
    ];

    return (
        <>
            <SimpleTabs
                border={'blue'}
                height={'full'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                customerPaymentById={'customerPaymentById'}
                parentLink={'/carrierPayments'}
                parent={'Carrier Payments'}
                child={'Payment Details'}
                defaultTab={'Payment Details'}
                paramsName={'carrierPaymentParams'}
            />
        </>
    )
}