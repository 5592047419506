import React from "react";
import {MinLoader} from "components";
import {FindLoad, useModal} from "utils";

export const CustomDeleteModal = ({text, info, handleDel, actionType, type, modalText, cancelButton, closeButton}) => {
    const {close} = useModal()
    const loader = FindLoad(actionType)

    return (
        <div className='delete-modal-wrapper'>
            <p className='delete-modal-title'>{text}</p>
            <p className='delete-modal-text'>
                {modalText ? modalText
                    :
                    type === 'void' ?
                        'Are you sure?  You wont Void transaction ?'
                        :
                        <span>Are you sure?  You wont delete  <span className='delete-info-text'>{info}</span></span>

                }
            </p>
            <div className='flex'>
                <button className='close-or-delete-btn' onClick={handleDel}>
                    {loader?.length ?
                        <MinLoader margin={'0'} color={'white'} position={'relative'}/>
                        :
                        cancelButton ? cancelButton :
                            type === 'void' ? 'Void' :
                                type === 'close' ? 'Close' :
                                    'Delete'
                    }

                </button>
                <button className='modal-close-btn' onClick={close}>
                    {closeButton ? closeButton : 'Chancel'}
                </button>
            </div>
        </div>

    );
};