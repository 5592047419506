import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    useGlobalStyles,
    useGlobalText,
    createInputsWrapper, ErrorText, FindSuccess, FindLoad, FindErrorItem, RolePermission, PermissionsList,
} from "utils";
import {
    AddressInput,
    CreateChancel,
    EditSaveButtons,
    Line, MaskInput,
    SelectInput,
    ValidationInput
} from "components";
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, mcActions} from "store";
import {CREATE_MC, EDIT_MC} from "../../../../store/mc/mc.types";
import {AuthorityTypes} from "./constants";

const CREATE_TYPE = CREATE_MC
const EDIT_TYPE = EDIT_MC

export const CreateAuthoritiesInputs = ({handleChangeName, info}) => {
    const globalWrappers = useGlobalStyles();
    const globalStyle = useGlobalText();
    const globalScreens = createInputsWrapper();
    const dispatch = useDispatch()
    const history = useHistory();
    const [disables, setDisabled] = useState(!!info)
    const [fullAddress, setFullAddress] = useState({})
    const [inputs, setInputs] = useState(info ? {...info} : {});
    const [error, setError] = useState('')
    const actionType = info ? EDIT_TYPE : CREATE_TYPE
    const loader = FindLoad(CREATE_TYPE)
    const success = FindSuccess(EDIT_TYPE)
    const backError = FindErrorItem(actionType)
    const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error


    useEffect(() => {
        if (info?.address) {
            const sendAddress = {
                address: info?.address?.formattedAddress,
            }
            if (info?.address?.unitNumber) {
                sendAddress.unitNumber = info?.address?.unitNumber
            }
            setFullAddress(sendAddress)
        }
    }, [info])

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_TYPE))
            setDisabled(true)
        }
    }, [success]);

    useEffect(() => {
        return () => {
            removeError()
        }
    }, [])

    const removeError = () => {
        errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType))
    }

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}))
        e.target.name === 'name' && handleChangeName(e.target.value)
        error === e.target.name && setError('')
        removeError()
    }


    const handleFullAddress = (ev) => {
        setFullAddress(ev)
        removeError()
        if (error === 'address') setError('')
    }

    const handleCreate = () => {
        const phoneNumber = inputs?.phone && parseInt(inputs?.phone.replace(/[^0-9]/g, '')) + ''
        const id = info && info.id

        const data = {
            "name": inputs.name,
            "mcNumber": inputs?.mcNumber,
            "phone": phoneNumber,
            "address": fullAddress,
            "authorityType": inputs.authorityType
        }

        if (inputs.name && inputs?.mcNumber && inputs.phone && inputs.authorityType && fullAddress?.address) {
            if (info) {
                dispatch(mcActions.editMc(id, data))
            } else {
                dispatch(mcActions.createMc(data))
            }
        } else {
            setError(
                !inputs.name ? 'name' :
                    !inputs.mcNumber ? 'mcNumber' :
                        !inputs.phone ? 'phone' :
                            !inputs.authorityType ? 'authorityType' :
                                !fullAddress?.address ? 'address' : ''
            )
        }
    }

    const handleChancel = ( ) => {
        setDisabled(true)
        setInputs({...info})
        setFullAddress(info?.address?.formattedAddress)
        setError('')
        removeError()
    }

    return (
        <div className={globalScreens.createInputsWrapper}>

            <div className={globalWrappers.spaceBetween}>
                <div className={globalWrappers.centerItem}>
                    {!info && <p className={globalStyle.title}>Authority Information</p>}
                </div>
                {info && RolePermission([PermissionsList.CREATE_UPDATE_MC?.code]) &&
                    <EditSaveButtons
                        margin={true}
                        type={EDIT_TYPE}
                        handleChancel={handleChancel}
                        handleSetEdit={() => setDisabled(false)}
                        handleSaveInfo={handleCreate}
                    />}
            </div>

            <div className={globalWrappers.flexAble}>
                {info && <Line height={'281px'}/>}
                <div className={globalWrappers.fullWidth}>
                    <div className={globalScreens.basicInfo}>
                        <p className={globalStyle.smallText}>Basic Information</p>

                        <div>
                            <div className={globalScreens.basicInfoInputs}>
                                <ValidationInput
                                    className={info && globalWrappers.inputTextFieldBlue}
                                    style={globalWrappers.simpleInput}
                                    variant={"outlined"}
                                    name={"name"}
                                    type={"text"}
                                    label={"Name*"}
                                    typeError={error === 'name' ? ErrorText.field : ''}
                                    onChange={handleChange}
                                    value={inputs.name}
                                    disabled={disables}
                                />
                                <ValidationInput
                                    className={info && globalWrappers.inputTextFieldBlue}
                                    style={globalWrappers.simpleInput}
                                    variant={"outlined"}
                                    name={"mcNumber"}
                                    label={"Authority Number*"}
                                    type={'text'}
                                    typeError={error === 'mcNumber' ? ErrorText.field : ''}
                                    onChange={handleChange}
                                    value={inputs?.mcNumber}
                                    disabled={disables}
                                    Length={10}
                                />

                                <MaskInput
                                    name='phone'
                                    label="Phone Number*"
                                    value={inputs.phone}
                                    disables={disables}
                                    handleChange={handleChange}
                                    info={info}
                                    error={
                                        error === 'phone' ? ErrorText.field :
                                            errorText === 'phone must be a valid phone number' && ErrorText.phone}
                                />

                                <SelectInput
                                    className={info && globalWrappers.inputTextFieldBlue}
                                    name={"authorityType"}
                                    label={"Authority Type*"}
                                    handleSelect={handleChange}
                                    value={inputs.authorityType}
                                    list={AuthorityTypes}
                                    typeError={error === 'authorityType' ? ErrorText.field : ''}
                                    type={'id'}
                                    disabled={disables}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={globalScreens.basicInfo}>
                        <p className={globalStyle.smallText}>Address</p>
                        <div className={globalScreens.basicInfoInputs}>
                            <AddressInput
                                errorBoolean={
                                    error === 'address' ? 'Input is not field' :
                                        errorText === 'Unable to verify the address' ? 'Unable to verify the address' :
                                            ''}
                                info={info}
                                handleSelectValue={handleFullAddress}
                                disabled={disables}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {!info &&
                <CreateChancel
                    loader={!!loader.length}
                    classes={globalWrappers.buttonsStyle}
                    create={"Create"}
                    chancel={"Cancel"}
                    onCreate={handleCreate}
                    onClose={() => history.push("/authorities")}
                />
            }
        </div>
    );
};
